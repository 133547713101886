import globalAxios, { AxiosInstance, AxiosPromise, AxiosResponse } from "axios";

export const BASE_PATH = "";
const DUMMY_BASE_URL = "https://example.com";

interface RequestArgs {
  url: string;
  options: any;
}

export interface ConfigurationParameters {
  accessToken?:
    | string
    | Promise<string>
    | ((name?: string, scopes?: string[]) => string)
    | ((name?: string, scopes?: string[]) => Promise<string>);
  basePath?: string;
  baseOptions?: any;
}

export class Configuration {
  accessToken?:
    | string
    | Promise<string>
    | ((name?: string, scopes?: string[]) => string)
    | ((name?: string, scopes?: string[]) => Promise<string>);
  basePath?: string;
  baseOptions?: any;

  public isJsonMime(mime: string): boolean {
    // eslint-disable-next-line
    const jsonMime: RegExp = new RegExp("^(application/json|[^;/ 	]+/[^;/ 	]+[+]json)[ 	]*(;.*)?$", "i");
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === "application/json-patch+json");
  }

  constructor(param: ConfigurationParameters = {}) {
    this.accessToken = param.accessToken;
    this.basePath = param.basePath;
    this.baseOptions = param.baseOptions;
  }
}

const setBearerAuthToObject = async function (object: any, configuration?: Configuration) {
  if (configuration && configuration.accessToken) {
    const accessToken =
      typeof configuration.accessToken === "function"
        ? await configuration.accessToken()
        : await configuration.accessToken;
    object["Authorization"] = "Bearer " + accessToken;
  }
};

const setSearchParams = function (url: URL, ...objects: any[]) {
  const searchParams = new URLSearchParams(url.search);
  for (const object of objects) {
    for (const key in object) {
      searchParams.set(key, object[key]);
    }
  }
  url.search = searchParams.toString();
};

const objectToFormData = function (obj: any) {
  let fd = new FormData();
  for (let kv of Object.entries(obj)) {
    if (kv[1] instanceof Blob) fd.append(kv[0], kv[1]);
    else if (kv[1] instanceof Array) for (let v of kv[1]) fd.append(kv[0], v);
    else fd.append(kv[0], kv[1] as any);
  }
  return fd;
};

const createRequestFunction = function (axiosArgs: RequestArgs, configuration?: Configuration) {
  return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
    const axiosRequestArgs = {
      ...axiosArgs.options,
      url: (configuration != null && configuration != undefined ? configuration.basePath : basePath) + axiosArgs.url
    };
    return axios.request(axiosRequestArgs);
  };
};

const toPathString = function (url: URL) {
  return url.pathname + url.search + url.hash;
};

const serializeDataIfNeeded = function (value: any, requestOptions: any, configuration?: Configuration) {
  const nonString = typeof value !== "string";
  const needsSerialization =
    nonString && configuration && configuration.isJsonMime
      ? configuration.isJsonMime(requestOptions.headers["Content-Type"])
      : nonString;
  return needsSerialization ? JSON.stringify(value !== undefined ? value : {}) : value || "";
};

const ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    CrearRequestArgs: async (
      localVarPath: string,
      method: string,
      localVarQueryParameter: any,
      requiereAutenticacion: boolean,
      data: any | undefined,
      options: any = {}
    ): Promise<RequestArgs> => {
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method, ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;

      // authentication bearerAuth required
      if (requiereAutenticacion) await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      if (data != undefined)
        localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

const ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiAxiosParamCreator(configuration);
  return {
    async CrearSolicitador(
      localVarPath: string,
      method: string,
      localVarQueryParameter: any,
      requiereAutenticacion: boolean,
      data: any,
      esBlob: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.CrearRequestArgs(
        localVarPath,
        method,
        localVarQueryParameter,
        requiereAutenticacion,
        data,
        options
      );
      localVarAxiosArgs.options.responseType = esBlob ? "blob" : "json";
      return createRequestFunction(localVarAxiosArgs, configuration);
    }
  };
};

class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

export module SRP {
  export module Models {
    export interface BitacoraEstatus {
      bitacoraEstatusId: number;
      requisicionPagoId: number;
      fechaRegistro: string;
      estatusRequisicionId: Models.EstatusRequisicion;
      usuarioId: number;
      observaciones?: string;
      usuario: Identity.Usuario;
      fechaPersonalizada?: string;
      descripcionRespuesta?: string;
    }

    export interface CatalogoBanco {
      clave?: string;
      nombre?: string;
      razonSocial?: string;
      numPosicionesCuenta: number;
    }

    export interface CatalogoCuentaBancaria {
      catalogoCuentaBancariaId: number;
      empresa?: string;
      tipoPagoId: number;
      beneficiario?: string;
      banco?: string;
      convenio?: string;
      cuenta?: string;
      clabe?: string;
      referencia?: string;
      bancoRequired: boolean;
      convenioRequired: boolean;
      cuentaRequired: boolean;
      clabeRequired: boolean;
      referenciaRequired: boolean;
      referenciaMaxlength: number;
      activo: boolean;
    }

    export interface CatalogoProveedorCuentaNAV {
      empresa?: string;
      proveedorId?: string;
      proveedorCuentaId?: string;
      nombre?: string;
      cuenta?: string;
      clabe?: string;
    }

    export interface CatalogoProveedorNAV {
      empresa?: string;
      proveedorId?: string;
      nombre?: string;
      nombreBusqueda?: string;
      email?: string;
      rfc?: string;
      proveedorCuentaPreferidaId?: string;
    }

    export interface DocumentoRequisicionPago {
      documentoRequisicionPagoId: number;
      requisicionPagoId: number;
      ruta: string;
      tipoDocumento: Models.TipoDocumento;
      mime?: string;
      fechaSubida: string;
      requisicionPago: Models.RequisicionPago;
    }

    export interface EmpresaEmisora {
      nombreNav: string;
      razonSocial: string;
    }

    export enum EstatusRequisicion {
      EN_CREACION = "EN_CREACION",
      EN_AUTORIZACION = "EN_AUTORIZACION",
      EN_VALIDACION_CXP = "EN_VALIDACION_CXP",
      EN_AUTORIZACION_CXP = "EN_AUTORIZACION_CXP",
      EN_TESORERIA = "EN_TESORERIA",
      EN_ACLARACION = "EN_ACLARACION",
      RECHAZADA = "RECHAZADA",
      PAGO_REALIZADO = "PAGO_REALIZADO",
      PAGO_RECHAZADO = "PAGO_RECHAZADO",
      EN_VERIFICACION_AREA = "EN_VERIFICACION_AREA"
    }

    export interface PlantillaRequisicion {
      plantillaRequisicionId: number;
      /**
       * @description Id del usuario solicitador
       */
      usuarioSolicitadorId: number;
      /**
       * @description Nombre del usuario creador de la plantilla
       */
      nombreUsuario?: string;
      /**
       * @description Fecha de la creación de la plantilla
       */
      fechaCreacionPlantilla: string;
      /**
       * @description Fecha de la última modificación a la plantilla
       */
      fechaUltimaModificacion: string;
      /**
       * @description Id del usuario solicitante
       */
      claveArea: number;
      /**
       * @description Razón social del emisor
       */
      razonSocial?: string;
      /**
       * @description Identificador de tipo de pago
       */
      tipoPagoId: number;
      /**
       * @description Es el nombre del CatalogoProveedorNAV (beneficiario)
       */
      nombreBeneficiario?: string;
      /**
       * @description Correo del beneficiario
       */
      correoBeneficiario?: string;
      /**
       * @description Cantidad de importe del beneficiario
       */
      importe: number;
      /**
       * @description Concepto de pago
       */
      conceptoPagos?: string;
      /**
       * @description Identificador de tipo de gasto
       */
      tipoGastoId: number;
      /**
       * @description Identificador del rubro
       */
      tipoRubroId: number;
      /**
       * @description Forma de pago al proveedor
       */
      formaPago?: string;
      /**
       * @description Aplica cuenta con CLABE
       */
      aplicaCuentaConClabe: boolean;
      /**
       * @description Es ProveedorCuentaId de CatalogoProveedorCuentaNAV
       */
      cuentaBeneficiario?: string;
      /**
       * @description Es Cuenta de CatalogoProveedorCuentaNAV
       */
      cuentaCLABE?: string;
      /**
       * @description Aplica cuenta con CLABE
       */
      aplicaConvenioConReferencia: boolean;
      /**
       * @description Numero de convenio o servicio de pago
       */
      convenioServicioPago?: string;
      /**
       * @description Referencia bancaria
       */
      referencia?: string;
      /**
       * @description Banco de la cuenta CLABE
       */
      bancoBeneficiario?: string;
    }

    export interface RequisicionPago {
      /**
       * @description Identificador autoincremental del requerimiento de pago
       */
      requisicionPagoId: number;
      /**
       * @description Nombre del solicitante
       */
      usuarioSolicitante?: string;
      /**
       * @description Correo del solicitante
       */
      correoSolicitante?: string;
      /**
       * @description Nombre del autorizador
       */
      usuarioAutorizador?: string;
      /**
       * @description Correo del autorizador
       */
      correoAutorizador?: string;
      /**
       * @description Nombre de la empresa emisor en NAV
       */
      empresaEmisoraId?: string;
      /**
       * @description Razón social del emisor
       */
      razonSocial?: string;
      /**
       * @description Identificador de tipo de gasto
       */
      tipoGastoId: number;
      /**
       * @description Numero de cuenta contable del tipo de gasto
       */
      cuentaContable?: string;
      /**
       * @description Descripción de cuenta contable (tipo de gasto) ocupado por Contabilidad
       */
      descripcionCuentaContable?: string;
      /**
       * @description Descripción de cuenta contable (tipo de gasto) ocupado por los solicitantes
       */
      codigoCompras?: string;
      /**
       * @description Proveedor id
       */
      beneficiarioId?: string;
      /**
       * @description Es el nombre del CatalogoProveedorNAV (beneficiario)
       */
      nombreBeneficiario?: string;
      /**
       * @description Es ProveedorCuentaId de CatalogoProveedorCuentaNAV
       */
      cuentaBeneficiario?: string;
      /**
       * @description Banco de la cuenta CLABE
       */
      bancoBeneficiario?: string;
      /**
       * @description Es Cuenta de CatalogoProveedorCuentaNAV
       */
      cuentaCLABE?: string;
      /**
       * @description RFC del proveedor
       */
      rfcBeneficiario?: string;
      /**
       * @description Identificador de tipo de pago
       */
      tipoPagoId: number;
      /**
       * @description Descripción de tipo de pago
       */
      tipoPago?: string;
      /**
       * @description Concepto de pago
       */
      conceptoPagos?: string;
      /**
       * @description Cuenta cargo
       */
      cuentaCargo?: string;
      /**
       * @description Numero de convenio o servicio de pago
       */
      convenioServicioPago?: string;
      /**
       * @description Identificador de forma de pago al proveedor
       */
      formaPagoId: number;
      /**
       * @description Forma de pago al proveedor
       */
      formaPago?: string;
      /**
       * @description Numero de factura
       */
      numeroFactura?: string;
      /**
       * @description Numero de UUID de una factura
       */
      uuid?: string;
      /**
       * @description Unidad de negocio
       */
      unidadNegocioId: number;
      /**
       * @description Descripción de unidad de negocio
       */
      unidadNegocio?: string;
      /**
       * @description Al seleccionar la forma de pago como cheque se debe incluir una observación respecto a este.
       */
      observacionPago?: string;
      /**
       * @description Cantidad de importe del beneficiario
       */
      importe: number;
      /**
       * @description Json de centro de costos
       */
      centroCostos?: string;
      /**
       * @description Referencia bancaria
       */
      referencia?: string;
      /**
       * @description Identificador del tipo de divisa
       */
      divisaId: number;
      /**
       * @description Descripcion del divisa
       */
      divisa?: string;
      estatusRequisicionId: Models.EstatusRequisicion;
      fechaSolicitud: string;
      fechaProgramadaPago: string;
      fechaAutorizacion?: string;
      fechaAutorizacionCxP?: string;
      fechaAplicacionPago?: string;
      /**
       * @description Nombre del verificador de cuentas por pagar
       */
      usuarioVerificador?: string;
      /**
       * @description Correo del verificador de cuentas por pagar
       */
      correoVerificador?: string;
      /**
       * @description Correo del beneficiario
       */
      correoBeneficiario?: string;
      /**
       * @description Id del usuario solicitante
       */
      usuarioSolicitadorId: number;
      /**
       * @description Id del usuario autorizador
       */
      usuarioAutorizadorId: number;
      /**
       * @description Id del usuario verificador de área
       */
      usuarioVerificadorAreaId: number;
      /**
       * @description Nombre del verificador del área relacionada
       */
      usuarioVerificadorArea?: string;
      /**
       * @description Correo del verificador del área relacionada
       */
      correoVerificadorArea?: string;
      fechaVerificadorArea?: string;
      /**
       * @description Identificador del rubro
       */
      tipoRubroId: number;
      documentos?: Models.DocumentoRequisicionPago[];
      tipoGasto: Models.TipoGasto;
      tipoRubro: Models.TipoRubro;
      nombreAutorizador?: string;
      nombreSolicitante?: string;
    }

    export interface RolAutorizador {
      rolAutorizadorId: number;
      rolId: number;
      autorizadorId: number;
      activo: boolean;
      esVerificador: boolean;
    }

    export enum TipoDocumento {
      PDF = "PDF",
      WORD = "WORD",
      EXCEL = "EXCEL",
      XML = "XML"
    }

    export interface TipoGasto {
      tipoGastoId: number;
      /**
       * @description Descripción conocida cómo código de compras
       */
      nombreGasto?: string;
      /**
       * @description Numero de cuenta contable del tipo de gasto
       */
      cuentaContable?: string;
      /**
       * @description Descripción de cuenta contable ocupado por Contabilidad
       */
      descripcionCuentaContable?: string;
      activo: boolean;
    }

    export interface TipoPago {
      tipoPagoId: number;
      nombre?: string;
      descripcion?: string;
      catalogoCuentas: boolean;
      proveedorNAV: boolean;
    }

    export interface TipoRubro {
      tipoRubroId: number;
      nombreRubro?: string;
      activo: boolean;
    }

    export interface VwArea {
      clave: number;
      nombre?: string;
    }

    export interface VwSucursal {
      clave: number;
      nombre?: string;
      esForanea: boolean;
    }

    export interface vwDiaInhabil {
      diaInhabilId: number;
      fecha: string;
      descripcion?: string;
    }

    export enum UnidadNegocio {
      HIR_CASA = "HIR_CASA",
      HIR_AUTO = "HIR_AUTO",
      SICRE = "SICRE",
      DESARROLLOS = "DESARROLLOS",
      NOMINA = "NOMINA"
    }
  }

  export module RequisicionPago {
    export module ControllerModels {
      export module RequisicionPago {
        export interface RequisicionPagoBody {
          /**
           * @description Identificador autoincremental del requerimiento de pago
           */
          requisicionPagoId: number;
          /**
           * @description Nombre del solicitante
           */
          usuarioSolicitante?: string;
          /**
           * @description Correo del solicitante
           */
          correoSolicitante?: string;
          /**
           * @description Nombre del autorizador
           */
          usuarioAutorizador?: string;
          /**
           * @description Correo del autorizador
           */
          correoAutorizador?: string;
          /**
           * @description Nombre de la empresa emisor en NAV
           */
          empresaEmisoraId?: string;
          /**
           * @description Razón social del emisor
           */
          razonSocial?: string;
          /**
           * @description Identificador de tipo de gasto
           */
          tipoGastoId: number;
          /**
           * @description Numero de cuenta contable del tipo de gasto
           */
          cuentaContable?: string;
          /**
           * @description Descripción de cuenta contable (tipo de gasto) ocupado por Contabilidad
           */
          descripcionCuentaContable?: string;
          /**
           * @description Descripción de cuenta contable (tipo de gasto) ocupado por los solicitantes
           */
          codigoCompras?: string;
          /**
           * @description Proveedor id
           */
          beneficiarioId?: string;
          /**
           * @description Es el nombre del CatalogoProveedorNAV (beneficiario)
           */
          nombreBeneficiario?: string;
          /**
           * @description Es ProveedorCuentaId de CatalogoProveedorCuentaNAV
           */
          cuentaBeneficiario?: string;
          /**
           * @description Banco de la cuenta CLABE
           */
          bancoBeneficiario?: string;
          /**
           * @description Es Cuenta de CatalogoProveedorCuentaNAV
           */
          cuentaCLABE?: string;
          /**
           * @description RFC del proveedor
           */
          rfcBeneficiario?: string;
          /**
           * @description Identificador de tipo de pago
           */
          tipoPagoId: number;
          /**
           * @description Descripción de tipo de pago
           */
          tipoPago?: string;
          /**
           * @description Concepto de pago
           */
          conceptoPagos?: string;
          /**
           * @description Cuenta cargo
           */
          cuentaCargo?: string;
          /**
           * @description Numero de convenio o servicio de pago
           */
          convenioServicioPago?: string;
          /**
           * @description Identificador de forma de pago al proveedor
           */
          formaPagoId: number;
          /**
           * @description Forma de pago al proveedor
           */
          formaPago?: string;
          /**
           * @description Numero de factura
           */
          numeroFactura?: string;
          /**
           * @description Numero de UUID de una factura
           */
          uuid?: string;
          /**
           * @description Unidad de negocio
           */
          unidadNegocioId: number;
          /**
           * @description Descripción de unidad de negocio
           */
          unidadNegocio?: string;
          /**
           * @description Al seleccionar la forma de pago como cheque se debe incluir una observación respecto a este.
           */
          observacionPago?: string;
          /**
           * @description Cantidad de importe del beneficiario
           */
          importe: number;
          /**
           * @description Json de centro de costos
           */
          centroCostos?: string;
          /**
           * @description Referencia bancaria
           */
          referencia?: string;
          /**
           * @description Identificador del tipo de divisa
           */
          divisaId: number;
          /**
           * @description Descripcion del divisa
           */
          divisa?: string;
          estatusRequisicionId: Models.EstatusRequisicion;
          fechaSolicitud: string;
          fechaProgramadaPago: string;
          fechaAutorizacion?: string;
          fechaAutorizacionCxP?: string;
          fechaAplicacionPago?: string;
          /**
           * @description Nombre del verificador de cuentas por pagar
           */
          usuarioVerificador?: string;
          /**
           * @description Correo del verificador de cuentsa por pagar
           */
          correoVerificador?: string;
          /**
           * @description Correo del beneficiario
           */
          correoBeneficiario?: string;
          usuarioSolicitadorId: number;
          usuarioAutorizadorId: number;
          /**
           * @description Id del usuario verificador de área
           */
          usuarioVerificadorAreaId: number;
          /**
           * @description Nombre del verificador del área relacionada
           */
          usuarioVerificadorArea?: string;
          /**
           * @description Correo del verificador del área relacionada
           */
          correoVerificadorArea?: string;
          fechaVerificadorArea?: string;
          /**
           * @description Identificador del rubro
           */
          tipoRubroId: number;
        }
      }
    }
    export class AdministracionCatalogoApi extends BaseAPI {
      public async ActualizarProveedorNAV(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/AdministracionCatalogo/ActualizarProveedorNAV",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }
    }

    export class RequisicionPagoApi extends BaseAPI {
      public async ObtenerRequisicionPago(
        requisicionPagoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.RequisicionPago | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          requisicionPagoId: requisicionPagoId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerRequisicionPago",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarRequisicionesPagoSolicitador(
        usuarioId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          usuarioId: usuarioId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarRequisicionesPagoSolicitador",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarRequisicionesPagoVerificadorArea(
        verificadorId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          verificadorId: verificadorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarRequisicionesPagoVerificadorArea",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarRequisicionesPagoAutorizadorArea(
        autorizadorId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          autorizadorId: autorizadorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarRequisicionesPagoAutorizadorArea",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarRequisicionesPagoVerificadorCXP(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarRequisicionesPagoVerificadorCXP",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarRequisicionesPagoAutorizadorCXP(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarRequisicionesPagoAutorizadorCXP",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarRequisicionesPagoTesoreria(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarRequisicionesPagoTesoreria",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoSolicitador(
        usuarioId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          usuarioId: usuarioId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoSolicitador",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoVerificadorArea(
        verificadorId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          verificadorId: verificadorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoVerificadorArea",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoAutorizadorArea(
        autorizadorId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          autorizadorId: autorizadorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoAutorizadorArea",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoVerificadorCXP(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoVerificadorCXP",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoAutorizadorCXP(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoAutorizadorCXP",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoTesoreria(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoTesoreria",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ListarHistorialRequisicionesPagoAuditoria(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ListarHistorialRequisicionesPagoAuditoria",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerBitacoraEstatusRequisicionPago(
        requisicionPagoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.BitacoraEstatus[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          requisicionPagoId: requisicionPagoId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerBitacoraEstatusRequisicionPago",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerArchivoGeneral(
        documentoId: number,
        descargaPdf: boolean,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          documentoId: documentoId,
          descargaPdf: descargaPdf
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivoGeneral",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerArchivo(
        documentoId: number,
        descargaPdf: boolean,
        requisicion: boolean,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          documentoId: documentoId,
          descargaPdf: descargaPdf,
          Requisicion: requisicion
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerAutorizadoresAsignados(
        usuarioId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.RolAutorizador[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          usuarioId: usuarioId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerAutorizadoresAsignados",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ComprobarExistenciaVerificadorArea(
        usuarioId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          usuarioId: usuarioId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ComprobarExistenciaVerificadorArea",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerEmpresasEmisoras(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.EmpresaEmisora[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerEmpresasEmisoras",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerProveedoresNAV(
        empresaEmisoraId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.CatalogoProveedorNAV[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          empresaEmisoraId: empresaEmisoraId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerProveedoresNAV",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCuentasProveedorNAV(
        empresaEmisoraId: string,
        proveedorId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.CatalogoProveedorCuentaNAV[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          empresaEmisoraId: empresaEmisoraId,
          proveedorId: proveedorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCuentasProveedorNAV",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoBeneficiariosSRP(
        empresaEmisoraId: string,
        tipoPagoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.CatalogoCuentaBancaria[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          empresaEmisoraId: empresaEmisoraId,
          tipoPagoId: tipoPagoId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoBeneficiariosSRP",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCuentasBancariasBeneficiarioSRP(
        empresaEmisoraId: string,
        tipoPagoId: number,
        catalogoCuentaBancariaId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.CatalogoCuentaBancaria[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          empresaEmisoraId: empresaEmisoraId,
          tipoPagoId: tipoPagoId,
          catalogoCuentaBancariaId: catalogoCuentaBancariaId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCuentasBancariasBeneficiarioSRP",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoTipoGasto(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.TipoGasto[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoTipoGasto",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoTipoRubro(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.TipoRubro[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoTipoRubro",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoDiasInhabiles(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.vwDiaInhabil[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoDiasInhabiles",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoAreas(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.VwArea[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoAreas",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoSucursales(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.VwSucursal[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoSucursales",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoTipoPago(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.TipoPago[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoTipoPago",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerUnidadesNegocio(
        opcionesAdicionalesAxios?: any
        ): Promise<AxiosResponse<JSON | [] | any | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerUnidadesNegocio",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerCatalogoBancos(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.CatalogoBanco[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerCatalogoBancos",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerRelacionTipoPagoDocumentos(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.TipoPago[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerRelacionTipoPagoDocumentos",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerRelacionCatalogosBeneficiario(
        tipoPagoId: number,
        empresaEmisoraId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tipoPagoId: tipoPagoId,
          empresaEmisoraId: empresaEmisoraId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerRelacionCatalogosBeneficiario",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ValidarDatosProveedorNav(
        empresaEmisoraId: string,
        proveedorId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          empresaEmisoraId: empresaEmisoraId,
          proveedorId: proveedorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ValidarDatosProveedorNav",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ExportToExcelTesoreria(
        tesoreria: boolean,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tesoreria: tesoreria
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ExportToExcelTesoreria",
            "GET",
            localVarQueryParameter,
            false,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ValidarBanco(
        clave: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Models.RolAutorizador[] | Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          clave: clave
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ValidarBanco",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ValidarRequisicionPorImporte(
        proveedorId: string,
        importe: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          proveedorId: proveedorId,
          importe: importe
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ValidarRequisicionPorImporte",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async NotificarCorreoMasivo(
        numeroRequisiciones: string,
        total: number,
        autorizadorId: number,
        estatusRequisicion: Models.EstatusRequisicion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          numeroRequisiciones: numeroRequisiciones,
          total: total,
          autorizadorId: autorizadorId,
          estatusRequisicion: estatusRequisicion
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/NotificarCorreoMasivo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async GuardarRequisicionPago(
        requisicionPago?: Models.RequisicionPago,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = requisicionPago;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/GuardarRequisicionPago",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async GuardarPlantillaRequisicion(
        plantillaRequisicion?: Models.PlantillaRequisicion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = plantillaRequisicion;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/GuardarPlantillaRequisicion",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerPlantillaRequisicion(
        plantillaRequisicionId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          plantillaRequisicionId: plantillaRequisicionId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerPlantillaRequisicion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ObtenerPlantillasRequisicion(
        usuarioSolicitadorId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          usuarioSolicitadorId: usuarioSolicitadorId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ObtenerPlantillasRequisicion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async EnviarRequisicionPago(
        requisicionPagoBody?: RequisicionPago.ControllerModels.RequisicionPago.RequisicionPagoBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = requisicionPagoBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/EnviarRequisicionPago",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async GuardarComentario(
        requisicionPagoId: number,
        usuarioId: number,
        comentario: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          requisicionPagoId: requisicionPagoId,
          usuarioId: usuarioId,
          comentario: comentario
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/GuardarComentario",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }
      
      public async GuardaEstatusRequisicionPagoMasivo(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/GuardaEstatusRequisicionPagoMasivo",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async GuardarDocumentoRequisicionPago(
        requisicionPagoId: number,
        archivo: Blob,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = objectToFormData({
          RequisicionPagoId: requisicionPagoId,
          Archivo: archivo
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/GuardarDocumentoRequisicionPago",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }


      public async ActualizarRequisicionPago(
        requisicionPagoBody?: RequisicionPago.ControllerModels.RequisicionPago.RequisicionPagoBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = requisicionPagoBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ActualizarRequisicionPago",
            "PUT",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ActualizarEstatusRequisicionPago(
        requisicionPagoId: number,
        usuarioId: number,
        autorizadorId: number,
        estatusRequisicion: Models.EstatusRequisicion,
        observaciones: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          requisicionPagoId: requisicionPagoId,
          usuarioId: usuarioId,
          autorizadorId: autorizadorId,
          estatusRequisicion: estatusRequisicion,
          observaciones: observaciones
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ActualizarEstatusRequisicionPago",
            "PATCH",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async ActualizarCuentaCargoEnRequisicion(
        arregloCuentaCargo: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          arregloCuentaCargo: arregloCuentaCargo
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/ActualizarCuentaCargoEnRequisicion",
            "PATCH",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async EliminarDocumento(
        documentoRequisicionPagoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          documentoRequisicionPagoId: documentoRequisicionPagoId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/EliminarDocumento",
            "DELETE",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }

      public async EliminarRequisicionPago(
        requisicionPagoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          requisicionPagoId: requisicionPagoId
        };

        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/SRP/RequisicionPago/RequisicionPago/EliminarRequisicionPago",
            "DELETE",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }
    }
  }
}

export module Identity {
  export interface CrearUsuarioBody {
    /**
     * @description Debe de ser el correo electrónico del usuario
     */
    usuario: string;
    contrasenia: string;
    urlConfirmacionCorreoElectronico: string;
    /**
     * @default false
     */
    crearEnAuthHIR?: boolean;
  }

  export interface LoginBody {
    usuario: string;
    contrasenia: string;
  }

  export interface Usuario {
    id: number;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed: boolean;
    passwordHash?: string;
    securityStamp?: string;
    concurrencyStamp?: string;
    phoneNumber?: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEnd?: string;
    lockoutEnabled: boolean;
    accessFailedCount: number;
    activo: boolean;
    colaboradorId: number;
  }
}

export module Test {
  export module Test {
    export class WeatherForecastApi extends BaseAPI {
      public async ObtenerClima(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = undefined;
        const data = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Test/Test/WeatherForecast/GetClima",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then((request) => request(this.axios, this.basePath));
      }
    }
  }
}

export enum EstadoRespuesta {
  OK = "OK",
  ALERT = "ALERT",
  INFO = "INFO",
  ERROR = "ERROR",
  NO_AUTORIZADO = "NO_AUTORIZADO"
}

export interface IdentityError {
  code?: string;
  description?: string;
}

export interface Respuesta {
  estado: EstadoRespuesta;
  mensaje?: string;
  data?: any;
}

export class IdentityApi extends BaseAPI {
  public async CrearUsuario(
    crearUsuarioBody: Identity.CrearUsuarioBody,
    opcionesAdicionalesAxios?: any
  ): Promise<AxiosResponse<IdentityError[] | Respuesta>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter = undefined;
    const data = crearUsuarioBody;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/CrearUsuario",
        "PUT",
        localVarQueryParameter,
        true,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then((request) => request(this.axios, this.basePath));
  }

  public async ConfirmarCorreoElectronico(
    userId: number,
    token: string,
    callbackUrl: string,
    opcionesAdicionalesAxios?: any
  ): Promise<AxiosResponse<Respuesta>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter = {
      userId: userId,
      token: token,
      callbackUrl: callbackUrl
    };

    const data = undefined;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/ConfirmarCorreoElectronico",
        "POST",
        localVarQueryParameter,
        false,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then((request) => request(this.axios, this.basePath));
  }

  public async Login(loginBody: Identity.LoginBody, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter = undefined;
    const data = loginBody;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/Login",
        "POST",
        localVarQueryParameter,
        false,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then((request) => request(this.axios, this.basePath));
  }

  public async Roles(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter = undefined;
    const data = undefined;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/Roles",
        "GET",
        localVarQueryParameter,
        true,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
