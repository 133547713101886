
import { SRP } from "@/servicios/api"; // eslint-disable-line
import api from "@/servicios/apiSRP";
import { defineComponent } from "vue";
import ModalDocumento from "../components/ModalDocumento.vue";
import swal from "sweetalert";
import swal2 from "sweetalert2";

import BotonPrimario from "../../Requisicion Pago/components/BotonPrimario.vue";
import { PlantillaRequisicion } from "../Models/PlantillaRequisicion";
import router from "@/router";

export default defineComponent({
  name: "FormularioDatosGenerales",
  components: {
    ModalDocumento,
    BotonPrimario
  },
  props: {
    montoTotalRP: String as any,
    plantilla: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  data() {
    return {
      centroCostosInfo: null as any,
      documentoPdfId: "" as any,
      razonSocialBanco: null as any,
      requisicionIdExistente: null as any,
      rutaDocumentoExistente: "" as any,
      txtPosicionesCuentaBanco: "",
      esNuevaRequisicion: false,
      esCreacion: false,
      esAclaracion: false,
      catalogoCuentas: false,
      diaHabil: false,
      estaCargandoActualizar: false,
      estaCargandoEnviar: false,
      estaCargandoGuardar: false,
      estaCargandoReenviar: false,
      fechaPropuestaExpiro: false,
      formularioValidado: false,
      formularioPLantillaValidado: false,
      proveedorNAV: false,
      referenciaRequired: false,
      tieneVerificador: window.sessionStorage.getItem("tieneVerificador") == "true" ? true : false,
      verFormatoAlta: false,
      verMensajeCuenta: false,
      contadorDisparador: 0,
      centroCostos: 0,
      documentoId: 0 as any,
      lengthInputCuenta: 18,
      noAplicaBanco: 0,
      noAplicaClabe: 0,
      noAplicaConvenio: false,
      noAplicaCuenta: false,
      noAplicaReferencia: false,
      aplicaCuentaConClabe: false,
      SeccionCuentaClaveVisible: true,
      aplicaConvenioConReferencia: false,
      noAplicaTipoGasto: 0,
      numPosicionesCuentaBanco: 0,
      referenciaMaxlength: 40,
      requisicionId: 0,
      validanumPosicionesBanco: 0,
      archivosRequisicion: [] as any,
      documentosExistentes: [] as any,
      estatusRequisicion: SRP.Models.EstatusRequisicion,
      catalogos: {
        TipoGasto: [] as SRP.Models.TipoGasto[],
        TipoRubro: [] as SRP.Models.TipoRubro[],
        EmpresasEmisoras: [] as SRP.Models.EmpresaEmisora[],
        TipoPago: [] as SRP.Models.TipoPago[],
        Proveedores: [] as SRP.Models.CatalogoProveedorNAV[],
        ProveedorCuenta: [] as SRP.Models.CatalogoProveedorCuentaNAV[],
        Autorizadores: [] as SRP.Models.RolAutorizador[],
        UnidadesDeNegocio: "" as SRP.Models.UnidadNegocio,
        CatalogoCuentaBeneficiario: [] as SRP.Models.CatalogoCuentaBancaria[],
        CatalogoCuentaBancaria: [] as SRP.Models.CatalogoCuentaBancaria[],
        CatalogoDiasInhabiles: [] as SRP.Models.vwDiaInhabil[],
        Bancos: [] as SRP.Models.CatalogoBanco[]
      },
      requisicionPago: {
        requisicionPagoId: 0,
        usuarioSolicitadorId: 0,
        fechaAplicacionPago: "2021-02-23",
        centroCostos: "",
        empresaEmisoraId: "",
        tipoPagoId: 0,
        tipoPago: "",
        razonSocial: "",
        beneficiarioId: "",
        nombreBeneficiario: "",
        cuentaBeneficiario: "N/A",
        bancoBeneficiario: "N/A",
        cuentaCLABE: "N/A",
        conceptoPagos: "",
        tipoGastoId: 0,
        tipoRubroId: 0,
        tipoGasto: undefined as any,
        tipoRubro: undefined as any,
        importe: undefined as any,
        divisaId: 1,
        rfcBeneficiario: "",
        formaPago: "",
        numeroFactura: "",
        uuid: "",
        observacionPago: "",
        fechaProgramadaPago: "",
        convenioServicioPago: "N/A",
        referencia: "N/A",
        unidadNegocioId: 0,
        unidadNegocio: "",
        fechaSolicitud: new Date().toISOString().substring(0, 10),
        usuarioAutorizadorId: 0,
        estatusRequisicionId: SRP.Models.EstatusRequisicion.EN_AUTORIZACION,
        estatusRequisicion: "",
        formaPagoId: 0,
        divisa: "",
        fechaAutorizacion: "1999-01-01",
        fechaAutorizacionCxP: "1999-01-01",
        correoBeneficiario: "",
        usuarioSolicitante: "",
        correoSolicitante: "",
        usuarioAutorizador: "",
        correoAutorizador: "",
        usuarioVerificadorAreaId: 0,
        usuarioVerificadorArea: "",
        correoVerificadorArea: "",
        fechaVerificadorArea: "1999-01-01"
      },
      plantillaRequisicon: new PlantillaRequisicion(),
      actulizarPlantilla: false
    };
  },
  watch: {
    "requisicionPago.importe": function (val) {
      if (this.contadorDisparador > 0) {
        this.requisicionPago.importe = val.replace(/[^0-9.,]/g, "");
      }
    },
    "requisicionPago.cuentaCLABE"(newVal) {
      if (this.contadorDisparador > 0) {
        if (this.proveedorNAV) {
          if (newVal.length == 18) {
            this.ObtenerCatalogoBancos(newVal);
          }
          if (newVal.length < 18) {
            this.numPosicionesCuentaBanco = 0;
            this.formularioValidado = false;
            this.razonSocialBanco = "";
            this.requisicionPago.bancoBeneficiario = "";
            this.verFormatoAlta = false;
          }
        }
        if (!this.proveedorNAV && !this.catalogoCuentas) {
          if (newVal.length == 18) {
            this.ObtenerCatalogoBancos(newVal);
          }
        }
      }
    },
    "requisicionPago.beneficiarioId"() {
      if (this.contadorDisparador > 0) {
        this.ChangeBeneficiario();
      }
    },
    "requisicionPago.cuentaBeneficiario"(newVal) {
      if (this.contadorDisparador > 0) {
        if (this.proveedorNAV) {
          this.catalogos.ProveedorCuenta.map((e: any) => {
            if (e.cuenta == this.requisicionPago.cuentaBeneficiario) {
              this.requisicionPago.cuentaCLABE = e.clabe;
            }
          });
        }
        if (this.catalogoCuentas) {
          this.catalogos.CatalogoCuentaBancaria.map((e: any) => {
            this.requisicionPago.cuentaCLABE = e.clabe;
            this.requisicionPago.bancoBeneficiario = e.banco;
            this.requisicionPago.convenioServicioPago = e.convenio;
            this.referenciaRequired = e.referenciaRequired;
            this.referenciaMaxlength = e.referenciaMaxlength;

            this.noAplicaCuenta = false;
            this.noAplicaClabe = 0;
            this.aplicaConvenioConReferencia = false;
            this.noAplicaBanco = 0;
          });
        }
        if (!this.proveedorNAV && !this.catalogoCuentas) {
          if (newVal.length == this.numPosicionesCuentaBanco) {
            this.ValidarPosicionesCuenta(this.numPosicionesCuentaBanco);
          }
        }
      }
    },
    aplicaCuentaConClabe: function () {
      if (this.requisicionPago.tipoPagoId != null)
        this.ValidarDinamismoDatosCuentaBancaria(this.requisicionPago.tipoPagoId);
    },
    aplicaConvenioConReferencia: function () {
      if (this.requisicionPago.tipoPagoId != null)
        this.ValidarDinamismoDatosCuentaBancaria(this.requisicionPago.tipoPagoId);
    }
  },
  async mounted() {

    await this.ValidarRuta()
    await this.CargarEmpresasEmisoras();
    await this.CargarTipoPago();
    await this.CargarTipoRubro();
    await this.CargarTipoGasto();
    await this.CargarUnidadNegocio();
    await this.CargarCatalogoDiasInhabiles();
    await this.ObtenerTodoslosBancos();

    let x = window.sessionStorage.getItem("usuarioId") as string;
    this.requisicionPago.usuarioSolicitadorId = +x;
    await this.CargarAutorizadores(this.requisicionPago.usuarioSolicitadorId);
    this.contadorDisparador++;

    this.ValidarDinamismoDatosCuentaBancaria(this.requisicionPago.tipoPagoId);

    if (this.$props.plantilla) this.CargarPlantilla()

  },
  computed: {
    inpNum(): any {
      return this.requisicionPago.cuentaBeneficiario;
    }
  },
  methods: {
    async ValidarRuta() {
      let ruta = this.$route.fullPath;
      let id = this.$route.params.requisicionPagoId
      if (ruta == "/formulario-requisicion-pago") {
        this.esNuevaRequisicion = true;
      } else if (ruta == "/editar-requisicion-pago/" + id) {
        this.requisicionIdExistente = this.$route.params["requisicionPagoId"] as any;
        this.EditarRequisicion(this.requisicionIdExistente);
      }
    },
    RestablecerValores(element: string): void {
      if (!this.esAclaracion) {
        if (this.contadorDisparador > 0) {
          this.verFormatoAlta = false;
          this.noAplicaTipoGasto = 0;
          this.noAplicaCuenta = false;
          this.noAplicaClabe = 0;
          this.aplicaConvenioConReferencia = false;
          this.noAplicaBanco = 0;

          this.requisicionPago.correoBeneficiario = "";
          this.requisicionPago.cuentaBeneficiario = "";
          this.requisicionPago.cuentaCLABE = "";
          this.requisicionPago.bancoBeneficiario = "";
          this.requisicionPago.convenioServicioPago = "";
          this.requisicionPago.referencia = "";
          this.referenciaRequired = false;
          this.referenciaMaxlength = 40;

          switch (element) {
            case "empresaEmisoraId":
              this.requisicionPago.tipoPagoId = 0;
              this.requisicionPago.beneficiarioId = "";
              this.requisicionPago.nombreBeneficiario = "";
              break;
            case "tipoPagoId":
              this.requisicionPago.beneficiarioId = "";
              this.requisicionPago.nombreBeneficiario = "";
              this.proveedorNAV = false;
              this.catalogoCuentas = false;
              break;
            default:
              break;
          }
        }
      }
    },
    RestablecerCuentaBancaria() {
      this.razonSocialBanco = "";
      this.numPosicionesCuentaBanco = 0;
      this.requisicionPago.cuentaBeneficiario = "";
      this.requisicionPago.bancoBeneficiario = "";
      this.requisicionPago.cuentaBeneficiario = "";
    },
    ChangeRazonSocial() {
      this.RestablecerValores("empresaEmisoraId");
      this.requisicionPago.unidadNegocio = "";
    },
    ChangeTipoPago() {
      this.RestablecerValores("tipoPagoId");
      this.ObtenerRelacionCatalogosBeneficiario(this.requisicionPago.empresaEmisoraId, this.requisicionPago.tipoPagoId);
    },

    ValidarDinamismoDatosCuentaBancaria(tipoPagoId: number) {
      let _aplicaCuentaClabe = document.getElementById("habilitarCuentaConClabe") as HTMLInputElement;
      let cuenta_txt = document.getElementById("inputCuentaBa") as HTMLInputElement;
      let clabe = document.getElementById("clabe") as HTMLInputElement;
      let convenio = document.getElementById("convenio") as HTMLInputElement;
      let referencia = document.getElementById("referencia") as HTMLInputElement;
      let banco = document.getElementById("bancoSelect") as HTMLInputElement;
      /**
       * Validar camino de datos de Cuenta bancaria
       *
       * 1 Tipo de pago - Libre : Cualquier otro que no sea Nav, tipoPagoId : 1041 o tipoPagoId :26
       * 2 Tipo de pago 5403 : 1041
       * 3 Tipo de pago 7204 : 26
       * 4 Nav proveedores : Nav
       */
      if (tipoPagoId != 1041 && tipoPagoId != 26 && !this.proveedorNAV) {
        /**
         *  Si presiona cunet y clave
         *  Se bloquea banco y se precarga con lo que escriba en el campo de clabe
         */
        if (this.aplicaCuentaConClabe) {
          cuenta_txt.disabled = false;
          clabe.disabled = false;
        } else {
          cuenta_txt.disabled = true;
          clabe.disabled = true;
        }

        //Si presiona actia o desactivar convenio y referencia
        if (this.aplicaConvenioConReferencia) {
          convenio.disabled = false;
          referencia.disabled = false;
        } else {
          convenio.disabled = true;
          referencia.disabled = true;
        }

        //se bloquea banco
        if (this.aplicaCuentaConClabe) {
          cuenta_txt.disabled = false;
          clabe.disabled = false;
          banco.disabled = true;
        } else {
          cuenta_txt.disabled = true;
          clabe.disabled = true;
          banco.disabled = false;
        }
      } else if (tipoPagoId == 1041) {
        // Datos de cuenta y clave deben desaparecer (no tomar en cuenta )
        // El input de convenio debe permancer bloqueado, pero el input de referencia puede ser activado o no
        // input de banco bloqueado sin poderser modificar, debe precargarse con los datos de la base de datos
        convenio.disabled = true;
        banco.disabled = true;
        this.aplicaConvenioConReferencia ? (referencia.disabled = false) : (referencia.disabled = true);
        //desaparecer cuenta y clave
      } else if (tipoPagoId == 26) {
        // Datos de cuenta y clave deben aparecer
        // El input de convenio y referencia si se activan deben ser obligatorio
        // input de banco bloqueado, debe precargarse con los datos de la base de datos

        //Importante falta validar que se coloque el banco en el select de select banco
        this.aplicaCuentaConClabe = true;
        _aplicaCuentaClabe.disabled = true;

        banco.disabled = true;

        cuenta_txt.disabled = true;
        // cuenta_select_interna.required = true;
        clabe.disabled = true;

        //Si presiona actia o desactivar convenio y referencia
        if (this.aplicaConvenioConReferencia) {
          convenio.disabled = false;
          referencia.disabled = false;
        } else {
          convenio.disabled = true;
          referencia.disabled = true;
        }
      } else if (this.proveedorNAV) {
        // Datos de cuenta y clave deben aparecer
        // El input de convenio y referencia si se activan deben ser obligatorio
        // input de banco bloqueado, debe precargarse con los datos de la base de datos

        this.aplicaCuentaConClabe = true;
        _aplicaCuentaClabe.disabled = true;

        banco.disabled = true;

        cuenta_txt.disabled = true;
        // cuenta_select_nav.required = true;
        clabe.disabled = true;

        //Si presiona actia o desactivar convenio y referencia
        if (this.aplicaConvenioConReferencia) {
          convenio.disabled = false;
          referencia.disabled = false;
        } else {
          convenio.disabled = true;
          referencia.disabled = true;
        }
      } else {
        if (this.aplicaConvenioConReferencia) {
          convenio.disabled = false;
          referencia.disabled = false;
        } else {
          convenio.disabled = true;
          referencia.disabled = true;
        }
        if (this.aplicaCuentaConClabe) {
          cuenta_txt.disabled = false;
          clabe.disabled = false;
          banco.disabled = true;
        } else {
          cuenta_txt.disabled = true;
          clabe.disabled = true;
          banco.disabled = false;
        }
      }
    },
    ChangeBeneficiario() {
      if (this.contadorDisparador > 0) {
        this.RestablecerValores("beneficiarioId");
        if (this.proveedorNAV) {
          this.catalogos.Proveedores.map((e: any) => {
            if (e.proveedorId == this.requisicionPago.beneficiarioId) {
              this.requisicionPago.rfcBeneficiario = e.rfc;
              this.requisicionPago.correoBeneficiario = e.email;
            }
          });

          let empresaEmisoraId = this.requisicionPago.empresaEmisoraId;
          this.CargarProveedorCuentas(empresaEmisoraId, this.requisicionPago.beneficiarioId);
        }

        if (this.catalogoCuentas) {
          let empresaEmisoraId = this.requisicionPago.empresaEmisoraId;
          let tipoPagoId = this.requisicionPago.tipoPagoId;
          this.CargarCatalogoCuentaBancaria(empresaEmisoraId, tipoPagoId, this.requisicionPago.beneficiarioId);
        }
      }
    },
    change(event: any) {
      let val = event.target.value.trim();
      if (/^[\d]+$/.test(val)) {
        this.requisicionPago.cuentaBeneficiario = val;
      } else {
        event.target.value = this.requisicionPago.cuentaBeneficiario;
      }
    },
    MostrarAltaProveedor(documentoId: number): void {
      this.documentoId = documentoId;
      this.rutaDocumentoExistente =
        "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivoGeneral?descargaPdf=false&documentoId=" +
        documentoId +
        "";
      this.documentoPdfId = 1 as any;
    },
    MostrarArchivo(documentoId: number, tipo: string) {
      if (tipo != "PDF") {
        window.open(
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false"
        );
      } else {
        this.rutaDocumentoExistente =
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false";
        this.documentoPdfId = documentoId as any;
      }
    },
    async ValidarFecha(fechaPropuesta: string) {
      let fechaPropuestaformateada = new Date(fechaPropuesta)
        .toLocaleString("en-GB", { timeZone: "UTC" })
        .substring(0, 10);

      let resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoDiasInhabiles();
      let fechas = resp.data as SRP.Models.vwDiaInhabil[];

      for (let i = 0; i < fechas.length; i++) {
        const fecha = fechas[i];
        if (fecha.fecha == fechaPropuestaformateada) {
          const Toast = swal2.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            showCloseButton: true,
            width: "500px",
            didOpen: (toast: any) => {
              toast.addEventListener("mouseenter", swal2.stopTimer);
              toast.addEventListener("mouseleave", swal2.resumeTimer);
            }
          });

          Toast.fire({
            icon: "error",
            title: "Fecha propuesta inválida",
            text: "Seleciona un día que no sea Sábado, Domingo o festivo."
          });
          this.requisicionPago.fechaProgramadaPago = "";
        }
      }
    },
    ValidarPosicionesCuenta(val: number): void {
      if (this.requisicionPago.cuentaBeneficiario.length == val) {
        //Limpiar mensajes
        this.verMensajeCuenta = false;
      } else {
        //Mostrar mensaje personalizado
        this.verMensajeCuenta = true;
        this.txtPosicionesCuentaBanco = "Deben ser " + val + " dígitos";
      }
    },
    LimpiarImporte(): void {
      if (this.requisicionPago.importe != null && this.requisicionPago.importe.toString().includes(",")) {
        this.requisicionPago.importe = this.requisicionPago.importe.replace(/,/g, "");
      } else if (this.requisicionPago.importe == "") {
        this.requisicionPago.importe = 0;
      }
    },
    FechaMinima: function () {
      let Fechaminima = new Date(this.requisicionPago.fechaSolicitud);
      // Add a day
      Fechaminima.setDate(Fechaminima.getDate() + 1);
      return Fechaminima.toISOString().substring(0, 10);
    },
    FechaMaxima() {
      let Fechamaxima = new Date(this.FechaMinima().toString());
      Fechamaxima.setMonth(Fechamaxima.getMonth() + 1);
      let fechaFinal = new Date(Fechamaxima);
      let fechafinalfinal = new Date(fechaFinal).toISOString().substring(0, 10);
      return fechafinalfinal;
    },
    ClickAInput(event: Event) {
      event.preventDefault();
      (this.$refs["inputArchivo"] as HTMLInputElement).click();
    },
    LimpiarInputFile() {
      (this.$refs["inputArchivo"] as HTMLInputElement).value = "";
    },
    SubirArchivo() {
      let archivo = (this.$refs["inputArchivo"] as HTMLInputElement).files; //obtengo la ruta del archivo
      for (let item of archivo as FileList) {
        if (item.type == "application/pdf" || item.type == "text/xml" || item.type == "xlsx" || item.type == "docx") {
          if (item.size <= 18000000) {
            this.archivosRequisicion.push(item);
          } else {
            swal({
              icon: "warning",
              title: "Reduce el peso de tu archivo",
              text: `El archivo ${item.name} tiene peso de ${(item.size / (1024 * 1024)).toFixed(
                2
              )} MB, archivo máximo permito de 20MB.`
            });
          }
        } else {
          swal({
            icon: "warning",
            title: "Archivo invalido",
            text: `El archivo ${item.name}, no es válido.`
          });
          (this.$refs["inputArchivo"] as HTMLInputElement).value = "";
        }
      }
    },
    EliminarArchivo(nombreArchivo: string) {
      var index = this.archivosRequisicion
        .map(function (e: any) {
          return e.name;
        })
        .indexOf(nombreArchivo);
      if (index > -1) {
        this.archivosRequisicion.splice(index, 1);
        if (index < 1) (this.$refs["inputArchivo"] as HTMLInputElement).value = "";
      }
    },
    DragArchivo(event: DragEvent) {
      event.preventDefault();
    },
    DropArchivo(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer) {
        let archivo = ((this.$refs["inputArchivo"] as any).files = event.dataTransfer.files);
        for (let item of archivo as FileList) {
          if (item.type == "application/pdf" || item.type == "text/xml" || item.type == "xlsx" || item.type == "docx") {
            if (item.size <= 18000000) {
              this.archivosRequisicion.push(item);
            } else {
              swal({
                icon: "warning",
                title: "Reduce el peso de tu archivo",
                text: `El archivo ${item.name} tiene peso de ${(item.size / (1024 * 1024)).toFixed(
                  2
                )} MB, archivo máximo permito de 20MB.`
              });
            }
          } else {
            swal({
              icon: "warning",
              title: "Archivo invalido",
              text: `El archivo ${item.name}, no es válido.`
            });
            (this.$refs["inputArchivo"] as HTMLInputElement).value = "";
          }
        }
      }
    },
    async GuardarActualizarPlantilla() {
      this.formularioPLantillaValidado = true;
      const plantillaId = this.$props.plantilla.plantillaRequisicionId || 0

      const ruta = this.$route.query.idPlantilla

      let formularioPLantillaValidado = (this.$refs["formularioPlantilla"] as HTMLFormElement).checkValidity();

      if (formularioPLantillaValidado) {
        const sessionCentroCostos = window.sessionStorage.getItem("centroCostos");
        this.requisicionPago.centroCostos = sessionCentroCostos as string;
        const modeloPlantilla = {
          plantillaRequisicionId: plantillaId,
          usuarioSolicitadorId: window.sessionStorage.getItem("usuarioId"),
          nombreUsuario: "",
          fechaCreacionPlantilla: new Date().toISOString().substring(0, 10),
          fechaUltimaModificacion: new Date().toISOString().substring(0, 10),
          claveArea: 0,
          razonSocial: '',
          empresaEmisoraId: this.requisicionPago.empresaEmisoraId,
          unidadNegocio: this.requisicionPago.unidadNegocio,
          rfcBeneficiario: this.requisicionPago.rfcBeneficiario,
          tipoPagoId: this.requisicionPago.tipoPagoId,
          nombreBeneficiario: this.requisicionPago.nombreBeneficiario,
          correoBeneficiario: this.requisicionPago.correoBeneficiario,
          importe: this.requisicionPago.importe,
          conceptoPagos: this.requisicionPago.conceptoPagos,
          tipoGastoId: this.requisicionPago.tipoGastoId,
          tipoRubroId: this.requisicionPago.tipoRubroId,
          formaPago: this.requisicionPago.formaPago,
          aplicaCuentaConClabe: this.aplicaCuentaConClabe,
          cuentaBeneficiario: this.requisicionPago.cuentaBeneficiario,
          cuentaCLABE: this.requisicionPago.cuentaCLABE,
          aplicaConvenioConReferencia: this.aplicaConvenioConReferencia,
          convenioServicioPago: this.requisicionPago.convenioServicioPago,
          referencia: this.requisicionPago.referencia,
          bancoBeneficiario: this.requisicionPago.bancoBeneficiario,
          beneficiarioId: this.requisicionPago.beneficiarioId,
          centroCostos: this.requisicionPago.centroCostos
        }
        const { status } = await api.SRP.RequisicionPago.RequisicionPago.GuardarPlantillaRequisicion(modeloPlantilla as any)
        if (status == 200) {
          await swal2.fire({
            icon: 'success',
            title: 'Éxito',
            text: `${this.actulizarPlantilla ? 'Se actualizo correctamente correctamente tu plantilla' : 'Se guardo correctamente correctamente tu plantilla'}`,

          })
          if (!ruta) router.push({ name: 'srp-requisicion-pago-plantillas' })

        }
        else {
          swal2.fire({
            icon: 'warning',
            title: 'Avertencia',
            text: 'Ocurrio un error al guardar tu plantilla, intente más tarde.',
          })
        }
      } else {
        swal2.fire({
          icon: 'warning',
          title: 'Completa los campos',
          text: 'Para generar o actualizar una plantilla es necesario, completar todos los campos.',
          footer: '<a href="#">Clic para ver un ejemplo</a>'
        })
      }
    },
    async GuardarRequisicion() {
      this.estaCargandoGuardar = true;
      this.LimpiarImporte();

      if (this.requisicionPago.tipoGastoId == 0) {
        this.requisicionPago.tipoGastoId = 1;
      }
      if (this.requisicionPago.tipoRubroId == 0) {
        this.requisicionPago.tipoRubroId = 99;
      }
      this.requisicionPago.beneficiarioId ? this.requisicionPago.beneficiarioId.toString() : '';
      var sessionCentroCostos = window.sessionStorage.getItem("centroCostos");
      this.requisicionPago.centroCostos = sessionCentroCostos as string;
      if (!this.requisicionPago.fechaProgramadaPago) {
        swal("¡Ups!", "Debes colocar al menos una fecha programada para guardar tu requisición de pago.", "error", {
          buttons: ["Salir"]
        });
        this.estaCargandoGuardar = false;
      } else {
        //Try guardar requisición
        try {
          var rest = await api.SRP.RequisicionPago.RequisicionPago.GuardarRequisicionPago(this.requisicionPago);
          if (rest.data.estado == "OK") {
            this.requisicionId = rest.data.data;
            this.EnviarDocumentos(this.requisicionId);
            setTimeout(() => {
              swal("¡Excelente!", "Tu requisición de pago fue guardada correctamente", "success", {
                buttons: ["Salir"]
              });
              this.$router.push("/historico-requisicion-pago/Solicitador");
            }, 1500);

            setTimeout(() => {
              this.estaCargandoGuardar = false;
            }, 2000);
          } else {
            swal("¡Ups!", `Error al guardar ${rest.data.estado}`, "error", {
              buttons: ["Cerrar", true]
            });
            setTimeout(() => {
              this.estaCargandoGuardar = false;
            }, 1000);
          }
        } catch (error) {
          swal("¡Ups!", `Error al guardar ${Object.values(error.response.data.errors)}`, "error", {
            buttons: ["Cerrar", true]
          });
          setTimeout(() => {
            this.estaCargandoGuardar = false;
          }, 1000);
        }
      }
    },
    async ActualizarRequisicion() {
      this.estaCargandoActualizar = true;
      this.LimpiarImporte();
      this.requisicionPago.beneficiarioId = this.requisicionPago.beneficiarioId.toString();
      var sessionCentroCostos = window.sessionStorage.getItem("centroCostos");
      this.requisicionPago.centroCostos = sessionCentroCostos as string;
      if (!this.requisicionPago.fechaProgramadaPago) {
        swal("¡Ups!", "Debes colocar al menos una fecha programada para actualizar tu requisición de pago.", "error", {
          buttons: ["Salir"]
        });
        this.estaCargandoActualizar = false;
      } else {
        try {
          let _ActualizarRequisicion = await api.SRP.RequisicionPago.RequisicionPago.ActualizarRequisicionPago(
            this.requisicionPago
          );
          if (_ActualizarRequisicion.data.estado == "OK") {
            this.EnviarDocumentos(this.requisicionPago.requisicionPagoId);
            swal("¡Excelente!", "Se han actualizado los datos", "success", {
              buttons: ["Salir"]
            });
            setTimeout(() => {
              this.$router.push("/historico-requisicion-pago/Solicitador");
            }, 1000);
            setTimeout(() => {
              this.estaCargandoActualizar = false;
            }, 1500);
          } else {
            swal("¡Ups!", "Ocurrió un problema al actualizar", "error", {
              buttons: ["Salir"]
            });
            setTimeout(() => {
              this.estaCargandoActualizar = false;
            }, 1500);
          }
        } catch (error) {
          swal("¡Ups!", `Error al actualizar: ${Object.values(error.response.data.errors)}`, "error", {
            buttons: ["Salir"]
          });
          setTimeout(() => {
            this.estaCargandoActualizar = false;
          }, 1500);
        }
      }
    },
    async EditarRequisicion(solicitud: number) {
      let hoy = new Date();
      let fecha_de_hoy =
        hoy.getFullYear() + "-" + (hoy.getMonth() + 1).toString().padStart(2, "0") + "-" + hoy.getDate();

      //Obetener data con API
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerRequisicionPago(solicitud);

      //Formatear fechas
      let fechaProgramadaPago = (resp.data as SRP.Models.RequisicionPago).fechaProgramadaPago as any;
      let fechaProgramadaPagoFormateada = new Date(fechaProgramadaPago).toISOString().slice(0, 10);
      let fechaSolicitud = (resp.data as SRP.Models.RequisicionPago).fechaSolicitud as any;
      let fechaSolicitudFormateada = new Date(fechaSolicitud).toISOString().slice(0, 10);
      //Mapear valores establecidos
      this.requisicionPago.requisicionPagoId = (resp.data as SRP.Models.RequisicionPago).requisicionPagoId;
      this.requisicionPago.usuarioSolicitadorId = (resp.data as SRP.Models.RequisicionPago).usuarioSolicitadorId;
      this.requisicionPago.usuarioSolicitante = (resp.data as SRP.Models.RequisicionPago).usuarioSolicitante as any;
      this.requisicionPago.correoSolicitante = (resp.data as SRP.Models.RequisicionPago).correoSolicitante as any;
      this.requisicionPago.fechaAplicacionPago = (resp.data as SRP.Models.RequisicionPago).fechaAplicacionPago as any;
      this.requisicionPago.centroCostos = (resp.data as SRP.Models.RequisicionPago).centroCostos as any;
      window.localStorage.setItem("JsonCC", this.requisicionPago.centroCostos);
      this.requisicionPago.empresaEmisoraId = (resp.data as SRP.Models.RequisicionPago).empresaEmisoraId as any;
      this.requisicionPago.tipoPagoId = (resp.data as SRP.Models.RequisicionPago).tipoPagoId as any;
      this.requisicionPago.tipoPago = (resp.data as SRP.Models.RequisicionPago).tipoPago as any;
      this.requisicionPago.razonSocial = (resp.data as SRP.Models.RequisicionPago).razonSocial as any;
      this.requisicionPago.beneficiarioId = (resp.data as SRP.Models.RequisicionPago).beneficiarioId as any;
      this.requisicionPago.nombreBeneficiario = (resp.data as SRP.Models.RequisicionPago).nombreBeneficiario as any;
      this.requisicionPago.cuentaBeneficiario = (resp.data as SRP.Models.RequisicionPago).cuentaBeneficiario as any;
      this.requisicionPago.bancoBeneficiario = (resp.data as SRP.Models.RequisicionPago).bancoBeneficiario as any;
      this.requisicionPago.cuentaCLABE = (resp.data as SRP.Models.RequisicionPago).cuentaCLABE as any;
      this.requisicionPago.conceptoPagos = (resp.data as SRP.Models.RequisicionPago).conceptoPagos as any;
      this.requisicionPago.tipoGastoId = (resp.data as SRP.Models.RequisicionPago).tipoGastoId;
      this.requisicionPago.tipoRubroId = (resp.data as SRP.Models.RequisicionPago).tipoRubroId;
      this.requisicionPago.importe = (resp.data as SRP.Models.RequisicionPago).importe;
      this.requisicionPago.divisaId = (resp.data as SRP.Models.RequisicionPago).divisaId;
      this.requisicionPago.rfcBeneficiario = (resp.data as SRP.Models.RequisicionPago).rfcBeneficiario as any;
      this.requisicionPago.formaPago = (resp.data as SRP.Models.RequisicionPago).formaPago as any;
      this.requisicionPago.observacionPago = (resp.data as SRP.Models.RequisicionPago).observacionPago as any;
      this.requisicionPago.convenioServicioPago = (resp.data as SRP.Models.RequisicionPago).convenioServicioPago as any;
      this.requisicionPago.uuid = (resp.data as SRP.Models.RequisicionPago).uuid as any;
      this.requisicionPago.numeroFactura = (resp.data as SRP.Models.RequisicionPago).numeroFactura as any;
      this.requisicionPago.fechaProgramadaPago = fechaProgramadaPagoFormateada as any;
      this.requisicionPago.referencia = (resp.data as SRP.Models.RequisicionPago).referencia as any;
      this.requisicionPago.fechaSolicitud = fechaSolicitudFormateada as any;
      this.requisicionPago.usuarioAutorizadorId = (resp.data as SRP.Models.RequisicionPago).usuarioAutorizadorId;
      this.requisicionPago.usuarioVerificadorAreaId = (resp.data as SRP.Models.RequisicionPago).usuarioVerificadorAreaId;
      this.requisicionPago.usuarioAutorizador = (resp.data as SRP.Models.RequisicionPago).usuarioAutorizador as any;
      this.requisicionPago.correoAutorizador = (resp.data as SRP.Models.RequisicionPago).correoAutorizador as any;
      this.requisicionPago.formaPagoId = (resp.data as SRP.Models.RequisicionPago).formaPagoId;
      this.requisicionPago.divisa = (resp.data as SRP.Models.RequisicionPago).divisa as any;
      this.requisicionPago.estatusRequisicionId = (resp.data as SRP.Models.RequisicionPago).estatusRequisicionId as any;
      var estatus = (resp.data as SRP.Models.RequisicionPago).estatusRequisicionId as any;
      this.requisicionPago.estatusRequisicion = estatus + "";
      this.requisicionPago.fechaAutorizacion = (resp.data as SRP.Models.RequisicionPago).fechaAutorizacion as any;
      this.requisicionPago.fechaAutorizacionCxP = (resp.data as SRP.Models.RequisicionPago).fechaAutorizacionCxP as any;
      this.requisicionPago.correoBeneficiario = (resp.data as SRP.Models.RequisicionPago).correoBeneficiario as any;
      this.documentosExistentes = (resp.data as SRP.Models.RequisicionPago).documentos;
      this.requisicionPago.unidadNegocio = (resp.data as SRP.Models.RequisicionPago).unidadNegocio as any;

      //Validar fecha programada
      if (fechaProgramadaPagoFormateada <= fecha_de_hoy) {
        swal({
          icon: "warning",
          title: "Tu fecha programada  expiró : " + fechaProgramadaPagoFormateada,
          text: `Por favor, coloca una nueva fecha programada`,
          timer: 2000
        });
        this.requisicionPago.fechaProgramadaPago = "";
      }
      if (this.requisicionPago.estatusRequisicion == "EN_CREACION") {
        this.esCreacion = true;
      } else if (this.requisicionPago.estatusRequisicion == "EN_ACLARACION") {
        this.esAclaracion = true;
      }
      if (this.requisicionPago.tipoPagoId != null)
        this.ValidarDinamismoDatosCuentaBancaria(this.requisicionPago.tipoPagoId);

      let banco = document.getElementById("bancoSelect") as HTMLInputElement;
      banco.disabled = true;
      return solicitud;
    },
    async EnviarRequisicion() {
      console.log("EnviarRequisicion");
      this.formularioValidado = true;
      let formularioValidado = (this.$refs["formulario"] as HTMLFormElement).checkValidity();

      this.estaCargandoEnviar = true;
      this.requisicionPago.beneficiarioId = this.requisicionPago.beneficiarioId.toString();

      var formularioDatosAdicionales = false;

      this.LimpiarImporte();

      if (this.noAplicaTipoGasto == 1) {
        this.requisicionPago.tipoGastoId = 1;
        formularioDatosAdicionales = true;
      }
      if (this.noAplicaCuenta == true) {
        formularioDatosAdicionales = true;
      }
      if (this.proveedorNAV && this.requisicionPago.cuentaBeneficiario == "") {
        formularioDatosAdicionales = false;
      }
      if (this.txtPosicionesCuentaBanco != "" && !this.noAplicaBanco) {
        if (this.requisicionPago.cuentaBeneficiario.length != this.numPosicionesCuentaBanco) {
          swal("¡Atención!", "Verificar el número de dígitos escritos en el campo de cuenta", "warning", {
            buttons: ["Salir", true]
          });
          formularioDatosAdicionales = false;
        } else {
          formularioDatosAdicionales = true;
        }
      } else {
        formularioDatosAdicionales = true;
      }
      if (this.noAplicaClabe) {
        if (this.txtPosicionesCuentaBanco != "" && !this.noAplicaBanco) {
          if (this.requisicionPago.cuentaBeneficiario.length != this.numPosicionesCuentaBanco) {
            swal("¡Atención!", "Verificar el número de dígitos escritos en el campo de cuenta", "warning", {
              buttons: ["Salir"]
            });
            formularioDatosAdicionales = false;
          } else {
            formularioDatosAdicionales = true;
          }
        } else {
          formularioDatosAdicionales = true;
        }
      }
      if (
        formularioValidado &&
        formularioDatosAdicionales &&
        (this.documentosExistentes.length != 0 || this.archivosRequisicion.length != 0)
      ) {
        var sessionCentroCostos = window.sessionStorage.getItem("centroCostos");
        this.requisicionPago.centroCostos = sessionCentroCostos as string;

        try {
          this.requisicionPago.estatusRequisicionId = this.tieneVerificador
            ? SRP.Models.EstatusRequisicion.EN_VERIFICACION_AREA
            : SRP.Models.EstatusRequisicion.EN_AUTORIZACION;
          var rest = await api.SRP.RequisicionPago.RequisicionPago.EnviarRequisicionPago(this.requisicionPago);
          if (rest.data.estado == "OK") {
            this.requisicionId = rest.data.data;
            this.EnviarDocumentos(this.requisicionId);
            setTimeout(() => {
              swal(
                "¡Excelente!",
                "Tu requisición de pago fue registrada con el ID: " + this.requisicionId + "",
                "success",
                {
                  buttons: ["Salir"]
                }
              );
              this.$router.push("/historico-requisicion-pago/Solicitador");
            }, 1000);
            setTimeout(() => {
              this.estaCargandoEnviar = false;
            }, 1000);
          } else {
            swal("¡Ups!", `Error al enviar ${rest.data.estado}`, "error", {
              buttons: ["Salir"]
            });
            setTimeout(() => {
              this.estaCargandoEnviar = false;
            }, 1000);
          }
        } catch (error) {
          swal("¡Ups!", `Error al enviar ${Object.values(error.response.data.errors)}`, "error", {
            buttons: ["Salir"]
          });
          setTimeout(() => {
            this.estaCargandoEnviar = false;
          }, 1000);
        }
      } else {
        if (this.documentosExistentes.length == 0 || this.archivosRequisicion.length == 0) {
          swal("¡Atención!", "Adjunta los soportes necesarios", "info", {
            buttons: ["Salir"]
          });
        }
        swal("¡Atención!", "La captura de información está incompleta", "info", {
          buttons: ["Salir"]
        });
      }
      setTimeout(() => {
        this.estaCargandoEnviar = false;
      }, 1000);
    },
    async ReenviarRequisicion() {
      let formularioValidado = false;

      if (this.requisicionPago.conceptoPagos != "") {
        formularioValidado = true;
      }

      if (formularioValidado && (this.documentosExistentes.length != 0 || this.archivosRequisicion.length != 0)) {
        this.estaCargandoReenviar = true;
        var sessionCentroC = window.sessionStorage.getItem("centroCostos");
        this.requisicionPago.centroCostos = sessionCentroC as string;
        //Try de actualizar y reenviar
        try {
          var _respustaActualiazarRP = await api.SRP.RequisicionPago.RequisicionPago.ActualizarRequisicionPago(
            this.requisicionPago
          );
          if (_respustaActualiazarRP.data.estado == "OK") {
            this.requisicionPago.estatusRequisicionId = this.tieneVerificador
              ? SRP.Models.EstatusRequisicion.EN_VERIFICACION_AREA
              : SRP.Models.EstatusRequisicion.EN_AUTORIZACION;

            await api.SRP.RequisicionPago.RequisicionPago.ActualizarEstatusRequisicionPago(
              this.requisicionPago.requisicionPagoId,
              this.requisicionPago.usuarioSolicitadorId,
              this.tieneVerificador
                ? this.requisicionPago.usuarioVerificadorAreaId
                : this.requisicionPago.usuarioAutorizadorId,
              this.requisicionPago.estatusRequisicionId,
              "Se reenvia requisición de pago"
            );
            swal(
              "¡Excelente!",
              `Tu requisición de pago fue reenviada al ` + this.tieneVerificador
                ? "verificador del área" + ` ID: ${this.requisicionPago.requisicionPagoId}`
                : "autorizador del área" + ` ID: ${this.requisicionPago.requisicionPagoId}`,
              "success",
              {
                buttons: ["Salir"]
              }
            );
            this.EnviarDocumentos(this.requisicionPago.requisicionPagoId);
            setTimeout(() => {
              this.$router.push("/historico-requisicion-pago/Solicitador");
            }, 500);
            setTimeout(() => {
              this.estaCargandoReenviar = false;
            }, 1000);
          } else {
            swal("¡Ups!", `Error al actualizar ${_respustaActualiazarRP.data.estado}`, "error", {
              buttons: ["Cerrar", true]
            });
            setTimeout(() => {
              this.estaCargandoReenviar = false;
            }, 1000);
          }
        } catch (error) {
          swal("¡Ups!", `Error al actualizar ${Object.values(error.response.data.errors)}`, "error", {
            buttons: ["Cerrar", true]
          });
          setTimeout(() => {
            this.estaCargandoReenviar = false;
          }, 1000);
        }
      } else {
        swal("¡Atención!", "Hace falta información requerida", "info", {
          buttons: ["Salir"]
        });
      }
    },
    async EnviarDocumentos(requisicionId: number) {
      for (var archivo of this.archivosRequisicion) {
        await api.SRP.RequisicionPago.RequisicionPago.GuardarDocumentoRequisicionPago(requisicionId, archivo, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      }
    },
    async EliminarArchivoExistente(documentoId: number) {
      await api.SRP.RequisicionPago.RequisicionPago.EliminarDocumento(documentoId);
      var index = this.documentosExistentes
        .map((e: any) => {
          return e.documentoRequisicionPagoId;
        })
        .indexOf(documentoId);
      if (index > -1) {
        this.documentosExistentes.splice(index, 1);
      }
    },
    async CargarEmpresasEmisoras() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerEmpresasEmisoras();
      this.catalogos.EmpresasEmisoras = resp.data as SRP.Models.EmpresaEmisora[];
    },
    async CargarTipoPago() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoTipoPago();
      this.catalogos.TipoPago = resp.data as SRP.Models.TipoPago[];
    },
    async CargarTipoRubro() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoTipoRubro();
      this.catalogos.TipoRubro = resp.data as SRP.Models.TipoRubro[];
    },
    async CargarCatalogoDiasInhabiles() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoDiasInhabiles();
      this.catalogos.CatalogoDiasInhabiles = resp.data as SRP.Models.vwDiaInhabil[];
    },
    async CargarUnidadNegocio() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerUnidadesNegocio();
      var json = resp.data;
      this.catalogos.UnidadesDeNegocio = json["UnidadesNegocio"];
    },
    async CargarTipoGasto() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoTipoGasto();
      this.catalogos.TipoGasto = resp.data as SRP.Models.TipoGasto[];
    },
    async CargarProveedores(empresaEmisoraId: string) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerProveedoresNAV(empresaEmisoraId);
      this.catalogos.Proveedores = resp.data as SRP.Models.CatalogoProveedorNAV[];
    },
    async CargarCatalogoCuentaBeneficiario(empresaEmisoraId: string, tipoPagoId: number) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoBeneficiariosSRP(
        empresaEmisoraId,
        tipoPagoId
      );
      this.catalogos.CatalogoCuentaBeneficiario = resp.data as SRP.Models.CatalogoCuentaBancaria[];
    },
    async ObtenerRelacionCatalogosBeneficiario(empresaEmisoraId: string, tipoPagoId: number) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerRelacionCatalogosBeneficiario(
        tipoPagoId,
        empresaEmisoraId
      );
      this.proveedorNAV = resp.data.data.esProveedorNav;
      this.catalogoCuentas = resp.data.data.esCatalogoCuentaBancaria;
      if (resp.data.data.esCatalogoCuentaBancaria) {
        this.catalogos.CatalogoCuentaBeneficiario = resp.data.data.datos as SRP.Models.CatalogoCuentaBancaria[];
      }
      if (resp.data.data.esProveedorNav) {
        this.catalogos.Proveedores = resp.data.data.datos as SRP.Models.CatalogoProveedorNAV[];
      }
      this.ValidarDinamismoDatosCuentaBancaria(this.requisicionPago.tipoPagoId);
    },
    async CargarCatalogoCuentaBancaria(empresaEmisoraId: string, tipoPagoId: number, catalogoCuentaBancariaId: string) {
      var cuentaBancariaId = parseInt(catalogoCuentaBancariaId);

      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCuentasBancariasBeneficiarioSRP(
        empresaEmisoraId,
        tipoPagoId,
        cuentaBancariaId
      );
      this.catalogos.CatalogoCuentaBancaria = resp.data as SRP.Models.CatalogoCuentaBancaria[];

      //El catálogo ya tiene cargada la info
      this.catalogos.CatalogoCuentaBancaria.map((e: any) => {
        this.requisicionPago.bancoBeneficiario = e.banco;
        this.requisicionPago.convenioServicioPago = e.convenio;
        this.referenciaRequired = e.referenciaRequired;
        this.referenciaMaxlength = e.referenciaMaxlength;
        this.requisicionPago.nombreBeneficiario = e.beneficiario;
      });
    },
    async CargarProveedorCuentas(empresaEmisoraId: string, beneficiarioId: string) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCuentasProveedorNAV(
        empresaEmisoraId,
        beneficiarioId
      );
      this.catalogos.ProveedorCuenta = resp.data as SRP.Models.CatalogoProveedorCuentaNAV[];
      if (this.catalogos.ProveedorCuenta.length > 0) {
        if (this.catalogos.ProveedorCuenta[0].clabe === "" || this.catalogos.ProveedorCuenta[0].cuenta === "") {
          this.verFormatoAlta = true;
        } else {
          this.verFormatoAlta = false;
        }
      } else {
        this.verFormatoAlta = true;
      }
    },
    async CargarAutorizadores(usuarioSolicitadorId: number) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerAutorizadoresAsignados(usuarioSolicitadorId);
      this.catalogos.Autorizadores = resp.data as SRP.Models.RolAutorizador[];
    },
    async ObtenerCatalogoBancos(clabe: string) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ValidarBanco(clabe.substring(0, 3));
      let infoBanco = JSON.parse(JSON.stringify(resp.data));

      if (Object.entries(infoBanco).length === 0) {
        //No hay bancos relacionados a esa CLABE
        this.requisicionPago.bancoBeneficiario = "";
        this.RestablecerCuentaBancaria();
        //Habilitar mensaje al usuario que no hay BANCO
        alert("LA CLABE NO PERTENECE A NINGÚN BANCO");
      } else {
        //Si hay banco entonces valida las posiciones que viene configurado el banco desde la tabla
        infoBanco.map((i: any) => {
          this.numPosicionesCuentaBanco = i.numPosicionesCuenta;
          this.lengthInputCuenta = i.numPosicionesCuenta;
          this.razonSocialBanco = i.razonSocialBanco;
          this.requisicionPago.bancoBeneficiario = i.nombreBanco;

          if (i.numPosicionesCuenta == 0) {
            this.txtPosicionesCuentaBanco = "";
            this.lengthInputCuenta = 18; //valor default máximo de cuenta bancaria
          } else {
            this.ValidarPosicionesCuenta(i.numPosicionesCuenta);
          }
        });
      }
    },
    async ObtenerTodoslosBancos() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoBancos();
      this.catalogos.Bancos = resp.data as any;
    },

    async CargarPlantilla() {

      this.actulizarPlantilla = true;
      this.esNuevaRequisicion = true;
      this.esCreacion = true;

      this.requisicionPago.bancoBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).bancoBeneficiario as any
      this.requisicionPago.conceptoPagos = (this.$props.plantilla as SRP.Models.RequisicionPago).conceptoPagos as any
      this.requisicionPago.convenioServicioPago = (this.$props.plantilla as SRP.Models.RequisicionPago).convenioServicioPago as any
      this.requisicionPago.correoBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).correoBeneficiario as any
      this.requisicionPago.cuentaBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).cuentaBeneficiario as any
      this.requisicionPago.cuentaCLABE = (this.$props.plantilla as SRP.Models.RequisicionPago).cuentaCLABE as any
      this.requisicionPago.divisa = (this.$props.plantilla as SRP.Models.RequisicionPago).divisa as any
      this.requisicionPago.empresaEmisoraId = (this.$props.plantilla as SRP.Models.RequisicionPago).empresaEmisoraId as any
      this.requisicionPago.formaPago = (this.$props.plantilla as SRP.Models.RequisicionPago).formaPago as any
      this.requisicionPago.formaPagoId = (this.$props.plantilla as SRP.Models.RequisicionPago).formaPagoId
      this.requisicionPago.importe = (this.$props.plantilla as SRP.Models.RequisicionPago).importe
      this.requisicionPago.nombreBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).nombreBeneficiario as any
      this.requisicionPago.razonSocial = (this.$props.plantilla as SRP.Models.RequisicionPago).razonSocial as any
      this.requisicionPago.referencia = (this.$props.plantilla as SRP.Models.RequisicionPago).referencia as any
      this.requisicionPago.rfcBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).rfcBeneficiario as any
      this.requisicionPago.tipoGastoId = (this.$props.plantilla as SRP.Models.RequisicionPago).tipoGastoId
      this.requisicionPago.tipoPago = (this.$props.plantilla as SRP.Models.RequisicionPago).tipoPago as any
      this.requisicionPago.tipoPagoId = (this.$props.plantilla as SRP.Models.RequisicionPago).tipoPagoId as any
      this.requisicionPago.tipoRubroId = (this.$props.plantilla as SRP.Models.RequisicionPago).tipoRubroId
      this.requisicionPago.unidadNegocio = (this.$props.plantilla as SRP.Models.RequisicionPago).unidadNegocio as any


      if (this.$props.plantilla.beneficiarioId) {
        this.requisicionPago.beneficiarioId = (this.$props.plantilla as SRP.Models.RequisicionPago).beneficiarioId as any
        await this.ObtenerRelacionCatalogosBeneficiario(this.requisicionPago.empresaEmisoraId, this.requisicionPago.tipoPagoId)
        this.requisicionPago.bancoBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).bancoBeneficiario as any
        this.requisicionPago.correoBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).correoBeneficiario as any
        this.requisicionPago.cuentaBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).cuentaBeneficiario as any
        this.requisicionPago.cuentaCLABE = (this.$props.plantilla as SRP.Models.RequisicionPago).cuentaCLABE as any
        this.requisicionPago.referencia = (this.$props.plantilla as SRP.Models.RequisicionPago).referencia as any
        this.requisicionPago.rfcBeneficiario = (this.$props.plantilla as SRP.Models.RequisicionPago).rfcBeneficiario as any
        this.requisicionPago.convenioServicioPago = (this.$props.plantilla as SRP.Models.RequisicionPago).convenioServicioPago as any

      }

      let banco = document.getElementById("bancoSelect") as HTMLInputElement;
      banco.disabled = true;
    }
  }
});
