import { Toast } from "@/vuex";
import { State } from "vue";
import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      toasts: [] as Toast[]
    };
  },
  mutations: {
    agregarToast(state: State, toast: Toast) {
      toast.id = Math.random();
      if (!toast.delay) toast.delay = 5000;
      state.toasts.push(toast);
    },
    removerToast(state: State, toastId: number) {
      let i = state.toasts.findIndex((t) => t.id == toastId);
      if (i >= 0) state.toasts.splice(i, 1);
    }
  }
});

export default store;
