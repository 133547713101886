
import { defineComponent } from "vue";
//Importar api cliente
import api from "@/servicios/apiSRP";
import { SRP } from "@/servicios/api"; // eslint-disable-line
import mixinRequisicionPago from "../mixins/mixinRequisicionPago";

export default defineComponent({
  name: "FormularioCentroCosto",
  mixins: [mixinRequisicionPago],
  data() {
    return {
      catalogos: {
        Sucursales: [] as SRP.Models.VwSucursal[],
        Areas: [] as SRP.Models.VwArea[]
      },
      AreasElegidas: [] as any,
      MetroElegidas: [] as any,
      ForaneasElegidas: [] as any,
      sumaSucursales: 0,
      sumaAreas: 0,
      sumaTotal: null as any,
      centroCostos: null as any,
      selecionarMetro: false,
      selecionarSucursalesForaneas: false,
      selecionarAreas: false

    };
  },
  beforeUpdate() {
    this.$emit("sumaTotal", this.sumaTotal);
  },
  watch: {
    selecionarMetro: {
      handler() {
        if (this.selecionarMetro) {
          let sucursalesMetro = this.catalogos.Sucursales.filter((sucursal) => !sucursal.esForanea)
          sucursalesMetro.forEach((sucursal: any) => {
            this.InsertarActualizarSucursalesMetro(sucursal, 'metro')
          })
        }
      }
    },
    selecionarSucursalesForaneas: {
      handler() {
        if (this.selecionarSucursalesForaneas) {
          let sucursalesForanea = this.catalogos.Sucursales.filter((sucursal) => sucursal.esForanea)
          sucursalesForanea.forEach((sucursal: any) => {
            this.InsertarActualizarSucursalesMetro(sucursal, 'foranea')
          })
        }
      }
    },
    selecionarAreas: {
      handler() {
        if (this.selecionarAreas) {
          let areas = this.catalogos.Areas;
          areas.forEach((sucursal: any) => {
            this.InsertarActualizarSucursalesMetro(sucursal, 'area')
          })
        }
      }
    },
    sumaTotal: {
      handler() {
        if (isNaN(this.sumaTotal as any)) this.sumaTotal = "...";
      }
    },
    AreasElegidas: {
      handler() {
        this.GenerarSessionStorage();
      }
    },
    MetroElegidas: {
      handler() {
        this.GenerarSessionStorage();
      }
    },
    ForaneasElegidas: {
      handler() {
        this.GenerarSessionStorage();
      }
    },
    "catalogos.Sucursales": {
      handler() {
        this.GenerarSessionStorage();
      },
      deep: true
    },
    "catalogos.Areas": {
      handler() {
        this.GenerarSessionStorage();
      },
      deep: true
    }
  },
  async mounted() {
    await Promise.all([this.ObtenerCatalogoSucursales(), this.ObtenerCatalogoAreas()]);

    const nuevaSolicitud = window.sessionStorage.getItem('requisicionPagoId') as any
    const plantillaId = this.$route.query.idPlantilla

    if (plantillaId) {
      window.sessionStorage.removeItem('requisicionPagoId')
      const resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerPlantillaRequisicion(plantillaId as any);
      window.sessionStorage.removeItem('centroCostos');
      window.localStorage.removeItem('JsonCC');
      const { centroCostos } = resp.data as any
      this.centroCostos = centroCostos
      this.LlenarCheckBoxes(this.centroCostos as string);
      this.verificarCasillasSelecionarTodos()
    }
    else if (nuevaSolicitud && this.$route.params.requisicionPagoId) {
      const resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerRequisicionPago(nuevaSolicitud)
      const { centroCostos: cc } = resp.data as SRP.Models.RequisicionPago
      this.centroCostos = cc;
      this.LlenarCheckBoxes(this.centroCostos as string);
      this.verificarCasillasSelecionarTodos()
    }
  },
  methods: {
    formatoMoneda(value: any) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    ActualizarSuma() {
      this.GenerarSessionStorage();
    },
    GenerarSessionStorage() {
      var jsonCostos = { sucursales: this.MetroElegidas.concat(this.ForaneasElegidas), areas: this.AreasElegidas };

      window.sessionStorage.setItem(
        "centroCostos",
        JSON.stringify({ sucursales: this.MetroElegidas.concat(this.ForaneasElegidas), areas: this.AreasElegidas })
      );
      jsonCostos.sucursales.forEach((obj: any) => {
        if ("monto" in obj)
          this.sumaSucursales = jsonCostos.sucursales.reduce((acc: any, obj: any) => acc + parseFloat(obj.monto || 0), 0);
        else obj.monto = 0
      });
      if (this.MetroElegidas.length == 0 && this.ForaneasElegidas.length == 0) this.sumaSucursales = 0;

      jsonCostos.areas.forEach((obj: any) => {
        if ("monto" in obj) {
          this.sumaAreas = jsonCostos.areas.reduce((acc: any, obj: any) => acc + parseFloat(obj.monto || 0), 0);
        }
        else obj.monto = 0
      });

      if (this.AreasElegidas.length == 0) this.sumaAreas = 0;

      this.sumaTotal = this.sumaSucursales + this.sumaAreas || 0;
      window.sessionStorage.setItem("sumaCentroCostos", this.sumaTotal as any);
    },
    async ObtenerCatalogoAreas() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoAreas();
      this.catalogos.Areas = resp.data as SRP.Models.VwArea[];
    },

    async ObtenerCatalogoSucursales() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoSucursales();
      this.catalogos.Sucursales = resp.data as SRP.Models.VwSucursal[];
    },
    LlenarCheckBoxes(jsoncc: string) {
      var CentroCostos = JSON.parse(jsoncc as string);
      this.catalogos.Areas.forEach((area) => {
        CentroCostos.areas.forEach((areacc: any) => {
          if (area.clave == areacc.clave) {
            (area as any).monto = areacc.monto || 0;
            this.AreasElegidas.push(area);
          }
        });
      });
      this.catalogos.Sucursales.forEach((sucursal) => {
        CentroCostos.sucursales.forEach((sucursalcc: any) => {
          if (sucursal.clave == sucursalcc.clave) {
            if (sucursalcc.esForanea == true) {
              (sucursal as any).monto = sucursalcc.monto || 0;
              this.ForaneasElegidas.push(sucursal);
            } else {
              (sucursal as any).monto = sucursalcc.monto || 0;
              this.MetroElegidas.push(sucursal);
            }
          }
        });
      });
    },

    InsertarActualizarSucursalesMetro(sucursal: any, tipo: string) {
      if (tipo === 'metro') {
        const existeSucursaleEnArreglo = this.MetroElegidas.some((s: any) => s.clave === sucursal.clave)
        if (!existeSucursaleEnArreglo) {
          sucursal.monto = 0
          this.MetroElegidas.push(sucursal)
        }
      }
      else if (tipo === 'foranea') {
        const existeSucursaleEnArreglo = this.ForaneasElegidas.some((s: any) => s.clave === sucursal.clave)
        if (!existeSucursaleEnArreglo) {
          sucursal.monto = 0
          this.ForaneasElegidas.push(sucursal)
        }
      }
      else {
        const existeSucursaleEnArreglo = this.AreasElegidas.some((s: any) => s.clave === sucursal.clave)
        if (!existeSucursaleEnArreglo) {
          sucursal.monto = 0
          this.AreasElegidas.push(sucursal)
        }
      }
    },

    verificarCasillasSelecionarTodos() {

      const numeroMetro = this.catalogos.Sucursales.filter((sucursal: any) => { return !sucursal.esForanea })
      const numeroForaneas = this.catalogos.Sucursales.filter((sucursal: any) => { return sucursal.esForanea })
      const numeroAreas = this.catalogos.Areas.length;

      if (numeroMetro.length === this.MetroElegidas.length) this.selecionarMetro = true;
      if (numeroForaneas.length === this.ForaneasElegidas.length) this.selecionarSucursalesForaneas = true;
      if (numeroAreas === this.AreasElegidas.length) this.selecionarAreas = true
    },

    ActualizarSeleccion() {
      const numeroMetro = this.catalogos.Sucursales.filter((sucursalMetro: any) => { return !sucursalMetro.esForanea })
      if (numeroMetro.length == this.MetroElegidas.length) this.selecionarMetro = true;
      else this.selecionarMetro = false;
    },

    ActualizarSeleccionForaneas() {
      const numeroForaneas = this.catalogos.Sucursales.filter((sucursal: any) => { return sucursal.esForanea })
      if (numeroForaneas.length == this.ForaneasElegidas.length) this.selecionarSucursalesForaneas = true;
      else this.selecionarSucursalesForaneas = false;
    },
    ActualizarSeleccionAreas() {
      const numeroAreas = this.catalogos.Areas
      if (numeroAreas.length == this.AreasElegidas.length) this.selecionarAreas = true;
      else this.selecionarAreas = false;
    },

    LimpiarSeleccion(event: any) {
      const check = event.target.checked
      const sucursal = event.target.value
      if (!check) {
        if (sucursal === 'metro') this.MetroElegidas = []
        else if (sucursal === 'foranea') this.ForaneasElegidas = []
        else if (sucursal === 'area') this.AreasElegidas = []
      }
    }
  }
});
