
import { defineComponent } from "vue";
export default defineComponent({
  name: "BotonHIR",
  props: {
    texto: {
      default: "Enviar",
      type: String
    },
    mensaje: {
      default: "Enviando",
      type: String
    },
    color: {
      default: "btn-primary",
      type: String
    },
    cargando: {
      default: false,
      type: Boolean
    },
    icono: {
      default: "fa fa-arrow-right",
      type: String
    },
    color_cargando: {
      default: "text-white",
      type: String
    }
  },
  data() {
    return {
      colorPrimario: this.color
    };
  }
});
