
import { defineComponent } from "vue";
//Importar api cliente
import api from "@/servicios/apiSRP";
import mixinRequisicionPago from "../mixins/mixinRequisicionPago";
import { SRP } from "@/servicios/api"; // eslint-disable-line

export default defineComponent({
  name: "DetalleCentroCosto",
  mixins: [mixinRequisicionPago],
  data() {
    return {
      catalogos: {
        Sucursales: [] as SRP.Models.VwSucursal[],
        Areas: [] as SRP.Models.VwArea[]
      },
      AreasElegidas: [] as any,
      MetroElegidas: [] as any,
      ForaneasElegidas: [] as any,
      sumaSucursales: 0,
      sumaAreas: 0,
      sumaTotal: 0,
      date: new Date().toISOString().substr(0, 10)
    };
  },
  props: {
    centroCostos: String
  },
  mounted() {
    this.ObtenerCatalogoSucursales();
    this.ObtenerCatalogoAreas();
  },
  methods: {
    async ObtenerCatalogoSucursales() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoSucursales();
      this.catalogos.Sucursales = resp.data as SRP.Models.VwSucursal[];
      if (this.catalogos.Sucursales != null) {
        this.CargarDatosSucursal();
      }
    },
    async ObtenerCatalogoAreas() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoAreas();
      this.catalogos.Areas = resp.data as SRP.Models.VwArea[];
      if (this.catalogos.Areas != null) {
        this.CargarDatosArea();
      }
    },
    async CargarDatosSucursal() {
      const jsonCentroCostos = JSON.parse(this.centroCostos as string, (key, value) => {
        if (typeof value === "string") {
          return value.toUpperCase();
        }
        return value;
      });

      if (jsonCentroCostos != 0) {
        this.catalogos.Sucursales.forEach((sucursal) => {
          jsonCentroCostos.sucursales.forEach((obj: any) => {
            if (sucursal.clave == obj.clave) {
              if (obj.esForanea) {
                (sucursal as any).monto = obj.monto || 0;
                this.ForaneasElegidas.push(sucursal);
              } else {
                (sucursal as any).monto = obj.monto || 0;
                this.MetroElegidas.push(sucursal);
              }
              this.sumaSucursales = jsonCentroCostos.sucursales.reduce(
                (acc: any, obj: any) => acc + parseFloat(obj.monto || 0),
                0
              );
            }
          });
        });
      }
    },
    async CargarDatosArea() {
      const jsonCentroCostos = JSON.parse(this.centroCostos as string, (key, value) => {
        if (typeof value === "string") {
          return value.toUpperCase();
        }
        return value;
      });
      if (jsonCentroCostos != 0) {
        this.catalogos.Areas.forEach((area) => {
          jsonCentroCostos.areas.forEach((areacc: any) => {
            if (area.clave == areacc.clave) {
              (area as any).monto = areacc.monto || 0;
              this.AreasElegidas.push(area);
              this.sumaAreas = jsonCentroCostos.areas.reduce((acc: any, obj: any) => acc + parseFloat(obj.monto || 0), 0);
            }
          });
        });
      }
    }
  }
});
