export class PlantillaRequisicion {
    plantillaRequisicionId = 0
    razonSocial = ''
    tipoPagoId = 0
    nombreBeneficiario = ''
    correoBeneficiario = ''
    importe = 0.0
    conceptoPagos = ''
    tipoGastoId = 0
    tipoRubroId = 0
    formaPago = ''
    aplicaCuentaConClabe = false
    cuentaBeneficiario = ''
    cuentaCLABE = ''
    aplicaConvenioConReferencia = false
    convenioServicioPago = ''
    referencia = ''
    bancoBeneficiario = ''
}