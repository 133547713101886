
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgresoSolicitud",
  props: {
    estatusRequisicionId: String,
    tieneVerificador: Boolean
  },
  data() {
    return {
      labelEnCreacion: false,
      labelPagoAplicado: false,
      completado: "",
      Estado: {
        enCreacion: "",
        enVerificacion: "",
        enAutorizacion: "",
        enCXP: "",
        enProcesoPago: "",
        pagoAplicado: ""
      }
    };
  },
  watch: {
    estatusRequisicionId: { handler: "ListenerEstatus", immediate: true }
  },
  methods: {
    ListenerEstatus() {
      switch (this.estatusRequisicionId) {
        case "EN_CREACION":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "";
          this.Estado.enAutorizacion = "";
          this.Estado.enCXP = "";
          this.Estado.enProcesoPago = "";
          this.Estado.pagoAplicado = "";
          break;
        case "EN_ACLARACION":
          this.labelEnCreacion = true;
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "";
          this.Estado.enAutorizacion = "";
          this.Estado.enCXP = "";
          this.Estado.enProcesoPago = "";
          this.Estado.pagoAplicado = "";
          break;
        case "EN_VERIFICACION_AREA":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "";
          this.Estado.enCXP = "";
          this.Estado.enProcesoPago = "";
          this.Estado.pagoAplicado = "";
          break;
        case "EN_AUTORIZACION":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "is-complete";
          this.Estado.enCXP = "";
          this.Estado.enProcesoPago = "";
          this.Estado.pagoAplicado = "";
          break;
        case "EN_VALIDACION_CXP":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "is-complete";
          this.Estado.enCXP = "is-complete";
          this.Estado.enProcesoPago = "";
          this.Estado.pagoAplicado = "";
          break;
        case "EN_AUTORIZACION_CXP":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "is-complete";
          this.Estado.enCXP = "is-complete";
          this.Estado.enProcesoPago = "";
          this.Estado.pagoAplicado = "";
          break;
        case "EN_TESORERIA":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "is-complete";
          this.Estado.enCXP = "is-complete";
          this.Estado.enProcesoPago = "is-complete";
          this.Estado.pagoAplicado = "";
          break;
        case "PAGO_REALIZADO":
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "is-complete";
          this.Estado.enCXP = "is-complete";
          this.Estado.enProcesoPago = "is-complete";
          this.Estado.pagoAplicado = "is-complete";
          break;
        case "PAGO_RECHAZADO":
          this.labelPagoAplicado = true;
          this.Estado.enCreacion = "is-complete";
          this.Estado.enVerificacion = "is-complete";
          this.Estado.enAutorizacion = "is-complete";
          this.Estado.enCXP = "is-complete";
          this.Estado.enProcesoPago = "is-complete";
          this.Estado.pagoAplicado = "is-complete";
          break;
      }
    }
  }
});
