
import { defineComponent } from "vue";
import api from "@/servicios/apiSRP";
import type { SRP } from "@/servicios/api"; //eslint-disable-line

export default defineComponent({
  name: "ModalSoporte",
  props: {
    rolUsuario: String,
    rolId: String,
    requisicion_id: String,
    estatusRequisicionId: String,
    documentos: Array,
    componenteId: String
  },
  data() {
    return {
      requisicionPagoId: window.sessionStorage.getItem("requisicionPagoId") as string,
      estatusRequisicion: "" as any,
      observaciones: "",
      usuarioId: 0,
      bitacora: {},
      requisicion: {} as SRP.Models.RequisicionPago,
      comentario: "",
      archivosRequisicion: [] as any,
      documentosExistentes: [] as any,
      requisicionIdExistente: null as any,
      rutaDocumentoExistente: "" as any,
      documentoPdfId: "" as any,
      documentoId: 0 as any,
      rutaDocumento: "" as any
    };
  },
  mounted() {
    this.traerRequisicion();
  },
  watch: {
    requisicion_id() {
      this.traerRequisicion();
    }
  },
  methods: {
    async traerRequisicion() {
      if (typeof this.requisicion_id != "undefined") this.requisicionPagoId = this.requisicion_id;
      let id_requisicion = Number(this.requisicion_id);
      if (id_requisicion != 0) {
        var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerRequisicionPago(
          parseInt(this.requisicionPagoId)
        );
        this.requisicion = resp.data as SRP.Models.RequisicionPago;
        this.rutaDocumento =
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" +
          this.requisicion.documentos![0].documentoRequisicionPagoId +
          "&descargaPdf=false";
      }
    },

    async MostrarArchivo(documentoId: number, tipo: String) {
      (document.getElementById(
        "iFrameDocumentos" + this.componenteId
      ) as HTMLImageElement).src = (document.getElementById(
        "iFrameDocumentos" + this.componenteId
      ) as HTMLImageElement).src;
      if (tipo != "APPLICATION/PDF") {
        window.open(
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=true"
        );
      } else {
        this.rutaDocumento =
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false";
        this.documentoPdfId = documentoId as any;
      }
    }
  }
});
