export default {
  methods: {
    DarFormatoFecha(fecha) {
      fecha.substring(0, 10).replaceAll("-", "/");
      return fecha.substring(8, 10) + "/" + fecha.substring(5, 7) + "/" + fecha.substring(0, 4);
    },
    formatearImporte(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    limpiarImporte(value) {
      if (value != null && value.toString().includes(",")) {
        return value.replace(/,/g, "");
      } else if (value == "") {
        return 0;
      }
    },
    FormatoFechaHora(fecha) {
      fecha.substring(0, 16).replaceAll("T", " ");

      return `${fecha.substring(8, 10)}/${fecha.substring(5, 7)}/${fecha.substring(0, 4)} ${fecha.substring(11, 16)}`;
    },
    sinCaracteresEspeciales(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z-0-9-,-/-.-ñÑ-áÁ-éÉ-íÍ-óÓ-úÚ_ ]+$/.test(char)) return true;
      else e.preventDefault();
    }
  }
};
