
import "@/sass/select2.scss";
import $ from "jquery";
import "select2";

// eslint-disable-next-line
import { defineComponent, PropType } from "vue";
// eslint-disable-next-line
import { DataFormat } from "select2";

/**
 * Soporta los mismos parámetros de configuración que Select2, los tipos válidos para el v-model son Number y string
 * Soporta una lista de opciones que cambie externamente desde el componente.
 */
export default defineComponent({
  props: {
    etiqueta: { type: String, default: "Seleccionar una opción..." },
    modelValue: { type: Object as PropType<Number | string>, default: undefined },
    data: { type: Object as PropType<DataFormat[]>, default: () => [] },
    tipo: { type: String, default: "string" }
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue(value) {
      $(this.$el).val(value).trigger("change");
    },
    data(options) {
      $(this.$el)
        .empty()
        .select2({ data: options, width: "100%" })
        .val(this.modelValue as any)
        .trigger("change");
    }
  },
  async mounted() {
    var vm = this;
    let select = this.$refs["select2"] as HTMLSelectElement;
    await (vm as any).$nextTick();
    $(select)
      .select2({ data: this.data, width: "100%" })
      .val(this.modelValue as any)
      .trigger("change")
      .on("change", function () {
        if (vm.tipo == "number") vm.$emit("update:modelValue", Number(this.value));
        else vm.$emit("update:modelValue", this.value);
      });
  },
  beforeUnmount() {
    $(this.$el).off().select2("destroy");
  }
});
