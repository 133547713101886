
import { defineComponent } from "vue";
import api from "@/servicios/apiSRP";
import swal from "sweetalert";

import BotonPrimario from "../../SRP/Areas/Requisicion Pago/components/BotonPrimario.vue";

export default defineComponent({
  name: "LOGIN",
  components: {
    BotonPrimario
  },
  data() {
    return {
      email: "",
      password: "",
      roles: [],
      usuario: [],
      aviso: "",
      estaCargandoIniciar: false,
      formularioValidado: false,
      error: undefined
    };
  },
  mounted() {
    var url = new URL(location.href).toString();
    if (url == "https://srp-test.hircasa.com/") {
      this.aviso = "PRUEBAS";
    }

    if (url == "https://localhost:5001/") {
      this.aviso = "DESARROLLO";
    }

    if (url == "https://srp.bprcasa.com/") {
      this.aviso = "";
    }
    setTimeout(() => {
      this.estaCargandoIniciar = false;
    }, 2000);
  },
  methods: {
    async ComprobarExistenciaVerificador() {
      let usuario_id = window.sessionStorage.getItem("usuarioId");
      try {
        let resp = await api.SRP.RequisicionPago.RequisicionPago.ComprobarExistenciaVerificadorArea(
          parseInt(usuario_id!)
        );
        console.log("Se cargó correctamente, ¿el usuario logueado tiene verificador? : ", typeof resp.data);
        window.sessionStorage.setItem("tieneVerificador", resp.data as any);
      } catch (e) {
        console.error("Error al consultar el método para comprobar existencia de verificador ", e);
      }
    },
    async login() {
      this.estaCargandoIniciar = true;
      this.formularioValidado = true;

      let formularioValidado = (this.$refs["formulario"] as HTMLFormElement).checkValidity();
      var obj = { usuario: this.email, contrasenia: this.password };

      if (formularioValidado) {
        var mensaje = null;
        try {
          mensaje = await api.Identity.Login(obj);
          if (mensaje.data.estado == "OK") {
            await this.cargarRoles();
            this.$router.push({ name: "srp-requisicion-pago-dashboard" });
            this.estaCargandoIniciar = false;
          } else {
            swal("¡Ups!", `${mensaje.data.mensaje}`, "error", {
              buttons: ["Cerrar", true]
            });
            this.estaCargandoIniciar = false;
          }
        } catch (error) {
          swal("¡Ups!", ` Usuario o contraseña incorrecta`, "error", {
            buttons: ["Cerrar", true]
          });
          this.estaCargandoIniciar = false;
        }
        //Obtener nombre y roles , Obtener configuracion de pagina principal,
      }
      setTimeout(() => {
        this.estaCargandoIniciar = false;
      }, 500);
    },
    async cargarRoles() {
      const objRol = [] as any;
      var resp = await api.Identity.Roles();
      var datos = resp.data.data;
      this.roles = datos.roles;
      this.usuario = datos.usuario;

      this.roles.map(async function (rol: any) {
        if (rol.name.indexOf("Solicita") == 0) {
          objRol.push("Solicitador");
        }
        if (rol.name.indexOf("Autoriza") == 0) {
          objRol.push("Autorizador área");
        }

        if (rol.name.indexOf("Verificador área") == 0) {
          objRol.push(rol.name);
        }
        if (rol.name.indexOf("Verificador CXP") == 0) {
          objRol.push("Verificador CXP");
        }
        if (rol.name.indexOf("Director Adjunto de Tesorería") == 0) {
          objRol.push("Tesoreria");
        }
        if (rol.name.indexOf("Conta") == 0) {
          objRol.push("Autorizador CXP");
        }
        if (rol.name.indexOf("Auditoria") == 0) {
          objRol.push("Auditoria");
        }
      });
      this.asignarSession(objRol);
      for (let [key, value] of Object.entries(this.usuario)) {
        await this.asignarSessionStorage(key, value);
      }
    },
    async asignarSession(...datos: string[]) {
      window.sessionStorage.setItem("listaRoles", JSON.stringify(datos));
    },
    async asignarSessionStorage(key: any, value: any) {
      switch (key) {
        case "id":
          window.sessionStorage.setItem("usuarioId", value);
          this.ComprobarExistenciaVerificador();
          break;
        case "userName":
          window.sessionStorage.setItem("usuarioNombre", value);
          break;
        default:
          break;
      }
    }
  }
});
