import * as VueRouter from "vue-router";
import RouterViewGenerico from "../views/RouterViewGenerico.vue";
import rutasSRP from "../Aplicaciones/SRP/rutas";

const routes = [
  {
    path: "/",
    component: RouterViewGenerico,
    children: rutasSRP
  }
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(process.env.BASE_URL),
  routes
});
router.onError((err) => console.log(err));

export default router;
