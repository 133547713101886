<template>
  <div class="carrusel">
    <swiper
      :pagination="{
        type: 'fraction',
        clickable: true
      }"
      :keyboard="{
        enabled: 'true'
      }"
      :modules="modules"
      :options="swiperOptions"
      navigation
      @swiper="setSwiperRef"
      @slideChange="cambiarSlide"
      :space-between="0"
    >
      <swiper-slide
        class="slide"
        v-for="(DatosRequisicionDetalle, index) in Historico"
        :key="DatosRequisicionDetalle.requisicionPagoId"
        :virtualIndex="index"
        :id="DatosRequisicionDetalle.requisicionPagoId"
        :no-swiping="true"
      >
        <div class="row">
          <div class="col-9">
            <TarjetaDetalle
              v-if="DatosRequisicionDetalle"
              :requisicionPagoId="DatosRequisicionDetalle.requisicionPagoId"
              :razonSocial="DatosRequisicionDetalle.razonSocial"
              :tipoPagoId="DatosRequisicionDetalle.tipoPagoId"
              :tipoPago="DatosRequisicionDetalle.tipoPago"
              :fechaSolicitud="DatosRequisicionDetalle.fechaSolicitud"
              :fechaPropuesta="DatosRequisicionDetalle.fechaProgramadaPago"
              :nombreBeneficiario="DatosRequisicionDetalle.nombreBeneficiario"
              :importe="DatosRequisicionDetalle.importe"
              :medioInstruccion="DatosRequisicionDetalle.formaPago"
              :numeroFactura="DatosRequisicionDetalle.numeroFactura"
              :uuid:="DatosRequisicionDetalle.uuid"
              :observacionInstruccion="DatosRequisicionDetalle.observacionPago"
              :conceptoPagoId="DatosRequisicionDetalle.conceptoPagoId"
              :conceptoPagos="DatosRequisicionDetalle.conceptoPagos"
              :nombreTipoGasto="DatosRequisicionDetalle.tipoGasto.nombreGasto"
              :nombreRubro="DatosRequisicionDetalle.nombreRubro"
              :cuentaBeneficiario="DatosRequisicionDetalle.cuentaBeneficiario"
              :bancoBeneficiario="DatosRequisicionDetalle.bancoBeneficiario"
              :clabe="DatosRequisicionDetalle.cuentaCLABE"
              :referencia="DatosRequisicionDetalle.referencia"
              :divisa="DatosRequisicionDetalle.divisaId"
              :rfcBeneficiario="DatosRequisicionDetalle.rfcBeneficiario"
              :convenioServicioPago="DatosRequisicionDetalle.convenioServicioPago"
              :usuarioAutorizadorId="DatosRequisicionDetalle.usuarioAutorizadorId"
              :usuarioSolicitadorId="DatosRequisicionDetalle.usuarioSolicitadorId"
              :usuarioAutorizador="DatosRequisicionDetalle.usuarioAutorizador"
              :usuarioSolicitante="DatosRequisicionDetalle.usuarioSolicitante"
              :estatusRequisicionId="DatosRequisicionDetalle.estatusRequisicionId"
              :rolUsuario="rolUsuario"
              :correoSolicitante="DatosRequisicionDetalle.correoSolicitante"
              :correoAutorizador="DatosRequisicionDetalle.correoAutorizador"
              :documentos="DatosRequisicionDetalle.documentos"
              :centroCostos="DatosRequisicionDetalle.centroCostos"
              :unidadNegocio="DatosRequisicionDetalle.unidadNegocio"
              :tieneVerificador="DatosRequisicionDetalle.correoVerificadorArea != null ? true : false"
            >
            </TarjetaDetalle>
          </div>
          <div class="col">
            <SeccionComentarios
              :rolUsuario="rolUsuario"
              :estatusRequisicionId="DatosRequisicionDetalle.estatusRequisicionId"
              :requsicionId="DatosRequisicionDetalle.requisicionPagoId"
              :requisicion_de_carrusel="Carrusel"
              @toParent="removerSlide"
            ></SeccionComentarios>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- <button @click="removerSlide">Borra Actual</button> -->
  </div>
</template>
<script>
import { SRP } from "@/servicios/api"; // eslint-disable-line
import api from "@/servicios/apiSRP";
import TarjetaDetalle from "../components/TarjetaDetalle.vue";
import SeccionComentarios from "../components/SeccionComentarios.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Keyboard, Manipulation } from "swiper";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Keyboard, Manipulation]);
import { Virtual } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.min.css";

export default {
  components: {
    TarjetaDetalle,
    Swiper,
    SwiperSlide,
    SeccionComentarios
  },
  setup() {
    return {
      Virtual
    };
  },
  data() {
    return {
      requisicionPagoId: Number,
      DatosRequisicionDetalle: String,
      Historico: [],
      usuarioId: 0,
      isActive: Boolean,
      rolUsuario: "",
      RequsicionActual: [],
      swiperRef: null,
      Documentos: [],
      Carrusel: true,
      swiperOptions: {
        autoHeight: true, //enable auto height
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        loop: true,
        noSwiping: true,
        noSwipingClass: "swiper-no-swiping"
      }
    };
  },
  methods: {
    setSwiperRef(swiper) {
      this.swiperRef = swiper;
    },
    cambiarSlide() {
      let index = this.swiperRef.activeIndex;
      let slide_actual = document.querySelectorAll("[data-swiper-slide-index='" + index + "']");
      let _id_slide_actual = slide_actual[0].getAttribute("id");
      window.sessionStorage.setItem("requisicionPagoId", _id_slide_actual);
    },
    removerSlide() {
      let index = this.swiperRef.activeIndex;
      this.swiperRef.removeSlide(index);
      this.swiperRef.update();
    },
    borrar() {
      this.swiperRef.removeSlide(0);
      this.swiperRef.update();
    },
    async CargarHistorialAutorizadorCXPConDocumentos() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoAutorizadorCXP();
      this.Historico = resp.data;
    },
    async CargarHistorialVerificadorCXPConDocumentos() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoVerificadorCXP();
      this.Historico = resp.data;
    }
  },
  mounted() {
    this.usuarioId = window.sessionStorage.getItem("usuarioId");
    this.rolUsuario = this.$route.params["rolUsuario"];
    if (this.rolUsuario == "Autorizador CXP") {
      this.CargarHistorialAutorizadorCXPConDocumentos();
    } else {
      this.CargarHistorialVerificadorCXPConDocumentos();
    }
  }
};
</script>
<style scoped>
.slide {
  transform: scale(0.9);
}
</style>
