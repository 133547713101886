import vuerouter from "vue-router";
import FormularioRequisicionPago from "./views/FormularioRequisicionPago.vue";
import FormularioDatosGenerales from "./views/FormularioDatosGenerales.vue";
import FormularioCentroCosto from "./views/FormularioCentroCosto.vue";
import HistoricoRequisionesPago from "./views/HistoricoRequisicionPago.vue";
import RequisicionesPagoHistorico from "./views/RequisicionPagoHistorico.vue";
import VistaDetalle from "./views/VistaDetalle.vue";
import Dashboard from "./views/Dashboard.vue";
import HistoricoCarrusel from "./views/HistoricoRequsicionesCarrusel.vue";
import PlantillasVista from "./views/PlantillasVista.vue"

const rutas: vuerouter.RouteRecordRaw[] = [
  {
    component: HistoricoRequisionesPago,
    path: ""
  },
  {
    component: Dashboard,
    path: "/dashboard",
    name: "srp-requisicion-pago-dashboard"
  },
  {
    component: HistoricoRequisionesPago,
    path: "/historico-requisicion-pago/:rolUsuario",
    name: "srp-requisicion-pago-historico"
  },
  {
    component: FormularioRequisicionPago,
    path: "/formulario-requisicion-pago",
    name:"crear-requisicion"
  },
  {
    component: FormularioRequisicionPago,
    path: "/formulario-requisicion-pago",
    name:"crear-requisicion-plantilla"
  },
  {
    component: FormularioRequisicionPago,
    path: "/editar-requisicion-pago/:requisicionPagoId"
  },
  {
    component: FormularioCentroCosto,
    path: "/formulario-centro-costo"
  },
  {
    component: VistaDetalle,
    path: "/vista-detalle-solicitud/:rolUsuario"
  },
  {
    component: RequisicionesPagoHistorico,
    path: "/requisicion-pago-historico/:rolUsuario",
    name: "srp-requisicion-pago-requisiciones"
  },
  {
    component: HistoricoCarrusel,
    path: "/historico-requisiones-carrusel/:rolUsuario",
    name: "srp-requisicion-pago-requisiciones-carrusel"
  },
  {
    component: PlantillasVista,
    path: "/plantillas/",
    name: "srp-requisicion-pago-plantillas"
  }
];

export default rutas;
