
import { SRP } from "@/servicios/api"; // eslint-disable-line
import { defineComponent } from "vue";
import api from "@/servicios/apiSRP";
import ModalDocumento from "../components/ModalDocumento.vue";
import swal2 from "sweetalert2";

export default defineComponent({
  name: "SeccionComentarios",
  props: {
    rolUsuario: String,
    estatusRequisicionId: String,
    requsicionId: Number,
    requisicion_de_carrusel: Boolean,
    tieneAutorizador: Boolean
  },
  components: {
    ModalDocumento
  },
  data() {
    return {
      requisicionPagoId: window.sessionStorage.getItem("requisicionPagoId") as string,
      estatusRequisicion: "" as any,
      observaciones: "",
      usuarioId: 0,
      autorizadorId: 0,
      bitacora: {},
      comentario: "",
      archivosRequisicion: [] as any,
      documentosExistentes: [] as any,
      requisicionIdExistente: null as any,
      rutaDocumentoExistente: "" as any,
      documentoPdfId: "" as any,
      documentoId: 0 as any,
      catalogos: {
        Autorizadores: [] as SRP.Models.RolAutorizador[]
      },
      customChange: "" as any
    };
  },
  async mounted() {
    await this.traerComentarios();
    let x = window.sessionStorage.getItem("usuarioId") as string;
    this.usuarioId = +x;
    await this.CargarAutorizadores(this.usuarioId);
  },
  watch: {
    // requisicionPagoId() {
    //   this.traerComentarios();
    // }
  },
  methods: {
    SubirArchivo() {
      let archivo = (this.$refs["inputArchivo"] as HTMLInputElement).files; //obtengo la ruta del archivo
      for (let item of archivo as FileList) {
        if (item.type == "application/pdf" || item.type == "text/xml" || item.type == "xlsx" || item.type == "docx") {
          this.archivosRequisicion.push(item);
        } else {
          alert("archivo inválido");
          (this.$refs["inputArchivo"] as HTMLInputElement).value = "";
        }
      }
    },
    DragArchivo(event: DragEvent) {
      event.preventDefault();
    },
    DropArchivo(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer) {
        (this.$refs["inputArchivo"] as any).files = event.dataTransfer.files;
        this.archivosRequisicion.push(...event.dataTransfer.files);
      }
    },
    ClickAInput(event: Event) {
      event.preventDefault();
      (this.$refs["inputArchivo"] as HTMLInputElement).click();
    },
    EliminarArchivo(nombreArchivo: String) {
      var index = this.archivosRequisicion
        .map(function (e: any) {
          return e.name;
        })
        .indexOf(nombreArchivo);
      if (index > -1) {
        this.archivosRequisicion.splice(index, 1);
        if (index < 1) (this.$refs["inputArchivo"] as HTMLInputElement).value = "";
      }
    },
    async validarRuta() {
      let ruta = this.$route.path;
      if (ruta != "/formulario-requisicion-pago") {
        this.requisicionIdExistente = this.$route.params["requisicionPagoId"] as any;
      }
      return ruta;
    },
    async MostrarArchivo(documentoId: number, tipo: String) {
      if (tipo != "PDF") {
        window.open(
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false"
        );
      } else {
        this.rutaDocumentoExistente =
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false";
        this.documentoPdfId = documentoId as any;
      }
    },
    async EliminarArchivoExistente(documentoId: number) {
      await api.SRP.RequisicionPago.RequisicionPago.EliminarDocumento(documentoId);
      var index = this.documentosExistentes
        .map((e: any) => {
          return e.documentoRequisicionPagoId;
        })
        .indexOf(documentoId);
      if (index > -1) {
        this.documentosExistentes.splice(index, 1);
      }
    },
    async EnviarDocumentos(requisicionId: number) {
      for (var archivo of this.archivosRequisicion) {
        await api.SRP.RequisicionPago.RequisicionPago.GuardarDocumentoRequisicionPago(requisicionId, archivo, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      }
    },
    MostrarSelector: function () {
      console.log("SeccionComentarios| estatusRequisicion: " + this.estatusRequisicion);
    },
    async ActualizarEstatus() {
      const Toast = swal2.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        showCloseButton: true,
        width: "500px",
        didOpen: (toast: any) => {
          toast.addEventListener("mouseenter", swal2.stopTimer);
          toast.addEventListener("mouseleave", swal2.resumeTimer);
        }
      });
      this.EnviarDocumentos(parseInt(this.requisicionPagoId));

      if (
        this.estatusRequisicion == "1" ||
        this.estatusRequisicion == "2" ||
        this.estatusRequisicion == "3" ||
        this.estatusRequisicion == "4" ||
        this.estatusRequisicion == "7"
      ) {
        if (this.estatusRequisicionId == "EN_VERIFICACION_AREA") {
          if (this.autorizadorId == 0) {
            alert("Debes seleccionar el autorizador");
          } else {
            await api.SRP.RequisicionPago.RequisicionPago.ActualizarEstatusRequisicionPago(
              this.requsicionId!,
              this.usuarioId,
              this.autorizadorId,
              this.estatusRequisicion,
              this.observaciones
            );

            this.$router.push("/historico-requisicion-pago/" + this.rolUsuario);
            this.estatusRequisicion = "";
            this.observaciones = "";
          }
        } else {
          await api.SRP.RequisicionPago.RequisicionPago.ActualizarEstatusRequisicionPago(
            this.requsicionId!,
            this.usuarioId,
            this.autorizadorId,
            this.estatusRequisicion,
            this.observaciones
          );

          if (this.requisicion_de_carrusel) {
            this.$emit("toParent");

            Toast.fire({
              icon: "success",
              title: "¡Excelente!",
              text: `Se envió con éxito la requisición ${this.requsicionId!}`
            });
          } else {
            this.$router.push("/historico-requisicion-pago/" + this.rolUsuario);
            this.estatusRequisicion = "";
            this.observaciones = "";
          }
        }
      } else {
        //Si se ejecuta la accion con exito
        if (this.observaciones != "" && this.estatusRequisicion != "") {
          await api.SRP.RequisicionPago.RequisicionPago.ActualizarEstatusRequisicionPago(
            this.requsicionId!,
            this.usuarioId,
            this.autorizadorId,
            this.estatusRequisicion,
            this.observaciones
          );
          if (this.requisicion_de_carrusel) {
            this.$emit("toParent");

            Toast.fire({
              icon: "success",
              title: "¡Excelente!",
              text: `Se ejecutó la acción con éxito  ${this.requsicionId!}`
            });
          } else {
            this.$router.push("/historico-requisicion-pago/" + this.rolUsuario);
            this.estatusRequisicion = "";
            this.observaciones = "";
          }
        } else {
          // si falta una acción
          if (this.estatusRequisicion == "") {
            Toast.fire({
              icon: "warning",
              title: "¡Atención!",
              text: `Debes seleccionar una respuesta a la solicitud`
            });
          } else {
            //si falta un motivo
            Toast.fire({
              icon: "warning",
              title: "¡Atención!",
              text: `Debes colocar un motivo`
            });
          }
        }
      }
    },
    async traerComentarios() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerBitacoraEstatusRequisicionPago(
        this.requsicionId!
      );
      this.bitacora = resp.data;
    },
    async EnviarComentario() {
      if (this.comentario != "") {
        await api.SRP.RequisicionPago.RequisicionPago.GuardarComentario(
          this.requsicionId!,
          this.usuarioId,
          this.comentario
        );
        this.traerComentarios();
        this.comentario = "";
      } else {
        alert("El comentario es requerido");
      }
    },
    async CargarAutorizadores(usuarioVerificadorId: number) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerAutorizadoresAsignados(usuarioVerificadorId);
      this.catalogos.Autorizadores = resp.data as SRP.Models.RolAutorizador[];
    }
  }
});
