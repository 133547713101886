import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { ref: "select2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("select", _hoisted_1, [
    (_ctx.etiqueta)
      ? (_openBlock(), _createBlock("option", {
          key: 0,
          selected: _ctx.modelValue === undefined,
          value: "",
          disabled: ""
        }, _toDisplayString(_ctx.etiqueta), 9, ["selected"]))
      : _createCommentVNode("", true)
  ], 512))
}