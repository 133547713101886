import { Configuration, EstadoRespuesta, IdentityApi, Identity, Test, SRP } from "./api";
import axios from "axios";

let token = "";
let usuario = "";
let contrasenia = "";

let axiosApi = axios.create();
axiosApi.interceptors.response.use(async (respuesta) => {
  if ("Token-Expired" in respuesta.headers) {
    await api.Identity.Login({
      contrasenia,
      usuario
    });
    respuesta.config.headers!["Authorization"] = (<(name: string) => string>configuracion.accessToken)("Authorization");
    respuesta = await axiosApi.request(respuesta.config);
  }
  function deserializar(objeto: any) {
    if (!(objeto instanceof Object) || !("$values" in objeto)) return objeto;
    let obj2 = {} as any;
    if ("$values" in objeto) {
      obj2 = objeto.$values;
      obj2.$id = objeto.$id;
      if ("$ref" in objeto) {
        obj2.$ref = objeto.$ref;
      }
      obj2.map((e: any) => deserializar(e));
    }
    for (let ref in objeto) {
      obj2[ref] = deserializar(objeto[ref]);
    }
    return obj2;
  }
  respuesta.data = deserializar(respuesta.data);
  return respuesta;
});

let configuracion = new Configuration({
  accessToken: () => {
    return token;
  },
  basePath:
    window.location.protocol +
    "//" +
    (window.location.host + process.env.BASE_URL).replace(/\/\//g, "/").replace(/\/$/g, "")
});

// AGREGAR AQUÍ LOS DEMÁS CONTROLADORES DE LA MISMA FORMA COMO ESTÁN DECLARADOS LOS DEMÁS:
let api = {
  Identity: new IdentityApi(configuracion, undefined, axiosApi),
  SRP: {
    RequisicionPago: {
      RequisicionPago: new SRP.RequisicionPago.RequisicionPagoApi(configuracion, undefined, axiosApi),
      ActualizarCatalogo: new SRP.RequisicionPago.AdministracionCatalogoApi(configuracion, undefined, axiosApi)
    }
  },
  Test: {
    Test: {
      WeatherForecast: new Test.Test.WeatherForecastApi(configuracion, undefined, axiosApi)
    }
  }
};

let loginOriginal = api.Identity.Login;

api.Identity.Login = async (loginBody: Identity.LoginBody, options?: any) => {
  let r = await loginOriginal.call(api.Identity, loginBody, options);
  if (r.data.estado == EstadoRespuesta.OK) {
    token = r.data.data;
    usuario = loginBody.usuario;
    contrasenia = loginBody.contrasenia;
  }
  return r;
};

export default api;
