<template>
  <div class="list-group p-4">
    <a class="list-group-item list-group-item-action flex-column align-items-start active">
      <div class="d-flex w-100 justify-content-between my-2 my-2">
        <h5 class="mb-1">¡ESTÁS EN LA URL CORRECTA!<i class="far fa-smile-beam"></i></h5>
        <small>AVISO</small>
      </div>
      <p class="mb-1">Fecha del cambio: 04/Mayo/2024.</p>
    </a>
    <a class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2 my-2">
        <h5 class="mb-1">Incorporación de plantillas <i class="fas fa-star"></i></h5>
        <small>Informativo</small>
      </div>
      <p class="mb-1">Ahora puedes generar y utilizar plantillas hechas para tu área, las cuales te ayudarán a reducir
        tiempos de captura y aumentar tu productividad. Utiliza esta nueva forma de trabajar.</p>
    </a>
    <a class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2 my-2">
        <h5 class="mb-1">¿El beneficiario no cuenta con información bancaria registrada o actualizada?</h5>
        <small>Informativo</small>
      </div>
      <p class="mb-1">Favor de descargar el formato de : <b>Alta Proveedores</b> llenarlo y enviarlo al correo de <a
          class="text-decoration-none" href="mailto:cuentasporpagar@hircasa.com.mx">cuentasporpagar@hircasa.com.mx</a>.
      </p>
      <a class="" :href="`${publicPath}altaproveedores.pdf`" download>Descargar Formato</a>
    </a>
    <a href="http://tickets.bprcasa.com/Login.aspx" target="_blank"
      class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2 my-2">
        <h5 class="mb-1">Soporte técnico <i class="fas fa-comment-medical"></i></h5>
        <small>Informativo</small>
      </div>
      <p class="mb-1">Ahora todo el soporte será a través de ¡Tickets! <i class="fas fa-hand-pointer"></i></p>
      <small></small>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2">
        <h5 class="mb-1">¿Vacaciones?¿Días Naranja? <i class="fas fa-umbrella-beach"></i></h5>
        <small>Informativo</small>
      </div>
      <p class="mb-1">
        Anticipa solicitar la actualización de quién autorizará las requisiciones de pago en tu ausencia.
      </p>
      <small class="text-muted">Seguiremos trabajando para automatizarlo</small>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2">
        <h5 class="mb-1">Horarios de recepción CxP <i class="fas fa-clock"></i></h5>
        <small class="text-muted">Informativo</small>
      </div>
      <div class="text2">
        <text> Se programa el pago para el día siguiente hábil</text>
      </div>
      <div class="text">
        <small>Lunes a Jueves &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp;9:00 a 15:15hrs</small><br />
        <small>Viernes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;9:00 a 13:00hrs</small><br />
        <small>Días de trabajo medio día &nbsp; &nbsp; &nbsp; &nbsp; 9:00 a 12:15hrs</small>
      </div>
      <div class="text2">
        <text>Después de estos horarios el pago será programado </text>
        <text> al tercer día hábil a partir de la recepción del mismo.</text>
      </div>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2">
        <h5 class="mb-1">Requisiciones de pago <i class="fas fa-file-invoice"></i></h5>
        <small class="text-muted">Informativo</small>
      </div>
      <p class="mb-1">Sección dónde encontrarás las requisiciones activas que requieren atención inmediata</p>
      <small class="text-muted">Dependiendo tu rol son las requisiciones que necesitas ver para trabajar en tu día a
        día.</small>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between my-2">
        <h5 class="mb-1">Sección de histórico <i class="fas fa-history"></i></h5>
        <small class="text-muted">Informativo</small>
      </div>
      <p class="mb-1">
        En esta sección podrás visualizar las requisiciones de pago que ya hayas generado y no requieran atención
        inmediata solo será consulta.
      </p>
      <small class="text-muted">Si tienes alguna duda, ¡Contáctanos!</small>
    </a>
  </div>
</template>
<script>
export default {
  setup() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>