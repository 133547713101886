import { createApp } from "vue";
import router from "@/router/index";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/sass/bootstrap-variables.scss";
import "@fortawesome/fontawesome-free";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap";
import Select2 from "@/components/Select2.vue";
import Autonumeric from "@/components/Autonumeric.vue";
import Toast from "@/components/Toast.vue";
import store from "@/store";
import { Tooltip } from "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueTour from 'v3-tour'

require('@/sass/vue-tour.css')
const options = {
  confirmButtonColor: "#ffa000"
};

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueTour)
app.use(VueSweetalert2, options);
app.component("select2", Select2);
app.component("autonumeric", Autonumeric);
app.component("toast", Toast);
app.component("tootlip", Tooltip);

//eslint-disable-next-line
app.config.errorHandler = function (err, vm, info) {
  console.log(err);
};
app.mount("#app");
