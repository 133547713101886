
import { defineComponent } from "vue";
import AutoNumeric from "autonumeric";

export default defineComponent({
  methods: {
    actualizarValor() {
      this.$emit("update:modelValue", AutoNumeric.getAutoNumericElement(this.$el).getNumber());
    }
  },
  watch: {
    modelValue(value) {
      AutoNumeric.getAutoNumericElement(this.$el).set(value);
    },
    configuracion(valor) {
      AutoNumeric.getAutoNumericElement(this.$el).set(
        AutoNumeric.getAutoNumericElement(this.$el).getNumericString(),
        valor
      );
    }
  },
  props: {
    configuracion: {
      type: Object as any,
      default: null
    },
    value: {
      type: Number
    }
  },
  mounted() {
    if (this.configuracion) new AutoNumeric(this.$el, this.configuracion);
    else new AutoNumeric(this.$el, null);
  }
});
