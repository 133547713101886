
import { defineComponent } from "vue";
import api from "@/servicios/apiSRP";

export default defineComponent({
  name: "ModelComentarios",
  props: {
    rolUsuario: String,
    rolId: String,
    requisicion_id: String,
    comentarioAle: String,
    estatusRequisicionId: String
  },
  data() {
    return {
      requisicionPagoId: window.sessionStorage.getItem("requisicionPagoId") as string,
      estatusRequisicion: "" as any,
      observaciones: "",
      usuarioId: 0,
      bitacora: {},
      requisicion: {},
      comentario: "",
      archivosRequisicion: [] as any,
      documentosExistentes: [] as any,
      requisicionIdExistente: null as any,
      rutaDocumentoExistente: "" as any,
      documentoPdfId: "" as any,
      documentoId: 0 as any
    };
  },
  watch: {
    requisicion_id() {
      this.traerComentarios();
      this.traerRequisicion();
      let x = window.sessionStorage.getItem("usuarioId") as string;
      this.usuarioId = +x;
    }
  },
  methods: {
    async traerComentarios() {
      if (typeof this.requisicion_id === "undefined") {
        console.log("traerComentarios:---undefined---");
      } else {
        this.requisicionPagoId = this.requisicion_id;
      }
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerBitacoraEstatusRequisicionPago(
        parseInt(this.requisicionPagoId)
      );
      this.bitacora = resp.data;
    },
    async traerRequisicion() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerRequisicionPago(parseInt(this.requisicionPagoId));
      this.requisicion = resp.data;
    },
    formatearImporte(value: number) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async EnviarComentario() {
      if (this.comentario != "") {
        await api.SRP.RequisicionPago.RequisicionPago.GuardarComentario(
          parseInt(this.requisicionPagoId),
          this.usuarioId,
          this.comentario
        );
        this.$emit("toParent", this.requisicionPagoId.toString());

        this.traerComentarios();
        this.comentario = "";
      } else {
        alert("El comentario es Requerido");
      }
    }
  }
});
