
import { defineComponent } from "vue";
import Header from "../Componentes/Header.vue";
import Footer from "../Componentes/Footer.vue";

export default defineComponent({
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      rolUsuario: "Solicitador",
      sesionIniciada: true
    };
  },
  mounted() {
    if (window.sessionStorage.length > 0) {
      this.sesionIniciada = true;
    } else {
      this.sesionIniciada = false;
    }
    this.$router.push("/dashboard/");
  },
  methods: {
    regresarLogin() {
      this.$router.push("/");
    }
  }
});
