
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalDocumentos",
  props: {
    rutaDocumento: String,
    documentoId: Number
  },
  methods: {
    DescargaPDF() {
      window.open(
        "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivoGeneral?descargaPdf=true&documentoId=" +
          this.documentoId +
          ""
      );
    }
  }
});
