<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header bg-white">
        <div class="header">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <div class="row">
                  <div class="col-1">
                    <div class="">
                      <a class="btn dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fas fa-filter"></i>
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <label class="list-group-item border-0"><strong> Filtro por: Estado </strong></label>
                        <li class="list-group-item border-0" v-for="estadoId in estadosIds" :key="estadoId">
                          <input class="form-check-input me-1" type="checkbox" v-model="checarEstadosIds"
                            v-bind:value="estadoId" />
                          <label v-if="estadoId == 1">En autorización</label>
                          <label v-else-if="estadoId == 2">En verificación</label>
                          <label v-else-if="estadoId == 3">En cuentas por pagar</label>
                          <label v-else-if="estadoId == 4">En proceso de pago</label>
                          <label v-else-if="estadoId == 5">En aclaración</label>
                          <label v-else-if="estadoId == 6">Rechazada</label>
                          <label v-else-if="estadoId == 7">Pago aplicado</label>
                          <label v-else-if="estadoId == 8">Pago rechazado</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-10">
                    <span class="fas fa-search form-control-icon"></span>
                    <input type="text" class="form-control" placeholder="Escribe aquí para buscar una requisición"
                      v-model="buscador" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7 d-flex justify-content-end" v-if="mostrarBotonNueva == 2" style="display: none">
              <!-- <button @click="NuevaSolicitud()" type="button" class="btn bg-primary text-white"> -->
              <button @click="abrirModal()" type="button" class="btn bg-primary text-white" id="v-step-0">
                <span class="fas fa-plus text-white"></span> Crear
              </button>
            </div>
            <div class="col-7 d-flex justify-content-end"
              v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Verificador CXP'">
              <BotonPrimario @click="ActualizarRequisicionCuentaCargo()" icono="fas fa-file-download"
                color="btn-primary text-white" texto="Descargar excel" mensaje="Descargando"
                :cargando="estaDescargandoExcel" :disabled="deshabilitarDescarga"></BotonPrimario>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover" v-if="rolUsuario != 'Auditoria'">
            <thead>
              <tr class="text-center">
                <th v-if="rolUsuario == 'Autorizador área'">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="esTodoSeleccionado"
                      @change="SeleccionarTodas()" />
                  </div>
                </th>
                <th style="width: 2%" scope="col">
                  <span @click="OrdenarPor('requisicionPagoId')" :class="OrdenarClass('requisicionPagoId')">ID</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  <span @click="OrdenarPor('usuarioSolicitante')" :class="OrdenarClass('usuarioSolicitante')">
                    Solicitante</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  <span @click="OrdenarPor('fechaSolicitud')" :class="OrdenarClass('fechaSolicitud')">
                    Fecha solicitud
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  <span @click="OrdenarPor('usuarioAutorizador')" :class="OrdenarClass('usuarioAutorizador')">
                    Autorizador</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Solicitador'">
                  <span @click="OrdenarPor('fechaSolicitud')" :class="OrdenarClass('fechaSolicitud')">
                    Fecha creación
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('fechaAutorizacionCxP')" :class="OrdenarClass('fechaAutorizacionCxP')">
                    Fecha de recepción
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  <span @click="OrdenarPor('fechaAutorizacion')" :class="OrdenarClass('fechaAutorizacion')">
                    F. autorización
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  <span @click="OrdenarPor('unidadNegocio')" :class="OrdenarClass('unidadNegocio')">
                    Ud. de Negocio
                  </span>
                </th>
                <th v-if="rolUsuario != 'Verificador CXP' && rolUsuario != 'Autorizador CXP'" scope="col">
                  <span @click="OrdenarPor('fechaProgramadaPago')" :class="OrdenarClass('fechaProgramadaPago')">
                    Fecha propuesta
                  </span>
                </th>
                <th scope="col">
                  <span @click="OrdenarPor('razonSocial')" :class="OrdenarClass('razonSocial')"> Razón social </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('nombreBeneficiario')"
                    :class="OrdenarClass('nombreBeneficiario')">Beneficiario</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('divisa')" :class="OrdenarClass('divisa')">Divisa</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('formaPago')" :class="OrdenarClass('formaPago')">Forma de Pago</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('importe')" :class="OrdenarClass('importe')"> Importe </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('unidadNegocio')" :class="OrdenarClass('unidadNegocio')">
                    Ud. de Negocio
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span class="no-pointer">Cuenta Cargo </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('bancoBeneficiario')" :class="OrdenarClass('bancoBeneficiario')">Banco Abono
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('cuentaBeneficiario')" :class="OrdenarClass('cuentaBeneficiario')">
                    Cta Bancaria</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('cuentaCLABE')" :class="OrdenarClass('cuentaCLABE')">CLABE</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('convenioServicioPago')"
                    :class="OrdenarClass('convenioServicioPago')">Convenio</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria'">
                  <span @click="OrdenarPor('referencia')" :class="OrdenarClass('referencia')">Referencia </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Autorizador área'">
                  <span @click="OrdenarPor('conceptoPagos')" :class="OrdenarClass('conceptoPagos')">
                    Concepto de Pago
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario != 'Tesoreria'">
                  <span @click="OrdenarPor('nombreBeneficiario')" :class="OrdenarClass('nombreBeneficiario')">
                    Beneficiario
                  </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  <span @click="OrdenarPor('conceptoPagos')" :class="OrdenarClass('conceptoPagos')">Concepto de
                    pago</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  <span @click="OrdenarPor('numeroFactura')" :class="OrdenarClass('numeroFactura')"> Factura </span>
                </th>
                <th v-if="rolUsuario != 'Tesoreria'" scope="col">
                  <span @click="OrdenarPor('importe')" :class="OrdenarClass('importe')">Importe</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  <span @click="OrdenarPor('fechaProgramadaPago')"
                    :class="OrdenarClass('fechaProgramadaPago')">Propuesta pago</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">
                  <span @click="OrdenarPor('usuarioSolicitante')"
                    :class="OrdenarClass('usuarioSolicitante')">Solicitante</span>
                </th>
                <th scope="col" v-if="
                  rolUsuario != 'Tesoreria' && rolUsuario != 'Verificador CXP' && rolUsuario != 'Autorizador área'
                ">
                  <span @click="OrdenarPor('tipoGastoId')" :class="OrdenarClass('tipoGastoId')"> Tipo de gasto </span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Autorizador área'"><span class="no-pointer">Soporte</span></th>
                <th scope="col" v-if="rolUsuario == 'Autorizador área'"><span class="no-pointer">Comentarios</span></th>
                <th style="width: 1%" scope="col" v-if="rolUsuario != 'Autorizador área'">
                  <span class="no-pointer">Estado</span>
                </th>
                <th style="width: 8%" scope="col" v-if="rolUsuario != 'Autorizador área'">
                  <span class="no-pointer">Acciones</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">
                  <span class="no-pointer">M.Pago</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">
                  <span class="no-pointer">F.Pago</span>
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">
                  <span @click="OrdenarPor('uuid')" :class="OrdenarClass('uuid')">UUID</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="solicitud in SolicitudesFiltradas" :key="solicitud.requisicionPagoId" class="text-center">
                <td v-if="rolUsuario == 'Autorizador área'">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="solicitud.requisicionPagoId"
                      v-model="requisicionesSeleccionadas" @change="ActualizarSeleccion($event)" />
                  </div>
                </td>
                <td>{{ solicitud.requisicionPagoId }}</td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.usuarioAutorizador }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP'">{{ FormatoFechaHora(solicitud.fechaAutorizacion) }}</td>
                <td v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  {{ solicitud.usuarioSolicitante }}
                </td>
                <td v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  {{ DarFormatoFecha(solicitud.fechaSolicitud) }}
                </td>
                <td v-if="rolUsuario == 'Tesoreria'">
                  {{ FormatoFechaHora(solicitud.fechaAutorizacionCxP) }}
                </td>
                <td v-if="rolUsuario == 'Solicitador'">{{ DarFormatoFecha(solicitud.fechaSolicitud) }}</td>
                <td v-if="rolUsuario != 'Verificador CXP' && rolUsuario != 'Autorizador CXP'">
                  {{ DarFormatoFecha(solicitud.fechaProgramadaPago) }}
                </td>
                <td v-if="rolUsuario == 'Autorizador CXP'">{{ FormatoFechaHora(solicitud.fechaAutorizacion) }}</td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.unidadNegocio }}
                </td>
                <td>{{ solicitud.razonSocial }}</td>
                <td v-if="rolUsuario == 'Autorizador área'">
                  {{ solicitud.conceptoPagos }}
                </td>
                <td>{{ solicitud.nombreBeneficiario }}</td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.divisaId == 1">MXN</td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.divisaId == 2">USD</td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.formaPago == '1'">Transferencia</td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.formaPago == '2'">Cheque simple</td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.formaPago == '3'">Cheque certificado</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ "$" + FormatearImporte(solicitud.importe) }}</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ solicitud.unidadNegocio }}</td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.cuentaCargo == null">
                  <select style="width: 160px" @change="GuardarArregloCuentas($event, solicitud.requisicionPagoId)"
                    class="form-select" aria-label="Elige una opción">
                    <option value="">Ninguno</option>
                    <option value="1001">BBVA 6741</option>
                    <option value="1002">BBVA 2953</option>
                    <option value="1003">BBVA 8865</option>
                    <option value="1004">BBVA 2694</option>
                    <option value="1005">BBVA 2671</option>
                    <option value="1006">BBVA 7399</option>
                    <option value="1007">BBVA 8593</option>
                    <option value="1008">BBVA 7054</option>
                    <option value="1009">BBVA 4289</option>
                    <option value="1010">Banamex 1827</option>
                    <option value="1011">Banamex 1703</option>
                    <option value="1012">Banamex 6327</option>
                    <option value="1013">Banamex 4439</option>
                    <option value="1014">Banamex 8965</option>
                    <option value="1015">Scotiabank 0846</option>
                    <option value="1016">Scotiabank 0803</option>
                    <option value="1017">Scotiabank 7617</option>
                    <option value="1018">Scotiabank 7951</option>
                    <option value="1019">Scotiabank 5403</option>
                    <option value="1020">Santander 0643</option>
                    <option value="1021">Santander 0751</option>
                    <option value="1022">Santander 0691</option>
                    <option value="1023">Santander 7827</option>
                    <option value="1024">Santander 5916</option>
                    <option value="1025">Santander 7282</option>
                    <option value="1026">Santander 6969</option>
                    <option value="1027">BAJIO 0201</option>
                  </select>
                </td>
                <td v-if="rolUsuario == 'Tesoreria' && solicitud.cuentaCargo != null">{{ solicitud.cuentaCargo }}</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ solicitud.bancoBeneficiario }}</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ solicitud.cuentaBeneficiario }}</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ solicitud.cuentaCLABE }}</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ solicitud.convenioServicioPago }}</td>
                <td v-if="rolUsuario == 'Tesoreria'">{{ solicitud.referencia }}</td>
                <td
                  v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.conceptoPagos }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.numeroFactura }}
                </td>
                <td v-if="rolUsuario != 'Tesoreria'">{{ "$" + FormatearImporte(solicitud.importe) }}</td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ DarFormatoFecha(solicitud.fechaProgramadaPago) }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP'">{{ solicitud.usuarioSolicitante }}</td>
                <td v-if="
                  rolUsuario != 'Tesoreria' && rolUsuario != 'Verificador CXP' && rolUsuario != 'Autorizador área'
                ">
                  {{ solicitud.tipoGasto.nombreGasto }}
                </td>
                <td v-if="rolUsuario == 'Autorizador área'">
                  <u data-bs-toggle="modal" data-bs-target="#modalSoporte" v-on:click="MostrarComentarios(solicitud)"><i
                      class="fas fa-file-alt"></i></u>
                </td>
                <td v-if="rolUsuario == 'Autorizador área'">
                  <u data-bs-toggle="modal" data-bs-target="#modalComentarios" ref="ligaModal"
                    v-on:click="MostrarComentarios(solicitud)"><i class="fas fa-comment"></i></u>
                  <div class="comentarios-necesario" v-if="necesita_comentario"></div>
                </td>
                <td v-if="rolUsuario != 'Autorizador área'">
                  <span v-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_CREACION"
                    class="fas fa-user-edit fa-lg" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    title="En creación" style="color: #8ba7b7"></span>
                  <span v-else-if="
                    solicitud.estatusRequisicionId == estatusRequisicion.EN_AUTORIZACION ||
                    solicitud.estatusRequisicionId == estatusRequisicion.EN_VERIFICACION_AREA
                  " style="color: #0f057e" class="fas fa-user-shield fa-lg" data-bs-toggle="tooltip"
                    data-bs-placement="bottom" title="En autorización"></span>
                  <span v-else-if="
                    solicitud.estatusRequisicionId == estatusRequisicion.EN_AUTORIZACION_CXP ||
                    solicitud.estatusRequisicionId == estatusRequisicion.EN_VALIDACION_CXP
                  " style="color: #0098f3" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    title="En cuentas por pagar" class="fas fa-calculator fa-lg"></span>
                  <span v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_TESORERIA"
                    style="color: #36d3b7" data-bs-toggle="tooltip" data-bs-placement="bottom" title="En tesorería"
                    class="fas fa-comments-dollar fa-lg"></span>
                  <span v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_ACLARACION"
                    class="fas fa-exclamation-triangle fa-lg" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    title="En aclaración" style="color: #febb0e"></span>
                  <span v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.RECHAZADA"
                    class="fas fa-times-circle fa-lg" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    title="Rechazada" style="color: #fe541f"></span>
                  <span v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.PAGO_REALIZADO"
                    class="fas fa-check-circle fa-lg" data-bs-toggle="tooltip" data-bs-placement="bottom"
                    title="Pago aplicado" style="color: #26bb10"></span>
                  <span v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.PAGO_RECHAZADO"
                    style="color: #fb1010" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Pago rechazado"
                    class="fab fa-creative-commons-nc fa-lg"></span>
                </td>
                <td v-if="rolUsuario != 'Autorizador área'">
                  <span class="far fa-eye btn" @click="VerSolicitud(solicitud.requisicionPagoId)"></span><span v-if="
                    rolUsuario == 'Solicitador' &&
                    (solicitud.estatusRequisicionId == estatusRequisicion.EN_CREACION ||
                      solicitud.estatusRequisicionId == estatusRequisicion.EN_ACLARACION)
                  " class="fas fa-edit btn" style="font-size: 14px"
                    @click="EditarSolicitud(solicitud.requisicionPagoId)"></span>
                  <span v-else-if="rolUsuario == 'Solicitador'"
                    style="opacity: 0.3; cursor: not-allowed; font-size: 14px" class="fas fa-edit btn"></span>

                  <span v-if="
                    rolUsuario == 'Solicitador' && solicitud.estatusRequisicionId == estatusRequisicion.EN_CREACION
                  " @click="EliminarSolicitud(solicitud.requisicionPagoId)" class="fas fa-trash-alt btn"
                    style="font-size: 14px"></span>
                  <span v-else-if="rolUsuario == 'Solicitador'"
                    style="opacity: 0.3; cursor: not-allowed; font-size: 14px" class="fas fa-trash-alt btn"></span>
                </td>
                <td v-if="rolUsuario == 'Verificador CXP'">-</td>
                <td v-if="rolUsuario == 'Verificador CXP'">-</td>
                <td v-if="rolUsuario == 'Verificador CXP'">{{ solicitud.uuid }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end"></div>
        </div>
      </div>
      <!-- Modal Soporte-->
      <div class="modal fade" id="modalSoporte" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Soportes de requisición</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <ModalSoporte :requisicion_id="(solicitudActual || {}).requisicionPagoId || 0"></ModalSoporte>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Comentarios-->
      <div class="modal fade" id="modalComentarios" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Comentarios</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <ModalComentarios :requisicion_id="(solicitudActual || {}).requisicionPagoId || 0"
                @toParent="ObtenerSolicitudIdAlEnviarComentario"></ModalComentarios>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Autorizar requisiciones -->
      <div class="modal" id="modalAutorizar" tabindex="-1" aria-labelledby="modalAutorizar" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center">Confirmación</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <label> ¿Estás seguro de autorizar? </label>
              <div class="seccion-autorizar">
                <div class="circulo bg-success">
                  <h6>{{ requisicionesSeleccionadas.length }}</h6>
                  <i class="fas fa-check"></i>
                </div>
              </div>
              <label>{{ requisicionesSeleccionadas.length > 1 ? "Requisiciones" : "Requisición" }}</label>
            </div>
            <div class="modal-footer text-center">
              <a href="#" class="btn link-dark" data-bs-dismiss="modal">No, Regresar</a>
              <button type="button" class="btn btn-success" @click="AutorizarSolicitudesMasivas()">
                Sí, Autorizar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal validar requsiciones a rechazar  -->
      <div class="modal" id="modalValidarRechazar" tabindex="-1" aria-labelledby="modalValidarRechazar"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Recuerda</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="cerrarmodalrechazadas"></button>
            </div>
            <div class="modal-body text-center">
              <label> Debes colocar un comentario en cada requisición a rechazar </label>
              <div class="seccion-autorizar">
                <div>
                  <label>Seleccionaste</label>
                  <div class="circulo bg-danger">
                    <h6>{{ requisicionesSeleccionadas.length }}</h6>
                    <i class="fas fa-times"></i>
                  </div>
                  <label>{{ requisicionesSeleccionadas.length > 1 ? "Requisiciones" : "Requisición" }}</label>
                </div>
                <div>
                  <label>Te faltan comentar</label>
                  <div class="circulo bg-info">
                    <h6>{{ RequsicionesFaltantesComentarios.length }}</h6>
                    <i class="fas fa-times"></i>
                  </div>
                  <label>{{
                    requisicionesSeleccionadas.length - requisiciones_a_rechazar_comentadas.length > 1
                      ? "Requisiciones"
                      : "Requisición"
                  }}</label>
                </div>
              </div>
              <div v-if="RequsicionesFaltantesComentarios.length != 0">
                <label>Coloca un comentario en las siguientes requisiciones</label>
                <ul>
                  <li v-for="r in RequsicionesFaltantesComentarios" :key="r">
                    ID : <strong>{{ r }}</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer text-center">
              <a href="#" class="btn link-dark" data-bs-dismiss="modal">Regresar</a>
              <button type="button" class="btn btn-danger" @click="RechazarSolicitudesMasivas()"
                :disabled="requisicionesSeleccionadas.length != requisiciones_a_rechazar_comentadas.length">
                Sí, Rechazar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bottom p-4 seccion-flotante" v-show="VerificarRequiSeleccionadas > 0">
      <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modalValidarRechazar">
        <i class="fas fa-times"></i>
        <h6>Rechazar</h6>
      </button>
      <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalAutorizar">
        <i class="fas fa-check"></i>
        <h6>Autorizar</h6>
      </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" :id="'modalCrearRequisicion-' + modalId" data-bs-backdrop="static" data-bs-keyboard="false"
      tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content p-4" style="border: none !important">
          <div class="modal-header d-block">
            <div class="d-flex">
              <h1 class="modal-title text-secondary" id="staticBackdropLabel">Crear requisición</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                style="color: #ced1dd !important"></button>
            </div>
            <p class="modal-title text-secondary py-2" id="staticBackdropLabel">
              Crea o selecciona un tipo de plantilla prellenada con datos para agilizar el llenado de la requisición.
            </p>
          </div>
          <div class="modal-body">
            <div class="options-container d-flex justify-content-around">
              <div class="opcion-crear-nueva">
                <div class="card text-center shadow p-3 mb-5 bg-body rounded" style="width: 18rem">
                  <div class="card-body">
                    <h5 class="card-title text-secondary">Crear nueva</h5>
                    <p class="card-text text-secondary">Requisición en blanco</p>
                    <img src="@/assets/crear_requ.png" class="card-img-top" alt="" />
                    <a href="#" class="btn btn-primary text-white mt-4" @click="NuevaSolicitud()"
                      id="v-step-1">Crear</a>
                  </div>
                </div>
              </div>
              <div class="opcion-usar-plantilla">
                <div class="card text-center shadow p-3 mb-5 bg-body rounded" style="width: 18rem">
                  <div class="card-body">
                    <h5 class="card-title text-secondary">Usar plantilla</h5>
                    <p class="card-text text-secondary">Busca y selecciona una plantilla</p>
                    <img src="@/assets/usar_plant.png" class="card-img-top" alt="" />
                    <a href="#" class="btn btn-primary text-white mt-4" @click="buscarPlantilla()"
                      id="v-step-2">Buscar</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-tour name="myTour" :steps="pasosRecorrido" :options="misOpcionesRecorrido"
      :callbacks="eventosRecorrido"></v-tour>
  </div>
</template>

<script>
import { SRP } from "@/servicios/api"; // eslint-disable-line
import api from "@/servicios/apiSRP";
import { defineComponent } from "vue";
//Importar mixins
import mixinRequisicionPago from "../mixins/mixinRequisicionPago";
import ModalComentarios from "../components/ModalComentarios.vue";
import ModalSoporte from "../components/ModalSoporte.vue";
import BotonPrimario from "../../Requisicion Pago/components/BotonPrimario.vue";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import * as Bootstrap from "bootstrap";

export default defineComponent({
  name: "Historico",
  components: {
    BotonPrimario,
    ModalComentarios,
    ModalSoporte
  },
  mixins: [mixinRequisicionPago],
  data() {
    return {
      usuarioId: 0,
      rolUsuario: "",
      buscador: "",
      modalActual: "",
      estatusRequisicion: SRP.Models.EstatusRequisicion,
      paginaActual: 1,
      numeroSolicitudesMostradas: 1500,
      numeroPaginasActuales: 0,
      solicitudActual: null,
      mostrarBotonNueva: 0,
      ordenar: {
        nombreColumna: "",
        esAscendente: false
      },
      misSolicitudesFiltradas: [],
      estadosIds: [1, 2, 3, 4, 5, 6, 7, 9],
      checarEstadosIds: [],
      estadosFormateados: [],
      tipoGasto: [],
      roles: [],
      arregloCuentaCargo: [],
      arregloCuentaCargoAsigancion: {
        idCuentaCargo: "",
        idRequisicion: ""
      },
      historialSolicitudes: [],
      bitacora: [
        {
          bitacoraEstatusId: 0,
          requisicionPagoId: 0,
          fechaRegistro: "",
          estatusRequisicionId: 1,
          observaciones: ""
        }
      ],
      requisicionesSeleccionadas: [],
      requisiciones_a_rechazar_comentadas: [],
      deshabilitarDescarga: true,
      estaDescargandoExcel: false,
      esTodoSeleccionado: false,
      existenRequisciones: false,
      necesita_comentario: false,
      modalId: null,
      misOpcionesRecorrido: {
        useKeyboardNavigation: true,
        highlight: false,
        labels: {
          buttonSkip: 'Quitar',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar'
        },
        startTimeout: 500

      },
      eventosRecorrido: {
        onSkip: this.SaltaRecorrido,
        onFinish: this.SaltaRecorrido
      },
      pasosRecorrido: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood
          header: {
            title: 'Bienvenido al nuevo modo de crear requisiciones',
          },
          content: `Clic en <strong>crear</strong> para generar una nueva requisición o plantilla.`,
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#v-step-1',
          header: {
            title: '¿Deseas crear una requisición o plantilla?',
          },
          content: 'Aquí podrás generar tu requisición como antes o crear una plantilla para tu área.',
          params: {
            enableScrolling: false
          },
          before: type => new Promise((resolve) => {
            console.log(type)
            this.abrirModal()
            setTimeout(() => {
              resolve()
            }, 400);
          }),
        },
        {
          target: '#v-step-2',
          header: {
            title: '¿Necesitas una plantilla?',
          },
          content: 'Clic en <b>buscar</b>, selecciona y utilizala, recuerda que solo se mostrarán las plantillas de tu área.',
          params: {
            enableScrolling: false
          }
        }
      ]
    };
  },
  async created() {
    this.modalId = await this.generarID();
  },
  async mounted() {
    this.rolUsuario = this.$route.params["rolUsuario"];
    this.usuarioId = window.sessionStorage.getItem("usuarioId");
    await this.ValidarRutaRol();
    await this.CargarTipoGasto();

    //Modal arrastrable de soporte
    dragElement(document.getElementById("modalSoporte"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    /** validar recorrido inicial de plantillas */
    const mostrarRecorrido = window.localStorage.getItem('muestraRecorrido');
    if (mostrarRecorrido != "false") this.$tours['myTour'].start()
  },
  methods: {
    SaltaRecorrido() {
      window.localStorage.setItem('muestraRecorrido', 'false');
    },
    abrirModal() {
      const miModal = document.getElementById(`modalCrearRequisicion-${this.modalId}`);
      const modal = Bootstrap.Modal.getInstance(miModal);
      if (modal) {
        modal.show();
      } else {
        const nuevoModal = new Bootstrap.Modal(miModal);
        nuevoModal.show();
      }
    },

    cerrarModal() {
      const miModal = document.getElementById(`modalCrearRequisicion-${this.modalId}`);
      const modal = Bootstrap.Modal.getInstance(miModal);
      modal.hide();
    },
    async generarID() {
      return "a" + Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7);
    },
    async AutorizarSolicitudesMasivas() {
      var myModal = document.getElementById("modalAutorizar");
      var modal = Bootstrap.Modal.getInstance(myModal);
      modal.hide();
      //try método para enviar solicitudes
      try {
        this.requisicionesSeleccionadas.forEach((element) => {
          this.SolicitudesFiltradas.forEach((requisicion) => {
            if (requisicion.requisicionPagoId == element) {
              api.SRP.RequisicionPago.RequisicionPago.ActualizarEstatusRequisicionPago(
                element,
                this.usuarioId,
                this.usuarioId,
                2,
                ""
              );
              this.SolicitudesFiltradas.splice(
                this.SolicitudesFiltradas.findIndex((e) => e.requisicionPagoId === element),
                1
              );
            }
          });
        });
        swal({
          icon: "success",
          title: "¡Excelente!",
          text: `${this.requisicionesSeleccionadas.length > 1 ? "Se han" : "Se ha "} actualizado correctamente`,
          timer: 1500
        });
        //mandar correo
        await api.SRP.RequisicionPago.RequisicionPago.NotificarCorreoMasivo(
          this.requisicionesSeleccionadas,
          this.requisicionesSeleccionadas.length,
          this.usuarioId,
          SRP.Models.EstatusRequisicion.EN_AUTORIZACION,
          "",
          {
            headers: { "Content-Type": "application/json" }
          }
        );
        this.esTodoSeleccionado = false;
        this.requisicionesSeleccionadas = [];
        this.requisiciones_a_rechazar_comentadas = [];
      } catch (e) {
        swal({
          icon: "error",
          title: "¡Ups!",
          text: `Algo no anda bien, intenta nuevamente ${e}`,
          timer: 1500
        });
      }
    },
    async RechazarSolicitudesMasivas() {
      var myModal = document.getElementById("modalValidarRechazar");
      var modal = Bootstrap.Modal.getInstance(myModal);
      modal.hide();
      try {
        this.requisicionesSeleccionadas.forEach((element) => {
          this.SolicitudesFiltradas.forEach((requisicion) => {
            if (requisicion.requisicionPagoId == element) {
              api.SRP.RequisicionPago.RequisicionPago.ActualizarEstatusRequisicionPago(
                element,
                this.usuarioId,
                this.usuarioId,
                6,
                ""
              );
              this.SolicitudesFiltradas.splice(
                this.SolicitudesFiltradas.findIndex((e) => e.requisicionPagoId === element),
                1
              );
            }
          });
        });

        swal({
          icon: "success",
          title: "¡Excelente!",
          text: `${this.requisicionesSeleccionadas.length > 1 ? "Se han" : "Se ha "} rechazado`,
          button: false,
          timer: 1500
        });
        await api.SRP.RequisicionPago.RequisicionPago.NotificarCorreoMasivo(
          this.requisicionesSeleccionadas,
          this.requisicionesSeleccionadas.length,
          this.usuarioId,
          SRP.Models.EstatusRequisicion.RECHAZADA,
          "",
          {
            headers: { "Content-Type": "application/json" }
          }
        );
        this.esTodoSeleccionado = false;
        this.requisicionesSeleccionadas = [];
        this.requisiciones_a_rechazar_comentadas = [];
      } catch (e) {
        console.log(e);
      }
    },
    async ActualizarRequisicionCuentaCargo() {
      if (this.arregloCuentaCargo.length > 0) {
        await api.SRP.RequisicionPago.RequisicionPago.ActualizarCuentaCargoEnRequisicion(
          JSON.stringify(this.arregloCuentaCargo)
        );
      }
      this.ExportarExcel();
    },
    async EliminarSolicitud(requisicionPagoId) {
      const borrar = await swal2.fire({
        title: "¿Estás seguro?",
        text: "Al borrar la requisición " + requisicionPagoId + " no la podrás recuperar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true
      });

      if (borrar.isConfirmed) {
        await api.SRP.RequisicionPago.RequisicionPago.EliminarRequisicionPago(requisicionPagoId);
        swal2.fire({
          title: "¡Excelente!",
          text: "La requisición " + requisicionPagoId + " se eliminó con éxito",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        this.ValidarRutaRol();
      }
    },
    async CargarHistorialSolicitador(idUsuario) {
      var resp = {};
      resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoSolicitador(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialVerificadorArea(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoVerificadorArea(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialAutorizador(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoAutorizadorArea(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialVerificador(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoVerificadorCXP(idUsuario);
      this.historialSolicitudes = resp.data;
      this.deshabilitarDescarga = this.historialSolicitudes.length < 1 ? true : false;
    },
    async CargarHistorialAutorizadorCXP(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoAutorizadorCXP(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialTesoreria(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarRequisicionesPagoTesoreria(idUsuario);
      this.historialSolicitudes = resp.data;
      this.historialSolicitudes.length < 1 ? (this.deshabilitarDescarga = true) : (this.deshabilitarDescarga = false);
    },
    async CargarTipoGasto() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoTipoGasto();

      this.tipoGasto = resp.data;
    },
    async ExportarExcel() {
      let r;
      if (this.rolUsuario == "Verificador CXP") {
        r = await api.SRP.RequisicionPago.RequisicionPago.ExportToExcelTesoreria(false);
      } else {
        r = await api.SRP.RequisicionPago.RequisicionPago.ExportToExcelTesoreria(true);
      }
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      var url = window.URL.createObjectURL(r.data);
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    ObtenerSolicitudIdAlEnviarComentario(value) {
      let id = parseInt(value);
      let existe_requsicion = this.requisicionesSeleccionadas.includes(id);
      function AgregaActualizaRequsicionesComentada(arr, id) {
        const index = arr.findIndex((e) => e === id);
        if (index === -1) {
          arr.push(id);
        } else {
          arr[index] = id;
        }
      }
      if (existe_requsicion) AgregaActualizaRequsicionesComentada(this.requisiciones_a_rechazar_comentadas, id);
      else console.log("No insertó");
    },
    SeleccionarTodas() {
      this.requisicionesSeleccionadas = [];
      if (this.esTodoSeleccionado)
        for (let requisicion in this.SolicitudesFiltradas)
          this.requisicionesSeleccionadas.push(this.SolicitudesFiltradas[requisicion].requisicionPagoId);
    },
    ActualizarSeleccion(event) {
      if (this.SolicitudesFiltradas.length == this.requisicionesSeleccionadas.length) this.esTodoSeleccionado = true;
      else this.esTodoSeleccionado = false;
      const checked = event.target.checked;
      let idDeselecionado = parseInt(event.target.value);
      if (!checked) {
        let idParaBorrar = this.requisiciones_a_rechazar_comentadas.indexOf(idDeselecionado);
        if (idParaBorrar > -1) {
          this.requisiciones_a_rechazar_comentadas.splice(idParaBorrar, 1);
        }
      }
    },
    ValidarRutaRol() {
      if (this.rolUsuario.startsWith("Verificador área")) {
        this.CargarHistorialVerificadorArea(this.usuarioId);
      } else {
        switch (this.rolUsuario) {
          case "Solicitador":
            var rolesList = window.sessionStorage.getItem("listaRoles");
            if (rolesList.indexOf("Solicitador") > 0) {
              this.CargarHistorialSolicitador(this.usuarioId);
              this.mostrarBotonNueva = 2;
            }
            break;
          case "Autorizador área":
            this.CargarHistorialAutorizador(this.usuarioId);
            break;
          case "Verificador CXP":
            this.CargarHistorialVerificador(this.usuarioId);

            break;
          case "Autorizador CXP":
            this.CargarHistorialAutorizadorCXP(this.usuarioId);
            break;
          case "Tesoreria":
            this.CargarHistorialTesoreria(this.usuarioId);
            break;
        }
      }
    },
    GuardarArregloCuentas(event, requisicionId) {
      this.arregloCuentaCargoAsigancion.idCuentaCargo = event.target[event.target.selectedIndex].text;
      this.arregloCuentaCargoAsigancion.idRequisicion = requisicionId + "";
      var idCuentaCargo = Object.assign({}, this.arregloCuentaCargoAsigancion);
      if (this.arregloCuentaCargo.length > 0) {
        for (var i = 0; i < this.arregloCuentaCargo.length; i++) {
          if (this.arregloCuentaCargo[i].idRequisicion == this.arregloCuentaCargoAsigancion.idRequisicion) {
            this.arregloCuentaCargo.splice(i, 1);
          }
        }
      }
      this.arregloCuentaCargo.push(idCuentaCargo);
    },
    MostrarComentarios(solicitud) {
      this.solicitudActual = solicitud;
    },
    EditarSolicitud(requisicionPagoId) {
      window.localStorage.removeItem("JsonCC");
      window.sessionStorage.setItem('requisicionPagoId', requisicionPagoId)
      this.$router.push("/editar-requisicion-pago/" + requisicionPagoId);
    },
    NuevaSolicitud() {
      this.cerrarModal();
      window.localStorage.removeItem("JsonCC");
      this.$router.push("/formulario-requisicion-pago");
    },
    buscarPlantilla() {
      this.cerrarModal();
      this.$router.push({ name: 'srp-requisicion-pago-plantillas' });
    },
    CalcularNumeroPaginas(numeroPaginasActuales) {
      numeroPaginasActuales = Math.ceil(this.historialSolicitudes.length / 5);

      return numeroPaginasActuales;
    },
    Prev() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
    },
    Next() {
      let totalPaginas = Math.ceil(this.historialSolicitudes.length / 5);
      if (this.paginaActual < totalPaginas) {
        this.paginaActual++;
      }
    },
    Paginated(historialSolicitudes) {
      return historialSolicitudes.slice(this.IndexStart, this.IndexEnd);
    },
    VerSolicitud(solicitud) {
      this.historialSolicitudes.id = solicitud;
      window.sessionStorage.setItem("requisicionPagoId", solicitud);
      this.$router.push("/vista-detalle-solicitud/" + this.rolUsuario);
    },
    OrdenarClass(nombreColumna) {
      return this.ordenar.nombreColumna === nombreColumna
        ? `sorted active ${this.ordenar.esAscendente ? "asc " : "desc"}`
        : "sorted desc";
    },
    OrdenarPor(nombreColumna) {
      this.ordenar.esAscendente = this.ordenar.nombreColumna === nombreColumna ? !this.ordenar.esAscendente : true;
      this.ordenar.nombreColumna = nombreColumna;
    },
    OrdenarElementos: function (historialSolicitudes) {
      const list = historialSolicitudes.slice();
      if (this.ordenar.nombreColumna) {
        list.sort((a, b) => {
          a = a[this.ordenar.nombreColumna];
          b = b[this.ordenar.nombreColumna];

          return (a === b ? 0 : a > b ? 1 : -1) * (this.ordenar.esAscendente ? 1 : -1);
        });
      }
      return list;
    },
    FormatearImporte(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    BuscarSolicitud: function (historialSolicitudes) {
      return historialSolicitudes.filter((solicitud) => {
        if (solicitud.razonSocial == null) {
          solicitud.razonSocial = "-";
        }
        return (
          (solicitud.requisicionPagoId || "").toString().includes(this.buscador) ||
          (solicitud.razonSocial || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.nombreBeneficiario || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaSolicitud) || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaProgramadaPago) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaAplicacionPago) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaAutorizacion) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaAutorizacionCxP) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (solicitud.importe || "").toString().includes(this.buscador) ||
          (solicitud.tipoGasto.nombreGasto || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.divisa || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.formaPago || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.conceptoPagos || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.unidadNegocio || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.usuarioAutorizador || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.usuarioSolicitante || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.bancoBeneficiario || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.convenioServicioPago || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.cuentaBeneficiario || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.cuentaCLABE || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.referencia || "").toLowerCase().includes(this.buscador.toLowerCase())
        );
      });
    },
    formatearFecha: function (fecha) {
      let fecha_formateada = new Date(fecha);
      let dia = `${fecha_formateada.getDate()}`.padStart(2, "0");
      let mes = `${fecha_formateada.getMonth() + 1}`.padStart(2, "0");
      let anio = fecha_formateada.getFullYear();
      return dia + "/" + mes + "/" + anio;
    },
    FiltrarPorEstados: function (historialSolicitudes) {
      if (!this.checarEstadosIds.length) {
        return this.historialSolicitudes;
      } else {
        if (this.checarEstadosIds.includes(1) && !this.estadosFormateados.includes("EN_AUTORIZACION")) {
          this.estadosFormateados.push("EN_AUTORIZACION");
        }
        if (this.checarEstadosIds.includes(2)) {
          this.estadosFormateados.push("EN_VALIDACION_CXP");
        }
        if (this.checarEstadosIds.includes(3)) {
          this.estadosFormateados.push("EN_AUTORIZACION_CXP");
        }
        if (this.checarEstadosIds.includes(4)) {
          this.estadosFormateados.push("EN_TESORERIA");
        }
        if (this.checarEstadosIds.includes(5)) {
          this.estadosFormateados.push("EN_ACLARACION");
        }
        if (this.checarEstadosIds.includes(6)) {
          this.estadosFormateados.push("RECHAZADA");
        }
        if (this.checarEstadosIds.includes(7)) {
          this.estadosFormateados.push("PAGO_REALIZADO");
        }
        if (this.checarEstadosIds.includes(8)) {
          this.estadosFormateados.push("PAGO_RECHAZADO");
        }

        return historialSolicitudes.filter((historialSolicitudes) =>
          this.estadosFormateados.includes(historialSolicitudes.estatusRequisicionId)
        );
      }
    },
    SolicitudesTotales: function () {
      return this.historialSolicitudes.length;
    }
  },
  computed: {
    RequsicionesFaltantesComentarios: function () {
      const diferenciaDeArreglos = (arr1, arr2) => {
        return arr1.filter((elemento) => arr2.indexOf(elemento) == -1);
      };
      return diferenciaDeArreglos(this.requisicionesSeleccionadas, this.requisiciones_a_rechazar_comentadas);
    },
    VerificarRequiSeleccionadas: function () {
      return this.requisicionesSeleccionadas.length;
    },
    SolicitudesFiltradas: function () {
      return this.Paginated(
        this.OrdenarElementos(this.BuscarSolicitud(this.FiltrarPorEstados(this.historialSolicitudes)))
      );
    },
    IndexStart() {
      return (this.paginaActual - 1) * this.numeroSolicitudesMostradas;
    },
    IndexEnd() {
      return this.IndexStart + this.numeroSolicitudesMostradas;
    },
    Paginas: function () {
      return this.CalcularNumeroPaginas();
    }
  }
});
</script>
<style lang="scss" scoped>
@import "src/sass/bootstrap-variables.scss";

.container-fluid {
  margin-top: 15px;
}

.sorted.asc::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f885";
  margin-left: 3px;
}

.sorted.asc.active,
.sorted.desc.active {
  color: $primary !important;
}

.sorted.asc.active::after,
.sorted.desc.active::after {
  color: $primary;
}

.sorted.desc::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f160";
  margin-left: 3px;
}

th {
  vertical-align: middle;
}

th span.no-pointer {
  cursor: default !important;
}

th span {
  color: $secondary;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.dropdown-toggle {
  &::after {
    display: none !important;
  }
}

.card {
  font-size: 12px;
}

.form-group {
  .form-control {
    padding-left: 2.375rem;
    background-color: #f2f2f2;
    color: #828282;
    border: 0px;
  }

  .form-control-icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}

.seccion-autorizar {
  display: flex;
  justify-content: space-around;
  margin: 10px auto;

  .circulo {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #f2f2f2;
  }

  .autorizar {
    background-color: green;
  }

  .rechazar {
    background-color: red;
  }

  h6 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 1px;
  }

  i {
    font-size: 18px;
  }
}

i {
  cursor: pointer;
  font-size: 18px;
}

.seccion-flotante {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.card-footer {
  padding: 10px 0px !important;
}

.comentarios-necesario {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

div.swal2-actions {
  gap: 10px !important;
}
</style>

