
import { defineComponent } from "vue";
import DatosGenerales from "../views/FormularioDatosGenerales.vue";
import CentroCosto from "../views/FormularioCentroCosto.vue";
import api from "@/servicios/apiSRP";
import swal2 from "sweetalert2";

export default defineComponent({
  name: "FormularioRequisicionPago",
  components: {
    DatosGenerales,
    CentroCosto
  },
  data() {
    return {
      sumaTotalRP: "" as any,
      plantilla: "" as any
    };
  },
  methods: {
    guardar() {
      api.SRP.RequisicionPago.RequisicionPago.GuardarRequisicionPago(
        (this.$refs["centroCosto"] as any).requisicionPago
      );
    }
  },
  async mounted() {
    if (this.$route.query.idPlantilla) {
      const plantillaId = this.$route.query.idPlantilla as any
      const Toast = swal2.mixin({
        toast: true,
        position: 'top',
        width: '500px',
        showConfirmButton: false,
        timer: 2000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', swal2.stopTimer)
          toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
      })
      try {
        const { data, status } = await api.SRP.RequisicionPago.RequisicionPago.ObtenerPlantillaRequisicion(plantillaId);
        if (status == 200) {
          this.plantilla = data;
          Toast.fire({
            icon: 'success',
            title: 'Se cargo la plantilla correctamente.'
          })
        }
        else {
          Toast.fire({
            icon: 'error',
            title: 'Al parecer no existe la plantilla'
          })
        }
      } catch (e) {
        Toast.fire({
          icon: 'error',
          title: 'Ocurrio un problema al cargar la plantilla.'
        })
      }

    }
  }
});
