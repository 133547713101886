
import { defineComponent } from "vue";
import TabsRequisicion from "./TabsRequisicion.vue";
import DetalleDatosGenerales from "./DetalleDatosGenerales.vue";
import DetalleCentroCosto from "../components/DetalleCentroCosto.vue";
import ProgresoSolicitud from "../components/ProgresoSolicitud.vue";
import api from "@/servicios/apiSRP";
import swal from "sweetalert2";

export default defineComponent({
  name: "TarjetaDetalle",
  components: {
    TabsRequisicion,
    DetalleDatosGenerales,
    DetalleCentroCosto,
    ProgresoSolicitud
  },
  methods: {
    editarRequisicion() {
      this.$router.push("/editar-requisicion-pago/" + this.requisicionPagoId);
    },
    async eliminarRequisicion() {
      const borrar = await swal.fire({
        title: "¿Estás seguro?",
        text: "Al borrar la requisición " + this.requisicionPagoId + " no la podrás recuperar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true
      });

      if (borrar.isConfirmed) {
        await api.SRP.RequisicionPago.RequisicionPago.EliminarRequisicionPago(this.requisicionPagoId!);
        swal.fire({
          title: "¡Excelente!",
          text: "La requisición " + this.requisicionPagoId + " se eliminó con éxito",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        this.$router.push("/historico-requisicion-pago/" + this.rolUsuario);
      }
    }
  },
  mounted() {
    switch (this.estatusRequisicionId) {
      case "EN_CREACION":
        this.claseEstatus = "creacion";
        break;
      case "EN_AUTORIZACION":
        this.claseEstatus = "autorizacion";
        break;
      case "EN_AUTORIZACION_CXP":
        this.claseEstatus = "CXP";
        break;
      case "EN_VALIDACION_CXP":
        this.claseEstatus = "CXP";
        break;
      case "EN_TESORERIA":
        this.claseEstatus = "tesoreria";
        break;
      case "EN_ACLARACION":
        this.claseEstatus = "aclaracion";
        break;
      case "RECHAZADA":
        this.claseEstatus = "rechazada";
        break;
      case "PAGO_REALIZADO":
        this.claseEstatus = "pago-realizado";
        break;
      case "PAGO_RECHAZADO":
        this.claseEstatus = "pago-rechazado";
        break;
      case "EN_VERIFICACION_AREA":
        this.claseEstatus = "autorizacion";
        break;
      default:
        break;
    }
  },
  data() {
    return {
      claseEstatus: "",
      componenteId: "a" + Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
    };
  },
  props: {
    requisicionPagoId: Number,
    razonSocial: String,
    tipoPagoId: Number,
    tipoPago: String,
    nombreBeneficiario: String,
    importe: Number,
    medioInstruccion: String,
    numeroFactura: String,
    uuid: String,
    conceptoPagoId: Number,
    nombreTipoGasto: String,
    cuentaBeneficiario: String,
    bancoBeneficiario: String,
    clabe: String,
    referencia: String,
    divisa: Number,
    rfcBeneficiario: String,
    convenioServicioPago: String,
    fechaSolicitud: String,
    fechaPropuesta: String,
    nombreRubro: String,
    observacionInstruccion: String,
    usuarioAutorizadorId: Number,
    usuarioSolicitadorId: Number,
    usuarioAutorizador: String,
    usuarioSolicitante: String,
    estatusRequisicionId: String,
    rolUsuario: String,
    correoSolicitante: String,
    correoAutorizador: String,
    documentos: Array,
    centroCostos: String,
    conceptoPagos: String,
    unidadNegocio: String,
    usuarioVerificadorAreaId: Number,
    usuarioVerificadorArea: String,
    tieneVerificador: Boolean
  }
});
