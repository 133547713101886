
import { defineComponent } from "vue";
import api from "@/servicios/apiSRP";
import TarjetaDetalle from "../components/TarjetaDetalle.vue";
import SeccionComentarios from "../components/SeccionComentarios.vue";

export default defineComponent({
  name: "VistaDetalle",
  components: {
    TarjetaDetalle,
    SeccionComentarios
  },
  data() {
    return {
      requisicionPagoId: window.sessionStorage.getItem("requisicionPagoId") as string,
      DatosRequisicionDetalle: null as any,
      mostrarAutorizador: "SI",
      rolUsuario: "" as string,
      tieneVerificador: false
    };
  },
  mounted() {
    this.CargarDatosRequisicion(this.requisicionPagoId);
    this.rolUsuario = this.$route.params["rolUsuario"] as string;
  },
  methods: {
    async CargarDatosRequisicion(requisicionPagoId: string) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerRequisicionPago(parseInt(requisicionPagoId));
      this.DatosRequisicionDetalle = resp.data;
      this.tieneVerificador = this.DatosRequisicionDetalle.correoVerificadorArea.length > 0 ? true : false;
    },
    async EnviarRespuesta() {
      alert("ENVIA RESPUESTA EL AUTORIZADOR");
    }
  }
});
