
import { defineComponent } from "vue";
import BotonPrimario from "../Areas/Requisicion Pago/components/BotonPrimario.vue";
import api from "@/servicios/apiSRP";
import swal from "sweetalert";

export default defineComponent({
  name: "Header",
  components: {
    BotonPrimario
  },
  data() {
    return {
      rolElegido: "" as string,
      rol: true,
      opcion: "",
      classRol: "",
      classGroup: "list-group-item",
      nombreLogin: window.sessionStorage.getItem("usuarioNombre") as string,
      roles: [] as string[],
      estaActualizandoNav: false
    };
  },
  props: {
    nombreUsuario: String
  },
  mounted() {
    const rolesSinFormato: string[] = JSON.parse(window.sessionStorage.getItem("listaRoles") as string).flat();
    const rolesIrrepetibles = new Set<string>(rolesSinFormato);
    this.roles = Array.from(rolesIrrepetibles);
    this.$router.push("/dashboard/");
  },
  watch: {
    opcion: { handler: "ListenerRol", immediate: true }
  },
  methods: {
    async ActualizarNav() {
      this.estaActualizandoNav = true;

      try {
        //Cambiar metodo de consulta por el indicado
        let resp = await api.SRP.RequisicionPago.ActualizarCatalogo.ActualizarProveedorNAV();
        if (resp.data.estado == "OK") {
          swal("¡Excelente!", `${resp.data.mensaje}`, "success");
        } else {
          swal("¡Ups!", `${resp.data.mensaje}`, "error");
        }
      } catch (error) {
        swal("¡Ups!", `${error}`, "error");
      }
      setTimeout(() => {
        this.estaActualizandoNav = false;
      }, 1500);
    },
    async EditarSolicitud(rolUsuario: string) {
      this.rolElegido = rolUsuario;
      this.rol = false;
      this.VerRequisiciones();
    },
    ListenerRol() {
      switch (this.opcion) {
        case "Solicitador":
          this.classRol = "solicitador";
          break;
        case "Autorizador área":
          this.classRol = "area";
          break;
        case "Verificador CXP":
          this.classRol = "verifica";
          break;
        case "Autorizador CXP":
          this.classRol = "cxp";
          break;
        case "Tesoreria":
          this.classRol = "tesoreria";
          break;
        default:
          break;
      }
    },
    CerrarSesion() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/");
    },
    VerRequisiciones() {
      this.$router.push("/historico-requisicion-pago/" + this.rolElegido);
    }
  }
});
