import vuerouter from "vue-router";
import Home from "./Vistas/Home.vue";
import Login from "./Vistas/Login.vue";
import rutasRequisicionPago from "./Areas/Requisicion Pago/router";

const rutas: vuerouter.RouteRecordRaw[] = [
  { component: Login, path: "/", name: "srp-login" },
  { component: Home, path: "/home", children: rutasRequisicionPago }
];
export default rutas;
