<script setup>
import { computed, onMounted, ref } from 'vue'// eslint-disable-line
import { SRP } from "@/servicios/api"// eslint-disable-line
import api from "@/servicios/apiSRP" // eslint-disable-line
import router from '@/router';
/** Variables  */
const plantillas = ref([])
const buscador = ref("")
/** Métodos  */
async function CargarHistorialSolicitador() {
    const { data } = await api.SRP.RequisicionPago.RequisicionPago.ObtenerPlantillasRequisicion(window.sessionStorage.getItem("usuarioId"))
    plantillas.value = data;
}
const plantillasLista = () => plantillas.value.filter((plantilla) => (plantilla.nombreBeneficiario == null ? '' : plantilla.nombreBeneficiario.toString().toLowerCase() || "").includes(buscador.value.toLowerCase())) // eslint-disable-line
function seleccionarPlantilla(plantilla) { // eslint-disable-line
    router.push({ name: 'crear-requisicion-plantilla', query: { idPlantilla: plantilla } })
}
/** Ciclos de vida  */
onMounted(async () => {
    await CargarHistorialSolicitador()
})
</script>
<template>
    <main class="container py-3">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h2 class="text-secondary">Selecciona una plantilla</h2>
            <div class="contendor-buscador" style="display: flex; align-items: center;">
                <span class="fas fa-search form-control-icon"></span>
                <input type="text" class="form-control form-control-sm" id="buscador"
                    placeholder="Escribe el nombre de un beneficiario " v-model="buscador" style="width:600px" />
            </div>
        </div>
        <template v-if="(plantillasLista().length == 0)"
            class="d-flex justify-content-center align-items-center bg-primary" style="height:60%">
            <h3 class="text-accent">Al parecer aún no agregan plantillas en tu área.</h3>
        </template>
        <div class="overflow-scroll px-3 py-4" style="height: 600px;" v-else>
            <div class="row">
                <div class="col-3"
                    v-for="{ nombreUsuario, plantillaRequisicionId, nombreBeneficiario, fechaUltimaModificacion } in plantillasLista() "
                    :key="plantillaRequisicionId" @click="seleccionarPlantilla(plantillaRequisicionId)">
                    <div class="card shadow-sm mb-3">
                        <div class="card-body text-center">
                            <h5 class="card-title text-secondary">{{ nombreBeneficiario || '...' }}</h5>
                            <p class="card-text text-ligth text-secondary">{{ nombreUsuario }}</p>
                            <p class="text-secondary">{{ fechaUltimaModificacion.slice(0, 10) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<style scoped lang="scss">
#buscador {
    height: 50px;
    width: 50%;
    font-size: 16px;
}

p {
    margin-bottom: 5px;
}

.card {
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    height: 150px;
    max-height: 150px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        border-color: #ffa000;
        transition: all 0.2s;
    }
}

.form-control {
    padding-left: 2.375rem;
    background-color: #f2f2f2;
    color: #828282;
    border: 0px;
}

.form-control-icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
</style>