<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header bg-white">
        <div class="header">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <div class="row">
                  <div class="col-1">
                    <div class="dropdown">
                      <a
                        class="btn dropdown-toggle"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fas fa-filter"></i>
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <label class="list-group-item border-0"> Estado </label>
                        <li class="list-group-item border-0" v-for="estadoId in estadosIds" :key="estadoId">
                          <input
                            class="form-check-input me-1"
                            type="checkbox"
                            v-model="checarEstadosIds"
                            v-bind:value="estadoId"
                          />
                          <label v-if="estadoId == 1">En autorización</label>
                          <label v-else-if="estadoId == 2">En Cuentas por pagar</label>
                          <label v-else-if="estadoId == 3">En proceso de pago</label>
                          <label v-else-if="estadoId == 4">En aclaración</label>
                          <label v-else-if="estadoId == 5">Rechazada</label>
                          <label v-else-if="estadoId == 6">Pago aplicado</label>
                          <label v-else-if="estadoId == 7">Pago rechazado</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-11">
                    <span class="fas fa-search form-control-icon"></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Escribe aquí para buscar una requisición"
                      v-model="buscador"
                      style="padding-left: 30px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr class="text-center">
                <th scope="col" @click="ordenarPor('requisicionPagoId')" :class="ordenarClass('requisicionPagoId')">
                  ID
                </th>
                <th scope="col" v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  Solicitante
                </th>
                <th scope="col" v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  Fecha solicitud
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  Autorizador
                </th>
                <th
                  scope="col"
                  v-if="rolUsuario == 'Solicitador'"
                  @click="ordenarPor('fechaSolicitud')"
                  :class="ordenarClass('fechaSolicitud')"
                >
                  Fecha creación
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Fecha de recepción</th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  F. autorización
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  Ud. de Negocio
                </th>
                <th
                  v-if="rolUsuario != 'Verificador CXP' && rolUsuario != 'Autorizador CXP'"
                  scope="col"
                  @click="ordenarPor('fechaProgramadaPago')"
                  :class="ordenarClass('fechaProgramadaPago')"
                >
                  Fecha propuesta
                </th>
                <th scope="col" @click="ordenarPor('razonSocial')" :class="ordenarClass('razonSocial')">
                  Razón social
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Beneficiario</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Divisa</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Forma de Pago</th>
                <th
                  scope="col"
                  v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'"
                  @click="ordenarPor('importe')"
                  :class="ordenarClass('importe')"
                >
                  Importe
                </th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Ud. de Negocio</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Cuenta Cargo</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Banco Abono</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Cta Bancaria</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">CLABE</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Convenio</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Referencia</th>
                <th scope="col" v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">Concepto de Pago</th>
                <th
                  scope="col"
                  v-if="rolUsuario != 'Tesoreria' && rolUsuario != 'Auditoria'"
                  @click="ordenarPor('nombreBeneficiario')"
                  :class="ordenarClass('nombreBeneficiario')"
                >
                  Beneficiario
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  Concepto de pago
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">Factura</th>
                <th
                  v-if="rolUsuario != 'Tesoreria' || rolUsuario != 'Auditoria'"
                  scope="col"
                  @click="ordenarPor('importe')"
                  :class="ordenarClass('importe')"
                >
                  Importe
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  Propuesta pago
                </th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">Solicitante</th>
                <th
                  scope="col"
                  v-if="rolUsuario != 'Tesoreria' && rolUsuario != 'Auditoria' && rolUsuario != 'Verificador CXP'"
                  @click="ordenarPor('NombreConcepto')"
                  :class="ordenarClass('NombreConcepto')"
                >
                  Tipo de gasto
                </th>
                <th scope="col">Estado</th>
                <th scope="col">Acciones</th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">M.Pago</th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">F.Pago</th>
                <th scope="col" v-if="rolUsuario == 'Verificador CXP'">UUID</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="solicitud in solicitudesFiltradas" :key="solicitud.requisicionPagoId" class="text-center">
                <td>{{ solicitud.requisicionPagoId }}</td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.usuarioAutorizador }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP'">{{ FormatoFechaHora(solicitud.fechaAutorizacion) }}</td>
                <td v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  {{ solicitud.usuarioSolicitante }}
                </td>
                <td v-if="rolUsuario == 'Autorizador área' || rolUsuario.indexOf('Verificador área') != -1">
                  {{ DarFormatoFecha(solicitud.fechaSolicitud) }}
                </td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">
                  {{ FormatoFechaHora(solicitud.fechaAutorizacionCxP) }}
                </td>
                <td v-if="rolUsuario == 'Solicitador'">{{ DarFormatoFecha(solicitud.fechaSolicitud) }}</td>
                <td v-if="rolUsuario != 'Verificador CXP' && rolUsuario != 'Autorizador CXP'">
                  {{ DarFormatoFecha(solicitud.fechaProgramadaPago) }}
                </td>
                <td v-if="rolUsuario == 'Autorizador CXP'">{{ FormatoFechaHora(solicitud.fechaAutorizacion) }}</td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.unidadNegocio }}
                </td>
                <td>{{ solicitud.razonSocial }}</td>
                <td>{{ solicitud.nombreBeneficiario }}</td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.divisaId == 1">MXN</td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.divisaId == 2">USD</td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.formaPago == '1'">
                  Transferencia
                </td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.formaPago == '2'">
                  Cheque simple
                </td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.formaPago == '3'">
                  Cheque certificado
                </td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">
                  {{ "$" + formatearImporte(solicitud.importe) }}
                </td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">{{ solicitud.unidadNegocio }}</td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.cuentaCargo != null">
                  {{ solicitud.cuentaCargo }}
                </td>
                <td v-if="(rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria') && solicitud.cuentaCargo == null">
                  {{ "-" }}
                </td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">{{ solicitud.bancoBeneficiario }}</td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">
                  {{ solicitud.cuentaBeneficiario }}
                </td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">{{ solicitud.cuentaCLABE }}</td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">
                  {{ solicitud.convenioServicioPago }}
                </td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">{{ solicitud.referencia }}</td>
                <td v-if="rolUsuario == 'Tesoreria' || rolUsuario == 'Auditoria'">{{ solicitud.conceptoPagos }}</td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.conceptoPagos }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ solicitud.numeroFactura }}
                </td>
                <td v-if="rolUsuario != 'Tesoreria' || rolUsuario != 'Auditoria'">
                  {{ "$" + formatearImporte(solicitud.importe) }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP' || rolUsuario == 'Autorizador CXP'">
                  {{ DarFormatoFecha(solicitud.fechaProgramadaPago) }}
                </td>
                <td v-if="rolUsuario == 'Verificador CXP'">{{ solicitud.usuarioSolicitante }}</td>
                <td v-if="rolUsuario != 'Tesoreria' && rolUsuario != 'Verificador CXP' && rolUsuario != 'Auditoria'">
                  {{ solicitud.tipoGasto.nombreGasto }}
                </td>
                <td>
                  <span
                    v-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_CREACION"
                    class="fas fa-user-edit fa-lg"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="En creación"
                    style="color: #8ba7b7"
                  ></span>
                  <span
                    v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_AUTORIZACION"
                    style="color: #0f057e"
                    class="fas fa-user-shield fa-lg"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="En autorización"
                  ></span>
                  <span
                    v-else-if="
                      solicitud.estatusRequisicionId == estatusRequisicion.EN_AUTORIZACION_CXP ||
                      solicitud.estatusRequisicionId == estatusRequisicion.EN_VALIDACION_CXP
                    "
                    style="color: #0098f3"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="En cuentas por pagar"
                    class="fas fa-calculator fa-lg"
                  ></span>
                  <span
                    v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_TESORERIA"
                    style="color: #36d3b7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="En tesorería"
                    class="fas fa-comments-dollar fa-lg"
                  ></span>
                  <span
                    v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.EN_ACLARACION"
                    class="fas fa-exclamation-triangle fa-lg"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="En aclaración"
                    style="color: #febb0e"
                  ></span>
                  <span
                    v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.RECHAZADA"
                    class="fas fa-times-circle fa-lg"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Rechazada"
                    style="color: #fe541f"
                  ></span>
                  <span
                    v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.PAGO_REALIZADO"
                    class="fas fa-check-circle fa-lg"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Pago aplicado"
                    style="color: #26bb10"
                  ></span>
                  <span
                    v-else-if="solicitud.estatusRequisicionId == estatusRequisicion.PAGO_RECHAZADO"
                    style="color: #fb1010"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Pago rechazado"
                    class="fab fa-creative-commons-nc fa-lg"
                  ></span>
                </td>
                <td>
                  <span class="far fa-eye btn" @click="verSolicitud(solicitud.requisicionPagoId)"></span>
                </td>
                <td v-if="rolUsuario == 'Verificador CXP'">-</td>
                <td v-if="rolUsuario == 'Verificador CXP'">-</td>
                <td v-if="rolUsuario == 'Verificador CXP'">{{ solicitud.uuid }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link">
                    {{ paginaActual + " de " + Math.ceil(this.historialSolicitudes.length / 5) + " páginas" }}</a
                  >
                </li>
                <li class="page-item">
                  <a class="page-link" aria-label="Previous" @click="prev">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" aria-label="Next" @click="next()">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SRP } from "@/servicios/api"; // eslint-disable-line
import api from "@/servicios/apiSRP";
import { defineComponent } from "vue";
//Importar mixins
import mixinRequisicionPago from "../mixins/mixinRequisicionPago";

export default defineComponent({
  name: "Historico",
  mixins: [mixinRequisicionPago],
  data() {
    return {
      usuarioId: 0,
      rolUsuario: "",
      buscador: "",
      paginaActual: 1,
      numeroSolicitudesMostradas: 30,
      numeroPaginasActuales: 0,
      mostrarBotonNueva: 0,
      ordenar: {
        nombreColumna: "",
        esAscendente: false
      },
      estadosIds: [1, 2, 3, 4, 5, 6, 7],
      checarEstadosIds: [],
      estadosFormateados: [],
      tipoGasto: [],
      roles: [],
      arregloCuentaCargo: [],
      arregloCuentaCargoAsigancion: {
        idCuentaCargo: "",
        idRequisicion: ""
      },
      historialSolicitudes: [],
      estatusRequisicion: SRP.Models.EstatusRequisicion
    };
  },
  mounted() {
    this.rolUsuario = this.$route.params["rolUsuario"];
    this.usuarioId = window.sessionStorage.getItem("usuarioId");
    this.validarRutaRol();
    this.CargarTipoGasto();
  },
  methods: {
    async validarRutaRol() {
      if (this.rolUsuario.startsWith("Verificador área")) {
        this.CargarHistorialVerificadorArea(this.usuarioId);
      } else {
        switch (this.rolUsuario) {
          case "Solicitador":
            var rolesList = window.sessionStorage.getItem("listaRoles");
            if (rolesList.indexOf("Solicitador") > 0) {
              this.CargarHistorialSolicitador(this.usuarioId);
              this.mostrarBotonNueva = 2;
            }
            break;
          case "Autorizador área":
            this.CargarHistorialAutorizador(this.usuarioId);
            break;
          case "Verificador CXP":
            this.CargarHistorialVerificador(this.usuarioId);
            break;
          case "Autorizador CXP":
            this.CargarHistorialAutorizadorCXP();
            break;
          case "Tesoreria":
            this.CargarHistorialTesoreria(this.usuarioId);
            break;
          case "Auditoria":
            this.CargarHistorialAuditoria(this.usuarioId);
            break;
        }
      }
    },
    async CargarHistorialVerificadorArea(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoVerificadorArea(
        idUsuario
      );
      this.historialSolicitudes = resp.data;
    },
    guardarArregloCuentas(event, requisicionId) {
      this.arregloCuentaCargoAsigancion.idCuentaCargo = event.target[event.target.selectedIndex].text;
      this.arregloCuentaCargoAsigancion.idRequisicion = requisicionId + "";
      var idCuentaCargo = Object.assign({}, this.arregloCuentaCargoAsigancion);
      if (this.arregloCuentaCargo.length > 0) {
        for (var i = 0; i < this.arregloCuentaCargo.length; i++) {
          if (this.arregloCuentaCargo[i].idRequisicion == this.arregloCuentaCargoAsigancion.idRequisicion) {
            this.arregloCuentaCargo.splice(i, 1);
          }
        }
      }
      this.arregloCuentaCargo.push(idCuentaCargo);
    },
    async ActualizarRequisicionCuentaCargo() {
      if (this.arregloCuentaCargo.length > 0) {
        await api.SRP.RequisicionPago.RequisicionPago.ActualizarCuentaCargoEnRequisicion(
          JSON.stringify(this.arregloCuentaCargo)
        );
      }
      this.ExportarExcel();
    },
    async EliminarSolicitud(requisicionPagoId) {
      await api.SRP.RequisicionPago.RequisicionPago.EliminarRequisicionPago(requisicionPagoId);
      this.validarRutaRol();
    },
    async CargarHistorialSolicitador(idUsuario) {
      var resp = {};
      resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoSolicitador(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialAutorizador(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoAutorizadorArea(
        idUsuario
      );
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialVerificador(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoVerificadorCXP(
        idUsuario
      );
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialAutorizadorCXP() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoAutorizadorCXP();
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialTesoreria(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoTesoreria(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarHistorialAuditoria(idUsuario) {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ListarHistorialRequisicionesPagoAuditoria(idUsuario);
      this.historialSolicitudes = resp.data;
    },
    async CargarTipoGasto() {
      var resp = await api.SRP.RequisicionPago.RequisicionPago.ObtenerCatalogoTipoGasto();
      this.tipoGasto = resp.data;
    },
    async EditarSolicitud(requisicionPagoId) {
      this.$router.push("/editar-requisicion-pago/" + requisicionPagoId);
    },
    async NuevaSolicitud() {
      this.$router.push("/formulario-requisicion-pago");
    },
    async ExportarExcel() {
      let r;
      if (this.rolUsuario == "Verificador CXP") {
        r = await api.SRP.RequisicionPago.RequisicionPago.ExportToExcelTesoreria(false);
      } else {
        r = await api.SRP.RequisicionPago.RequisicionPago.ExportToExcelTesoreria(true);
      }
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      var url = window.URL.createObjectURL(r.data);
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    calcularNumeroPaginas(numeroPaginasActuales) {
      numeroPaginasActuales = Math.ceil(this.historialSolicitudes.length / 5);

      return numeroPaginasActuales;
    },
    prev() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
    },
    next() {
      let totalPaginas = Math.ceil(this.historialSolicitudes.length / 5);
      if (this.paginaActual < totalPaginas) {
        this.paginaActual++;
      }
    },
    paginated(historialSolicitudes) {
      return historialSolicitudes.slice(this.indexStart, this.indexEnd);
    },
    async verSolicitud(solicitud) {
      this.historialSolicitudes.id = solicitud;
      window.sessionStorage.setItem("requisicionPagoId", solicitud);
      this.$router.push("/vista-detalle-solicitud/" + this.rolUsuario);
    },
    ordenarClass(nombreColumna) {
      return this.ordenar.nombreColumna === nombreColumna ? `sorted ${this.ordenar.esAscendente ? "asc" : "desc"}` : "";
    },
    ordenarPor(nombreColumna) {
      this.ordenar.esAscendente = this.ordenar.nombreColumna === nombreColumna ? !this.ordenar.esAscendente : true;
      this.ordenar.nombreColumna = nombreColumna;
    },
    ordenarElementos: function (historialSolicitudes) {
      const list = historialSolicitudes.slice();
      if (this.ordenar.nombreColumna) {
        list.sort((a, b) => {
          a = a[this.ordenar.nombreColumna];
          b = b[this.ordenar.nombreColumna];

          return (a === b ? 0 : a > b ? 1 : -1) * (this.ordenar.esAscendente ? 1 : -1);
        });
      }

      return list;
    },
    formatearImporte(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    BuscarSolicitud: function (historialSolicitudes) {
      return historialSolicitudes.filter((solicitud) => {
        if (solicitud.razonSocial == null) {
          solicitud.razonSocial = "-";
        }
        return (
          (solicitud.requisicionPagoId || "").toString().includes(this.buscador) ||
          (solicitud.razonSocial || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.nombreBeneficiario || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaSolicitud) || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaProgramadaPago) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaAplicacionPago) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaAutorizacion) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (this.formatearFecha(solicitud.fechaAutorizacionCxP) || "")
            .toLowerCase()
            .includes(this.buscador.toLowerCase()) ||
          (solicitud.importe || "").toString().includes(this.buscador) ||
          (solicitud.tipoGasto.nombreGasto || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.divisa || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.formaPago || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.conceptoPagos || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.unidadNegocio || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.usuarioAutorizador || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.usuarioSolicitante || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.bancoBeneficiario || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.convenioServicioPago || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.cuentaBeneficiario || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.cuentaCLABE || "").toLowerCase().includes(this.buscador.toLowerCase()) ||
          (solicitud.referencia || "").toLowerCase().includes(this.buscador.toLowerCase())
        );
      });
    },
    formatearFecha: function (fecha) {
      let fecha_formateada = new Date(fecha);
      let dia = `${fecha_formateada.getDate()}`.padStart(2, "0");
      let mes = `${fecha_formateada.getMonth() + 1}`.padStart(2, "0");
      let anio = fecha_formateada.getFullYear();
      return dia + "/" + mes + "/" + anio;
    },
    FiltrarPorEstados: function (historialSolicitudes) {
      if (!this.checarEstadosIds.length) {
        return this.historialSolicitudes;
      } else {
        if (this.checarEstadosIds.includes(1) && !this.estadosFormateados.includes("EN_AUTORIZACION")) {
          this.estadosFormateados.push("EN_AUTORIZACION");
        }
        if (this.checarEstadosIds.includes(2)) {
          this.estadosFormateados.push("EN_VALIDACION_CXP");
        }
        if (this.checarEstadosIds.includes(3)) {
          this.estadosFormateados.push("EN_AUTORIZACION_CXP");
        }
        if (this.checarEstadosIds.includes(4)) {
          this.estadosFormateados.push("EN_TESORERIA");
        }
        if (this.checarEstadosIds.includes(5)) {
          this.estadosFormateados.push("EN_ACLARACION");
        }
        if (this.checarEstadosIds.includes(6)) {
          this.estadosFormateados.push("RECHAZADA");
        }
        if (this.checarEstadosIds.includes(7)) {
          this.estadosFormateados.push("PAGO_REALIZADO");
        }
        if (this.checarEstadosIds.includes(8)) {
          this.estadosFormateados.push("PAGO_RECHAZADO");
        }

        return historialSolicitudes.filter((historialSolicitudes) =>
          this.estadosFormateados.includes(historialSolicitudes.estatusRequisicionId)
        );
      }
    },
    solicitudesTotales: function () {
      return this.historialSolicitudes.length;
    }
  },
  computed: {
    solicitudesFiltradas: function () {
      return this.paginated(
        this.ordenarElementos(this.BuscarSolicitud(this.FiltrarPorEstados(this.historialSolicitudes)))
      );
    },
    indexStart() {
      return (this.paginaActual - 1) * this.numeroSolicitudesMostradas;
    },
    indexEnd() {
      return this.indexStart + this.numeroSolicitudesMostradas;
    },
    paginas: function () {
      return this.calcularNumeroPaginas();
    }
  }
});
</script>
<style lang="scss">
.container-fluid {
  margin-top: 15px;
}

table {
  th .sorted {
    &.asc::after {
      display: inline-block;
      content: "▼";
    }

    &.desc::after {
      display: inline-block;
      content: "▲";
    }
  }
}

.dropdown-toggle {
  &::after {
    display: none !important;
  }
}

span {
  color: #2d9cdb;
}

.card {
  font-size: 12px;
}

.form-group {
  .form-control {
    padding-left: 2.375rem;
    background-color: #f2f2f2;
    color: #828282;
    border: 0px;
  }

  .form-control-icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}
</style>
