
import { defineComponent } from "vue";

//Importar mixins
import mixinRequisicionPago from "../mixins/mixinRequisicionPago";
import ModalSoporte from "../components/ModalSoporte.vue";

export default defineComponent({
  name: "DetalleDatosGenerales",
  components: {
    ModalSoporte
  },
  mixins: [mixinRequisicionPago],
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      date1: this.fechaPropuesta,
      rutaDocumento: "",
      documentoPdfId: null,
      componenteId: "a" + Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7),
      requisicion_id: "",
      rolUsuario: ""
    };
  },
  props: {
    requisicionPagoId: Number,
    razonSocial: String,
    tipoPagoId: Number,
    tipoPago: String,
    nombreBeneficiario: String,
    importe: Number,
    conceptoPagoId: Number,
    nombreTipoGasto: String,
    nombreRubro: String,
    medioInstruccion: String,
    numeroFactura: String,
    uuid: String,
    cuentaBeneficiario: String,
    bancoBeneficiario: String,
    clabe: String,
    referencia: String,
    divisa: Number,
    rfcBeneficiario: String,
    convenioServicioPago: String,
    fechaSolicitud: String,
    fechaPropuesta: String,
    observacionInstruccion: String,
    usuarioAutorizadorId: Number,
    documentos: Array,
    conceptoPagos: String,
    unidadNegocio: String
  },
  mounted() {
    this.rolUsuario = this.$route.params["rolUsuario"] as string;
    //Modal con funcionalidad de ser arrastrada modal archivos individuales
    dragElement(document.getElementById(this.componenteId + "modalDocumento"));
    function dragElement(elmnt: any) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e: any) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e: any) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    //Modal arrastrable de soporte
    dragElementSoporte(document.getElementById("modalSoporte" + this.componenteId));

    function dragElementSoporte(elmnt: any) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e: any) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e: any) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  },

  methods: {
    abrirmodal() {
      var modal = document.getElementById("myModal" + this.componenteId) as any;
      modal.style.display = "block";
    },
    cerrarmodal() {
      var modal = document.getElementById("myModal" + this.componenteId) as any;
      modal.style.display = "none";
    },
    EsconderBackdrop() {
      let backdrop = document.getElementsByClassName("modal-backdrop") as any;
      backdrop[0].setAttribute("style", "display:none !important");
      document.body.classList.remove("modal-open");
    },
    async MostrarArchivo(documentoId: number, tipo: String) {
      (document.getElementById("iFrameDocumentosUno") as HTMLImageElement).src = (document.getElementById(
        "iFrameDocumentosUno"
      ) as HTMLImageElement).src;
      if (tipo != "PDF") {
        window.open(
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false"
        );
      } else {
        this.rutaDocumento =
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" + documentoId + "&descargaPdf=false";
        this.documentoPdfId = documentoId as any;
      }
    },
    ValidarFecha: function (fechaPropuesta: string) {
      let fechaReformateadaPago = new Date(fechaPropuesta);
      let diaSeleccionado = fechaReformateadaPago.getDay(); //posicion en la semana
      //validación de fines de semana
      if (diaSeleccionado == 6 || diaSeleccionado == 5) alert("Sábado o Domingo");
      this.$emit("input", fechaPropuesta);
    },
    FechaMinima: function () {
      let Fechaminima = new Date();
      let diaPosActual = new Date(Fechaminima).getDay(); // Posicion del dia 0-6
      if (diaPosActual == 6) {
        //segun aqui es domingo es 6
        Fechaminima.setDate(Fechaminima.getDate() + 2);
        return Fechaminima.toString().substring(0, 10);
      } else if (diaPosActual == 5) {
        //
        Fechaminima.setDate(Fechaminima.getDate());
        return Fechaminima.toString().substring(0, 10);
      } else {
        Fechaminima.setDate(Fechaminima.getDate());
        return Fechaminima.toISOString().substring(0, 10);
      }
    },
    FechaMaxima() {
      let Fechamaxima = new Date();
      Fechamaxima.setMonth(Fechamaxima.getMonth() + 1);

      let fechaFinal = new Date(Fechamaxima); //esta fecha es la que coloca como final
      //alert(fechaFinal);
      //Ya se obtuvo la posible fecha validamos si esta fecha es domingo sabado o viernes
      let diaPosActual = new Date(fechaFinal).getDay(); // Posicion del dia 0-6
      //alert(fechaFinal);
      if (diaPosActual == 6) {
        // en este caso este es domingo
        let diasumado = fechaFinal.setDate(fechaFinal.getDate() + 2);
        let fechafinalfinal = new Date(diasumado).toISOString().substring(0, 10);
        return fechafinalfinal;
      } else if (diaPosActual == 5) {
        // en este caso este es sabado
        let diasumado = fechaFinal.setDate(fechaFinal.getDate() + 3);
        let fechafinalfinal = new Date(diasumado).toISOString().substring(0, 10);
        return fechafinalfinal;
      } else {
        let fechafinalfinal = new Date(fechaFinal).toISOString().substring(0, 10);
        return fechafinalfinal;
      }
    },
    DescargaPDF() {
      if (this.documentoPdfId != null) {
        window.open(
          "/api/SRP/RequisicionPago/RequisicionPago/ObtenerArchivo?documentoId=" +
            this.documentoPdfId +
            "&descargaPdf=true"
        );
      }
    }
  }
});
